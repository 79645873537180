import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import {
  GenericIntegration,
  GenericIntegrationConfiguration,
  GenericIntegrationType,
  ScheduleType
} from "src/services/integrations/types";
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import GeneralBlock from "./GeneralBlock";
import ConfigurationDetailsBlock from "./ConfigurationDetailsBlock";
import ScheduleBlock from "./ScheduleBlock";
import DisconnectBlock from "./DisconnectBlock";


type GeneralTabType = {
  name: string,
  setName: Dispatch<SetStateAction<string>>,
  integration: GenericIntegration,
  configuration: GenericIntegrationConfiguration,
  setConfiguration: Dispatch<SetStateAction<GenericIntegrationConfiguration | undefined>>,
  schedule: ScheduleType | undefined,
  setSchedule: Dispatch<SetStateAction<ScheduleType | undefined>>,
  update: (data?:Partial<GenericIntegration>) => void,
  updateInProgress: boolean,
  setUpdateInProgress: Dispatch<SetStateAction<boolean>>
}

const GeneralTab = ({name, setName, configuration, setConfiguration, schedule, setSchedule, integration, update, updateInProgress, setUpdateInProgress}: GeneralTabType) => {
  const integrationType = useParams().type as GenericIntegrationType;

  return (
    <div className='w-[40rem] m-auto mb-10'>
      <div className="flex flex-col gap-4 relative">
        <div className="rounded-lg border bg-white py-4 px-6 w-[40rem] mt-6">
          <GeneralBlock name={name} setName={setName} integration={integration} update={update} updateInProgress={updateInProgress} setUpdateInProgress={setUpdateInProgress}/>
        </div>
        <div className="rounded-lg border bg-white py-4 px-6 w-[40rem]">
          <ConfigurationDetailsBlock
            integrationType={integrationType}
            configuration={configuration}
            integration={integration}
            setConfiguration={setConfiguration}
            update={update}
            updateInProgress={updateInProgress}
            setUpdateInProgress={setUpdateInProgress}
          />
        </div>
        { integrationType !== "dbt_core" && (
          <div className="rounded-lg border bg-white py-4 px-6 w-[40rem]">
            <ScheduleBlock
              schedule={schedule}
              setSchedule={setSchedule}
              integration={integration}
              update={update}
              updateInProgress={updateInProgress}
              setUpdateInProgress={setUpdateInProgress}
            />
          </div>
        )}
        {!integration && integrationType === "dbt_core" && (
          <div className="flex flex-row rounded-lg py-4 px-6 w-[40rem] bg-slate-100">
            <InformationCircleIcon width="20" height="20" className="mr-2 text-slate-400"/>
            <span className='text-text-primary'>Euno will create an integration key upon source creation</span>
          </div>
        )}
        {!integration && (
          <div className="absolute border mt-6 p-4 right-[-22rem] w-80 rounded-lg border">
            <div className="text-lg mb-6">Need help?</div>
            <a className='text-tertiary' href="https://docs.euno.ai/integrations">Visit our Developer Documentation</a>
          </div>
        )}
        {integration && (
          <div className="rounded-lg border bg-white py-4 px-6 w-[40rem]">
            <DisconnectBlock/>
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralTab;
