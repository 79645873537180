import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import Input from "src/components/form/Input";
import Table from "src/components/Table/Table";
import { TableColumnProps } from "src/components/Table/types";
import { ISuperficialNode } from "src/features/models/discover/INode";
import { ResourceSidepane } from "src/features/models/discover/resourceSidepane/ResourceSidepane";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useLazyGetDataModelResourcesQuery } from "src/services/nodes/nodes";

const columns: TableColumnProps[] = [
    {
        name: 'Type',
        selector: (row: unknown) => (row as ISuperficialNode).type,
        maxWidth: '150px',
    },
    {
        name: 'Name',
        selector: (row: unknown) => (row as ISuperficialNode).name,
        maxWidth: '300px',
    },
    {
        name: 'UTL',
        selector: (row: unknown) => (row as ISuperficialNode).id,
    }
];

export const EQLExplorer = () => {
    const [query, setQuery] = useState<string>('');
    const [getResources, { error, data, isFetching }] = useLazyGetDataModelResourcesQuery();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const accountId = useSelector(selectActiveAccountId);
    const [selectedResourceId, setSelectedResourceId] = useState<string | null>(null);

    const search = useCallback(() => {
        getResources({ eql: query, page, pageSize, accountId, withSubResources: true });
    }, [query, page, pageSize, accountId, getResources]);

    const onRowClicked = useCallback((row: unknown) => {
        const node = row as ISuperficialNode;
        setSelectedResourceId(node.id);
    }, []);

    useEffect(() => {
        search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, pageSize]);

    return (
        <div className="flex w-[70vw] flex-col gap-2">
            <div className="flex gap-2 items-center">
                <Input placeholder="Enter EQL" value={query} onInputChange={e => setQuery(e.target.value)} onEnter={search} />
                <Button type={ButtonTypes.primary} onClick={search} text="Run" className="w-40" />
            </div>
            {
                error && <div className="text-red-500 break-words">{JSON.stringify(error)}</div>
            }
            <Table
                data={data?.items || []}
                columns={columns}
                onRowClicked={onRowClicked}
                pagination={{
                    total: data?.total || 0,
                    page,
                    pageSize,
                    setPage,
                    setPageSize,
                }}
                isLoading={isFetching}
            />
            <ResourceSidepane onClose={() => setSelectedResourceId(null)} resourceId={selectedResourceId || ''} />
        </div>
    );
};