import { TailSpin } from 'react-loader-spinner';
import { GithubIcon } from '../../../assets/images/icons/DelphiIcons';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import { ChangeDiff, ChangeStatus, ChangeType, IChange, NewColumnChangeData, NewMeasureChangeData } from '../IChange';
import { useCallback, useEffect, useState } from 'react';
import { useGenerateChangeDiffMutation } from '../../../services/changes/changes';
import { extractErrorMessage } from '../../../services/api';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { useSelector } from 'react-redux';
import { DiffOperationResult } from './Types';
import { getFilenameFromPath } from '../../../utils/stringsUtils';
import FileDiff from '../../../components/FileDiff';
import waitForOperationToComplete from '../../../infrastructure/waitForOperation';
import { Alert } from '../../../components/Alert';
import { Operation, OperationStatus } from '../../operations/Operation';
import { LogsModal } from '../../account/AccountLogsModal';
import { SubnodeType } from '../../models/discover/INode';
import exportedVariables from '../../../../exportedVariables.json';

const getChangeDiffData = (change: IChange) => {
    if (change.changeType === ChangeType.NewColumn) {
      return {
        type: SubnodeType.Column.toLowerCase(),
        modelId: (change.changeData as NewColumnChangeData).modelId,
        name: (change.changeData as NewColumnChangeData).newColumnName
      };
    } else if (change.changeType === ChangeType.NewMeasure) {
      return {
        type: SubnodeType.Measure.toLowerCase(),
        modelId: (change.changeData as NewMeasureChangeData).modelId,
        name: (change.changeData as NewMeasureChangeData).newMeasureName
      };
    }
    return null;
};

const ChangeCodeDiff = ({ change, isActive }: { change: IChange; isActive: boolean }) => {
  const [diffOperation, setDiffOperation] = useState<Operation | null>(null);
  const accountId = useSelector(selectActiveAccountId);
  const [generateDiff] = useGenerateChangeDiffMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [generateDiffError, setGenerateDiffError] = useState('');
  const [showDiffOperationModal, setShowDiffOperationModal] = useState(false);

  const getDiff = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!change.id) {
        throw new Error('Change id is missing');
      }
      const { id: operationId } = await generateDiff({ changeId: change.id, projectId: change.projectId }).unwrap();
      const operation = await waitForOperationToComplete(operationId, accountId, 1000, 120);
      setDiffOperation(operation);
    } catch (e) {
      setGenerateDiffError(extractErrorMessage(e).message);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, generateDiff, change.id, change.projectId, accountId]);

  const diff = diffOperation?.result ? getDiffFromOperationResult(diffOperation?.result as DiffOperationResult) : null;

  const shouldShowDiff = [ChangeStatus.Open, ChangeStatus['Draft Rejected']].includes(change.status);

  useEffect(() => {
    if (shouldShowDiff) {
      getDiff();
    }
  }, [getDiff, shouldShowDiff, change]);

  if (!isActive) {
    return null;
  }

  const normalizedChangeStrings = getChangeDiffData(change);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="text-text-primary font-medium">Code</div>
        {change.pullRequestUrl && (
          <Button
            type={ButtonTypes.secondary}
            text="View in Github"
            icon={<GithubIcon width="14" height="14" fill="#334155" />}
            onClick={() => window.open(change.pullRequestUrl, '_blank')}
            className="ml-auto"
          />
        )}
      </div>
      {isLoading && (
        <div className="flex items-center">
          <TailSpin
            height="32"
            width="32"
            color={exportedVariables.primary}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="mr-2"
            visible={true}
          />
          Generating diff...
        </div>
      )}
      {!shouldShowDiff && (
        <Alert
          text="To view the changes to the dbt repository, select the GitHub link to be directed to the associated pull request."
          type="info"
          className="mt-2"
        />
      )}
      {!isLoading && shouldShowDiff && diff?.length ? diff.map((file, index) => <FileDiff key={index} file={file} index={index} />) : null}
      {!isLoading && shouldShowDiff && diffOperation?.status !== OperationStatus.Failed && !diff?.length && normalizedChangeStrings ?
          (<div>
            <Alert text={`A ${normalizedChangeStrings.type} with the name ${normalizedChangeStrings.name} already exists in the model ${normalizedChangeStrings.modelId}. If this is indeed a different ${normalizedChangeStrings.type}, please edit the name in the change proposals and refresh the page to generate the code diff.`} type="warning" />
          </div>)
          : null
      }
      {diffOperation?.status === OperationStatus.Failed && (
        <>
          <div
            onClick={() => {
              setShowDiffOperationModal(true);
            }}
            className="cursor-pointer"
          >
            <Alert text={`Failed to generate diff - click to view operation log`} type="error" />
          </div>
          <LogsModal
            operation={diffOperation}
            isOpen={showDiffOperationModal}
            onClose={() => setShowDiffOperationModal(false)}
          />
        </>
      )}
      {generateDiffError && (
        <Alert text={`Failed to generate diff: ${extractErrorMessage(generateDiffError).message}`} type="error" />
      )}
    </div>
  );
};

const getDiffFromOperationResult = (operationResult: DiffOperationResult): ChangeDiff[] => {
  return operationResult.diff.parsed_diff.map((file) => ({
    fileName: getFilenameFromPath(file.new_path || ''),
    oldCode: atob(file.old_content_base64 || ''),
    newCode: atob(file.new_content_base64 || '')
  }));
};

export default ChangeCodeDiff;
