import {Dispatch, SetStateAction} from "react";
import {useParams} from "react-router-dom";
import { notify } from 'src/components/Toaster';
import {
  GenericIntegration,
  GenericIntegrationConfiguration,
  GenericIntegrationType
} from "src/services/integrations/types";
import AdvancedBlock from "src/features/sources/connectedSources/AdvancedBlock";
import AllowDeny from "src/components/form/AllowDeny";
import Mapping from "src/components/form/Mapping";
import Button from "src/components/button/Button";
import {ButtonTypes} from "src/components/button/types";


type AdvancedTabType = {
  configuration: GenericIntegrationConfiguration,
  integration: GenericIntegration,
  setConfiguration: Dispatch<SetStateAction<GenericIntegrationConfiguration | undefined>>,
  update: (data?: Partial<GenericIntegration>, fieldsForUpdate?: (keyof GenericIntegrationConfiguration)[] ) => void,
  updateInProgress: boolean,
  setUpdateInProgress: Dispatch<SetStateAction<boolean>>
}

const AdvancedTab = (({configuration, integration, setConfiguration, update, updateInProgress, setUpdateInProgress}: AdvancedTabType) => {
  const integrationType = useParams().type as GenericIntegrationType;
  let mappingLabel, mappingField: keyof GenericIntegrationConfiguration;
  let allowDenyField = "project_pattern" as keyof GenericIntegrationConfiguration;

  if (integrationType === 'looker') {
    mappingLabel = "Map your connections";
    mappingField = "connection_mapping" as keyof GenericIntegrationConfiguration;
  } else if (integrationType === 'tableau') {
    mappingLabel = "Replace database hostname to platform instance";
    mappingField = "database_hostname_to_platform_instance_map" as keyof GenericIntegrationConfiguration;
  } else if (integrationType === 'dbt_core') {
    mappingLabel = "Map your schema aliases";
    mappingField = "schemas_aliases" as keyof GenericIntegrationConfiguration;
  } else {
    mappingLabel = "Replace database hostname to platform instance";
    mappingField = "database_mapping" as keyof GenericIntegrationConfiguration;
    allowDenyField = "database_pattern" as keyof GenericIntegrationConfiguration;
  }

  return (
    <div className='w-[40rem] m-auto mb-10'>
      <div className="flex flex-col gap-4 relative">
        <div className="rounded-lg border bg-white py-4 px-6 w-[40rem] mt-6">
          <AdvancedBlock
            configuration={configuration}
            setConfiguration={setConfiguration}
            update={update}
            updateInProgress={updateInProgress}
            setUpdateInProgress={setUpdateInProgress}
          />
        </div>
        <div className="rounded-lg border bg-white py-4 px-6 w-[40rem]">
          <span className="text-lg">Mapping</span>
          <div className="flex flex-col justify-between mt-4">
            <Mapping
              label={mappingLabel}
              existingMappings={configuration[mappingField]}
              sourcePlaceholder='Source'
              targetPlaceholder='Target'
              setMappings={(newMappings) => {
                try {
                  if (integrationType === 'looker') {
                    Object.keys(newMappings).forEach(mapping => {
                      if (typeof newMappings[mapping] !== 'object') {
                        newMappings[mapping] = JSON.parse(newMappings[mapping] as string);
                      }
                    });
                  }
                  setConfiguration({...configuration, [mappingField]: newMappings});
                } catch (e) {
                  notify('You must insert json object as a mapping value for Looker', 'error');
                }
              }}
            />
          </div>
          <div className="flex justify-end mt-6 pt-4 border-t">
            <Button
              className="ml-4 w-24 !text-base"
              type={ButtonTypes.primary}
              text="Save"
              onClick={() => {
                setUpdateInProgress(true);
                update({configuration}, [mappingField]);
              }}
              isDisabled={updateInProgress || (configuration &&
                JSON.stringify(Object.keys(integration.configuration[mappingField])) ===
                JSON.stringify(Object.keys(configuration[mappingField])))}
            />
          </div>
        </div>
        { (integrationType === "tableau" || integrationType === "snowflake") && (
          <div className="rounded-lg border bg-white py-4 px-6 w-[40rem]">
            <span className="text-lg">{`${integrationType === "tableau" ? 'Project' : 'Database'} pattern`}</span>
            <div className="flex flex-col justify-between mt-4">
              <AllowDeny
                allowList={configuration[allowDenyField]["allow"]}
                denyList={configuration[allowDenyField]["deny"]}
                setAllowDeny={({allow, deny}) => {
                  setConfiguration({...configuration, [allowDenyField]: {allow, deny}});
                }}
              />
            </div>
            <div className="flex justify-end mt-6 pt-4 border-t">
              <Button
                className="ml-4 w-24 !text-base"
                type={ButtonTypes.primary}
                text="Save"
                onClick={() => {
                  setUpdateInProgress(true);
                  update(
                    {configuration},
                    [allowDenyField]
                  );
                }}
                isDisabled={updateInProgress || (configuration &&
                  JSON.stringify(integration.configuration[allowDenyField]) ===
                  JSON.stringify(configuration[allowDenyField]))}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default AdvancedTab;
