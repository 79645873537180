
export const SourceRunStatus = ({ active, loading }: { active?: boolean, loading: boolean }) => (
	<>
		{active === true && (
			<div className={`text-base self-center rounded-full bg-green-600 text-white font-bold py-1 px-2.5
				${loading ? 'opacity-50' : ''}`}
			>
				Active
			</div>
		)}
		{active === false && (
			<div className={`text-base self-center rounded-full bg-tertiary text-white font-bold py-1 px-2.5
				${loading ? 'opacity-50' : ''}`}
			>
				Paused
			</div>
		)}
	</>
);
