import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Input from "src/components/form/Input";


type SearchProps = {
    search: string;
    setSearch: (value: string) => void;
		className?: string;
		inputClassName?: string;
		placeholder?: string
}

export const Search = ({ search, setSearch, className, inputClassName, placeholder }: SearchProps) => {
	return (
		<div className={className}>
			<Input
				value={search}
				onInputChange={(e) => setSearch(e.target.value)}
				placeholder={placeholder || "Search"}
				height="h-8"
				className='rounded-md'
				inputClassName={inputClassName}
				icon={<MagnifyingGlassIcon width="14" height="14" />}
			/>
		</div>
	);
};