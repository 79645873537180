import { LookerIcon } from '../../../assets/images/icons/DelphiIcons';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  useCreateGenericIntegrationMutation,
  useGetGenericIntegrationsQuery,
  useDeleteGenericIntegrationMutation
} from '../../../services/integrations/integrations';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import { EllipsisVerticalIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { Alert } from '../../../components/Alert';
import Input from '../../../components/form/Input';
import { GenericIntegration, LookerIntegrationConfiguration } from '../../../services/integrations/types';
import { CrawlIntegrationModal } from 'src/features/account/integrations/CrawlIntegrationModal';

const LookerIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const { data: integrations = [] } = useGetGenericIntegrationsQuery({ accountId }, { skip: !accountId });
  const lookerIntegrations = integrations.filter((i) => i.integration_type === 'looker').map((i) => ({ ...i, configuration: i.configuration as LookerIntegrationConfiguration }));
  const [showCrawlModal, setShowCrawlModal] = useState(false);


  const menuOptions = [
    {
      name: 'Edit configuration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    },
    {
      name: 'Start polling',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowCrawlModal(true);
      }
    }
  ];

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4" dataTestId='looker-integration'>
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <LookerIcon width="56" height="56" className="mb-2 text-black" color="black" />
      <span className="mt-2 font-medium">Looker</span>
      {lookerIntegrations.length === 0 ? (
        <Button
          type={ButtonTypes.secondary}
          text="Configure"
          onClick={() => setShowConfigurationModal(true)}
          className="-mb-2 mt-1 h-6"
          dataTestId='configure-looker-integration-button'
        />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {lookerIntegrations.length} active integration{lookerIntegrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={lookerIntegrations}
      />
      <CrawlIntegrationModal
        isOpen={showCrawlModal}
        onClose={() => setShowCrawlModal(false)}
        integrations={lookerIntegrations}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: GenericIntegration[];
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations
}: IntegrationConfigurationModalProps) => {
  const [unlinkIntegration, { originalArgs, isLoading: isLoadingUnlink }] = useDeleteGenericIntegrationMutation();
  const [addingInstance, setAddingInstance] = useState(false);
  const [createIntegration, { isLoading }] = useCreateGenericIntegrationMutation();
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [lookerHost, setLookerHost] = useState('');
  const [error, setError] = useState<string>('');

  const onCreateClick = async () => {
    if (!clientId) {
      setError('Client ID is required');
      return;
    }
    if (!clientSecret) {
      setError('Client Secret is required');
      return;
    }
    if (!lookerHost) {
      setError('Looker host is required');
      return;
    }
    try {
      await createIntegration({ accountId, integrationName: lookerHost, configuration: { 'host': lookerHost, 'client_id': clientId, 'client_secret': clientSecret }, integrationType: 'looker' }).unwrap();
      setAddingInstance(false);
      notify('Looker integration created successfully', 'success');
    } catch (e) {
      setError(`Error creating integration: ${extractErrorMessage(e).message}`);
    }
  };

  useEffect(() => {
    setError('');
  }, [isOpen, clientId, clientSecret, lookerHost]);

  useEffect(() => {
    if (isOpen) {
      setClientId('');
      setClientSecret('');
      setLookerHost('');
      setError('');
      if (integrations.length === 0) {
        setAddingInstance(true);
      }
    }
  }, [isOpen, integrations.length]);

  const onUnlinkClick = async (integration: GenericIntegration) => {
    try {
      await unlinkIntegration({ accountId, integrationId: integration.id }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      notify(`Error unlinking integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configure Looker Integration"
      maxWidth="max-w-lg"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-32' }]}>
      <div className="rounded border border-slate-200 bg-slate-50">
        {integrations.map((integration) => (
          <div key={integration.id} className="flex items-center justify-between border-b border-slate-200 px-4 py-3">
            <div className="flex items-center">
              <LookerIcon width="20" height="20" className="mr-2" />
              <span className="text-sm" data-test-id="looker-integration-name">{(integration.configuration as LookerIntegrationConfiguration).host}</span>
            </div>
            {originalArgs?.integrationId === integration.id && isLoadingUnlink ? (
              <span className="flex items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Loading...
              </span>
            ) : (
              <span
                onClick={() => onUnlinkClick(integration)}
                className="flex cursor-pointer items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Remove
              </span>
            )}
          </div>
        ))}
        <div className="px-4 py-3">
          {!addingInstance && (
            <div
              className="flex items-center text-slate-600 hover:text-slate-800"
              onClick={() => setAddingInstance(!addingInstance)}>
              <PlusCircleIcon width="16" height="16" className="mr-1 cursor-pointer" />
              <span className="cursor-pointer text-sm">Add instance</span>
            </div>
          )}
          <div
            className={`transition-max-height overflow-auto duration-200 ${addingInstance ? 'max-h-72' : 'max-h-0'}`}>
            <InputField label="Host">
              <Input
                placeholder="https://xxxxxx.looker.app"
                value={lookerHost}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setLookerHost(e.target.value)}
              />
            </InputField>
            <InputField label="Client ID">
              <Input
                placeholder="Enter Looker client id"
                value={clientId}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setClientId(e.target.value)}
              />
            </InputField>
            <InputField label="Client Secret">
              <Input
                placeholder="Enter Looker client secret"
                value={clientSecret}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setClientSecret(e.target.value)}
              />
            </InputField>
            <div>{error && <Alert title="Error:" text={error} type="error" />}</div>
            <div className="mt-4 flex h-8 flex-row flex-row-reverse">
              <Button
                type={ButtonTypes.secondary}
                text="Save"
                onClick={onCreateClick}
                isLoading={isLoading}
                className="w-24"
                dataTestId='looker-integration-save-button'
              />
              {integrations.length > 0 && (
                <Button
                  type={ButtonTypes.secondary}
                  text="Cancel"
                  onClick={() => setAddingInstance(false)}
                  className="w-24 mr-2"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const InputField = ({ children, label }: { children: React.ReactNode; label: string }) => {
  return (
    <div className="mb-4 flex items-center justify-between text-text-primary">
      <div className="w-20">{label}</div>
      <div className="w-80">{children}</div>
    </div>
  );
};

export default LookerIntegration;
