import { memo } from 'react';

const PageLoader = memo(({ label }: { label?: string }) => {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="h-16 w-16 animate-spin rounded-full border-t-2 border-lilac-500 border-opacity-40"></div>
      {label && <div className="ml-4 text-gray-600">{label}</div>}
    </div>
  );
});

PageLoader.displayName = 'PageLoader';

export default PageLoader;
