import { useEffect, useMemo, useRef, useState } from "react";
import Table from "../../../../../components/Table/Table";
import { ISuperficialNode } from "../../INode";
import { discoverTableColumns, subResourcesDiscoverColumns } from "./discoverTableColumns";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../../../../infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesQuery, useLazyDownloadNodesCsvQuery, useLazyGetDataModelResourcesQuery } from "../../../../../services/nodes/nodes";
import { useTableColumnSelector } from "../../../../../components/Table/ColumnSelector/useTableColumnSelector";
import { TableColumnProps } from "src/components/Table/types";
import { CustomProperty } from "src/services/nodes/types";
import { notify } from "src/components/Toaster";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { DownloadIcon } from "src/assets/images/icons/DelphiIcons";
import { selectIsMenuCollpased } from "src/infrastructure/state/slices/isMenuCollpasedSlice";
import { events, trackEvent } from "src/infrastructure/analytics";
const defaultPageSize = 25;

type DiscoverTableViewProps = {
    setShowNodeSidepane: (node: ISuperficialNode) => void;
    setSelectedNode: (node: ISuperficialNode) => void;
    showSubResources: boolean;
    eql: string;
    tableName: string;
}

export const DiscoverTableView = ({ setShowNodeSidepane, setSelectedNode, eql, tableName, showSubResources }: DiscoverTableViewProps) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const accountId = useSelector(selectActiveAccountId);
    const [sort, setSort] = useState<{ field: string, direction: 'asc' | 'desc' } | null>(null); // Send sort to BE once /search route is implemented
    const getCustomProperties = useGetCustomPropertiesQuery({ accountId });
    const customProperties = useMemo(() => getCustomProperties.data || [], [getCustomProperties.data]);
    const [getDataModelResources, { isFetching, data: dataModelResourcesData, error }] = useLazyGetDataModelResourcesQuery();
    // Temporary solution till the column selector component will be replaced by the new one in DLP-1153
    const discoverColumns = [...discoverTableColumns];
    if (showSubResources) {
        discoverColumns.splice(2, 0, ...subResourcesDiscoverColumns);
    }
    const columns = [...discoverColumns, ...getCustomColumns(customProperties)];
    const tableColumnSelector = useTableColumnSelector({ columns, autoSaveColumns: true, tableName, skip: getCustomProperties.isLoading });
    const [downloadCsv, { isFetching: isDownloadingCsv, error: errorGettingCsv }] = useLazyDownloadNodesCsvQuery();
    const isMenuCollapsed = useSelector(selectIsMenuCollpased);
    const [menuWidth, setMenuWidth] = useState<number>(0);

    useEffect(() => {
        if (errorGettingCsv) {

            notify('Failed downloading csv', 'error');
        }
    }, [errorGettingCsv]);

    const debouncedGetDataModelResources = useRef(0);

    useEffect(() => {
        const now = Date.now();
        debouncedGetDataModelResources.current = now;
        setTimeout(() => {
            if (now === debouncedGetDataModelResources.current) {
                getDataModelResources({ accountId, page, pageSize, eql, orderBy: sort?.field, orderDirection: sort?.direction, additionalProperties: customProperties.map(({ name }) => name), withSubResources: showSubResources });
            }
        }, 500);
    }, [accountId, page, pageSize, eql, sort, customProperties, getDataModelResources, showSubResources]);

    useEffect(() => {
        if (error) {
            notify('Failed to get data model resources', 'error');
            console.error(error);
        }
    }, [error]);

    const onRowClicked = (row: unknown) => {
        setSelectedNode(row as ISuperficialNode);
        setShowNodeSidepane(row as ISuperficialNode);
    };

    //Update view width when menu is collapsed
    useEffect(() => {
        const menuWidth = document.querySelector('#menu-layout')?.getBoundingClientRect().width || 0;
        setMenuWidth(menuWidth);
    }, [isMenuCollapsed]);

    const onExportClick = () => {
        if (!isDownloadingCsv) {
            downloadCsv({ accountId, eql, properties: tableColumnSelector.selectedColumns.map(c => c.property).join(','), propertyHeaders: tableColumnSelector.selectedColumns.map(c => c.name).join(',') });
            trackEvent(events.dataModelCsvExport);
        }
    };

    return (
        <div className="mt-5 mx-3 relative" style={{ width: `${window.innerWidth - menuWidth - 30}px` }}>
            {tableColumnSelector.columnSelector}
            <Table
                isLoading={isFetching}
                data={dataModelResourcesData?.items || []}
                columns={tableColumnSelector.selectedColumns}
                onRowClicked={onRowClicked}
                pagination={{ page, pageSize, total: dataModelResourcesData?.total || 0, setPage, setPageSize }}
                maxBodyHeight='80vh'
                fixedHeight={true}
                onSort={(field, direction) => {
                    if (sort?.field !== field || sort?.direction !== direction) {
                        setSort({ field, direction });
                    }
                }}
            />
            <div className="absolute bottom-3 left-3">
                {dataModelResourcesData?.items?.length ? <Button isLoading={isDownloadingCsv} onClick={onExportClick} type={ButtonTypes.secondary} text='Download CSV' icon={<DownloadIcon width="16" height="16" />} /> : null}
            </div>
        </div>
    );
};

const getCustomColumns = (customProperties: CustomProperty[]): TableColumnProps[] => {
    const pxPerLetter = 8;
    return customProperties.map(prop => {
        const width = Math.max(prop.name.length * pxPerLetter, 200);
        return {
            name: prop.name,
            selector: row => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const value = (row as any)[prop.name] || '';
                switch (prop.content.type) {
                    case 'boolean':
                        if (value === true) {
                            return 'Yes';
                        } else if (value === false) {
                            return 'No';
                        }
                        else {
                            return '';
                        }
                }
                return value;
            },
            width: `${width}px`,
            property: prop.name,
            sortBy: prop.name,
        };
    });
};
