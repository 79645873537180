import { Tooltip } from "react-tooltip";
import { GeneratedByEunoChipIcon } from "src/assets/images/icons/DelphiIcons";
import { IExpandedNode } from "src/features/models/discover/INode";

export const ResourceSidepaneChips = ({ resource }: { resource: IExpandedNode }) => {
    const chips = getChipsForResources(resource);
    return (
        <div className="flex gap-1 mt-1 flex-wrap">
            {
                resource.generatedByDelphi && (
                    <>
                        <Tooltip anchorSelect="#generated-by-euno-chip">Generated by Euno</Tooltip>
                        <GeneratedByEunoChipIcon width="16" height="16" id="generated-by-euno-chip" />
                    </>
                )
            }
            {chips.map(chip => (
                <div key={chip.name}>
                    {
                        chip.description && (
                            <Tooltip anchorSelect={`#${chip.name}`}>{chip.description}</Tooltip>
                        )
                    }
                    <div id={chip.name} className="border border-slate-100 capitalize text-tertiary rounded px-1 text-sm font-fira-code">{chip.name}</div>
                </div>
            ))}
        </div>
    );
};

const getChipsForResources = (resource: IExpandedNode): { name: string, description: string }[] => {
    const chips = [];
    if (resource.isCalculated) {
        chips.push({ name: 'calculated', description: '' });
    }
    if (resource.dbtMaterializationStrategy) {
        chips.push({ name: resource.dbtMaterializationStrategy, description: 'dbt materialization strategy' });
    }
    if (resource.derivedType) {
        let label;
        switch (resource.derivedType) {
            case 'native_derived_table':
                label = 'Native derived view';
                break;
            case 'sql_derived_table':
                label = 'SQL derived view';
                break;
        }
        chips.push({ name: label, description: 'LookML view derived type' });
    }
    if (resource.dataType) {
        chips.push({ name: resource.dataType, description: 'Data type' });
    }
    if (resource.isProxyToPublishedDatasource) {
        chips.push({ name: 'Instance', description: 'Instance of published data source' });
    }
    if (resource.isIdentityTransformation) {
        chips.push({ name: 'Identity transformation', description: 'Resource has no inherent logic' });
    }
    if (resource.isIdentityTransformation === false) {
        chips.push({ name: 'Contains logic', description: 'resource consists of a transformation that contains logic' });
    }
    if (resource.hasRefinements) {
        chips.push({ name: 'Includes refinements', description: 'This LookML resource includes refinement(s)' });
    }

    return chips;
};