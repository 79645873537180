import { Navigate } from 'react-router-dom';
import { Login } from '../features/auth/Login';
import { UserNotFoundPage } from '../features/auth/UserNotFoundPage';
import { GithubAppInstalledPage } from 'src/features/sources/GithubAppInstalledPage';

const publicRoutes = () => {
  return [
    { path: '/login', element: <Login /> },
    { path: '/github-app-installed', element: <GithubAppInstalledPage /> },
    { path: '/user-not-found', element: <UserNotFoundPage /> },
    { path: '*', element: <Navigate to="/login" replace /> }
  ];
};

export default publicRoutes;
