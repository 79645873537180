import { ButtonTypes } from "src/components/button/types";
import Button from "src/components/button/Button";


export type EnabledSourcesType = {
  setTab: (value: "enabled" | "gallery") => void;
}

const EmptyEnabledSources = ({ setTab } : EnabledSourcesType) => {

  return (
    <div className='w-[50rem] h-40 mt-9 border flex flex-col m-auto rounded-lg items-center'>
      <div className='mt-8 text-lilac-950'>
        You have no enabled sources
      </div>
      <div className='mt-2 text-tertiary'>
        Connect your data sources to start integrating and analyzing your information
      </div>
      <Button
        dataTestId="open-create-project-modal"
        className="mt-4"
        onClick={() => setTab('gallery')}
        type={ButtonTypes.primary}
        text="Browse sources"
      />
    </div>
  );
};

export default EmptyEnabledSources;
