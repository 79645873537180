import { useMemo, useState } from "react";
import { NodeType, SubnodeType } from "src/features/models/discover/INode";
import { NewModelChangeData, NewModelChangeSubnode } from "src/features/evolution/IChange";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { NameForm } from "src/features/evolution/formSections/NameForm";
import { DescriptionForm } from "src/features/evolution/formSections/DescriptionForm";
import { MetaForm } from "src/features/evolution/formSections/MetaForm";
import { TagsForm } from "src/features/evolution/formSections/TagsForm";
import { Search } from "src/components/Search";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { ColumnTestsForm } from "src/features/evolution/formSections/ColumnTestsForm";


type NewModelSubnodeTabProps = {
    changeData: NewModelChangeData;
    setChangeData: (changeData: NewModelChangeData) => void;
    type: 'Columns' | 'Semantics';
    disabled?: boolean;
}

export const NewModelSubnodeTab = ({ changeData, setChangeData, type, disabled = false }: NewModelSubnodeTabProps) => {
    const [selectedSubnode, setSelectedSubnode] = useState('');
    const subnodesInType = useMemo(
        () => changeData.subnodes.filter((subnode) => type === 'Columns' ? subnode.type === SubnodeType.Column : subnode.type !== SubnodeType.Column),
        [changeData.subnodes, type]
    );

    if (subnodesInType.length === 0) {
        return <div className="w-fit mx-auto my-8 text-tertiary">No results found</div>;
    }

    return (
        <div className="flex border-t border-slate-200 flex-1">
            <SubnodeSearch selectedSubnode={selectedSubnode} subnodes={subnodesInType} setSelectedSubnode={setSelectedSubnode} />
            {
                selectedSubnode && (
                    <SubnodeForm changeData={changeData} selectedSubnode={selectedSubnode} setChangeData={setChangeData} disabled={disabled} />
                )
            }
        </div>
    );
};

const SubnodeSearch = ({ selectedSubnode, subnodes, setSelectedSubnode }: { selectedSubnode: string, subnodes: NewModelChangeSubnode[], setSelectedSubnode: (subnode: string) => void }) => {
    const [search, setSearch] = useState('');
    const subnodesToShow = subnodes.filter((subnode) => subnode.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <div className="shrink-0 w-52 bg-white border-r border-slate-200 pb-4 rounded-bl-lg">
            <Search search={search} setSearch={setSearch} className="p-2 mx-auto"/>
            <div className="mt-2 overflow-y-auto max-h-[40vh]">
                {
                    subnodesToShow.map((subnode) => (
                        <div
                            key={subnode.originalName}
                            onClick={() => setSelectedSubnode(subnode.originalName)}
                            className={`py-1 px-2 flex gap-1 items-center border-b border-slate-200 cursor-pointer text-text-primary hover:bg-slate-100 ${selectedSubnode === subnode.name ? 'bg-slate-100' : 'bg-white'}`}
                        >
                            <div><NodeIcon type={NodeType.DataModel} subnodeType={subnode.type} /></div>
                            <TextWithEllipsisAndTooltip text={subnode.name} maxChars={23} />
                        </div>
                    ))
                }
                {
                    search && subnodesToShow.length === 0 && <div className="text-tertiary text-center">No results</div>
                }
            </div>

        </div>
    );
};

const SubnodeForm = ({ changeData, selectedSubnode, setChangeData, disabled }: { disabled: boolean, changeData: NewModelChangeData, selectedSubnode: string, setChangeData: (changeData: NewModelChangeData) => void }) => {
    const subnode = changeData.subnodes.find((subnode) => subnode.originalName === selectedSubnode);
    if (!subnode) return null;
    const setSubnodeValues = (newValues: Partial<NewModelChangeSubnode>) => {
        setChangeData({
            ...changeData,
            subnodes: changeData.subnodes.map((subnode) => subnode.originalName === selectedSubnode ? { ...subnode, ...newValues } : subnode)
        });
    };
    return (
        <div className="flex flex-col gap-2 p-2 pb-2 w-full">
            <NameForm disabled={disabled} labelClassName="w-48" label={subnode.type} name={subnode.name} setName={name => setSubnodeValues({ name })} />
            <DescriptionForm disabled={disabled} labelClassName="w-48" label={subnode.type} description={subnode.description} setDescription={description => setSubnodeValues({ description })} />
            <TagsForm disabled={disabled} labelClassName="w-48" label={subnode.type} tags={subnode.tags} setTags={tags => setSubnodeValues({ tags })} />
            <MetaForm disabled={disabled} labelClassName="w-48" label={subnode.type} meta={subnode.meta} setMeta={meta => setSubnodeValues({ meta })} />
            {
                subnode.type === SubnodeType.Column && (
                    <ColumnTestsForm disabled={disabled} labelClassName="w-48" tests={subnode} setTests={(tests) => setSubnodeValues(tests)} />
                )
            }
        </div>
    );
};

