import { ChangeEvent } from "react";
import Input from "../../../components/form/Input";
import { Bars4Icon } from "@heroicons/react/24/outline";

type NameFormProps = {
    name: string;
    setName: (name: string) => void;
    label: string;
    labelClassName?: string;
    disabled?: boolean;
    withIcon?: boolean;
}

export const NameForm = ({ name, setName, label, labelClassName = 'w-36', disabled = false, withIcon = true }: NameFormProps) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
    return (
        <div className="flex items-center flex-1">
            <div className={`text-secondary text-sm font-medium flex items-center gap-1 ${labelClassName}`}>
                {
                    withIcon && <Bars4Icon width="12" height="12" />
                }
                {label} name
            </div>
            <div className="flex-1">
                <Input
                    value={name}
                    onInputChange={onChange}
                    placeholder="Enter name"
                    height="h-9"
                    disabled={disabled}
                />
            </div>
        </div>
    );
};