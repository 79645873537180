import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Fragment, MouseEvent } from 'react';
import { DropdownMenuItem } from 'src/components/DropdownMenu/types';

interface DropdownMenuProps {
  children?: React.ReactNode;
  className?: string;
  withStopPropogation?: boolean;
  items: DropdownMenuItem[];
}

export default function DropdownMenu({ children, className, items, withStopPropogation}: DropdownMenuProps) {
  const menuClick = (e: MouseEvent<HTMLElement>) => {
    if (withStopPropogation) {
      e.stopPropagation();
    }
  };

  const menuItemClick = (e: MouseEvent<HTMLElement>, onClick: () => void) => {
    if (withStopPropogation) {
      e.stopPropagation();
    }
    onClick();
  };

  return (
    <Menu as="div" className="relative flex" id="open-dropdown-menu-button">
      <Menu.Button onClick={menuClick}>{children || <DefaultDropdownChildren />}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items
          className={`${className || ''
            } bg-white text-title absolute left-0 z-40 mt-7 w-48 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="">
            {items.map((item, index) => (
              <Menu.Item key={index}>
                {() => (
                  <button
                    onClick={(e) => menuItemClick(e, item.onClick)}
                    disabled={item.isDisabled}
                    className={`text-secondary hover:bg-slate-50 bg-white group flex w-full justify-between items-center overflow-hidden rounded-md px-2 py-2 text-sm ${item.className || ''} ${item.isDisabled ? 'cursor-not-allowed' : ''}`}>
                    <div className="w-full flex items-center">
                      {item.icon && <span className="mr-2">{item.icon}</span>}
                      <span className="whitespace-nowrap overflow-hidden text-ellipsis">{item.name}</span>
                    </div>
                    {item.rightElement}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const DefaultDropdownChildren = () => (
  <div className="w-fit hover:bg-slate-100 rounded-full">
    <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
  </div>
);