import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneContentsTabSelector } from "src/features/models/discover/resourceSidepane/contentsTab/ResourceSidepaneContentsTabSelector";
import { ResourceSidepaneRelationshipsTabSelector } from "src/features/models/discover/resourceSidepane/relationshipsTab/ResourceSidepaneRelationshipsTabSelector";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceSidepaneSchemaTabSelector } from "src/features/models/discover/resourceSidepane/schemaTab/ResourceSidepaneSchemaTabSelector";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";
import { ResourceSidepaneUsageTabSelector } from "src/features/models/discover/resourceSidepane/usageTab/ResourceSidepaneUsageTabSelector";
import { ResourceSidepaneUsedFieldsTabSelector } from "src/features/models/discover/resourceSidepane/usedFieldsTab/ResourceSidepaneUsedFieldsTabSelector";

type ResourceSidepaneTabsProps = {
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
    resource: IExpandedNode;
}

export const ResourceSidepaneTabs = ({ activeTab, setActiveTab, resource }: ResourceSidepaneTabsProps) => {
    return (
        <div className="border-l border-border p-4 flex flex-col gap-6">
            {
                Object.values(ResourceTab).map((tab) => <TabMap resource={resource} key={tab} tab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />)
            }
        </div>
    );
};

type TabMapProps = {
    tab: ResourceTab;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
    resource: IExpandedNode;
}

const TabMap = ({ tab, activeTab, setActiveTab, resource }: TabMapProps) => {
    switch (tab) {
        case ResourceTab.Relationships:
            return <ResourceSidepaneRelationshipsTabSelector resource={resource} activeTab={activeTab} setActiveTab={setActiveTab} />;
        case ResourceTab.Schema:
            return <ResourceSidepaneSchemaTabSelector resource={resource} activeTab={activeTab} setActiveTab={setActiveTab} />;
        case ResourceTab.Contents:
            return <ResourceSidepaneContentsTabSelector resource={resource} activeTab={activeTab} setActiveTab={setActiveTab} />;
        case ResourceTab.Usage:
            return <ResourceSidepaneUsageTabSelector resource={resource} activeTab={activeTab} setActiveTab={setActiveTab} />;
        case ResourceTab.UsedFields:
            return <ResourceSidepaneUsedFieldsTabSelector resource={resource} activeTab={activeTab} setActiveTab={setActiveTab} />;
        default:
            return <ResourceSidepaneTab tab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />;
    }
};