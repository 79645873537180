import { MagnifyingGlassIcon, CheckIcon, ChevronLeftIcon, CodeBracketIcon } from "@heroicons/react/24/solid";
import { useState, useMemo, useEffect } from "react";
import Button from "../../../../../components/button/Button";
import { ButtonTypes } from "../../../../../components/button/types";
import { DiscoverFilterProps } from "./Types";
import Input from "../../../../../components/form/Input";
import { useKeyPress } from "react-use";

export const MetaFilter = ({ value, onChange, onSave, onClose, filterOptions }: DiscoverFilterProps) => {
    const parsedMetaValue = useMemo(() => parseMetaValue(value), [value]);
    const metaOptions = stringifiedObjectsToKeyValuePair(filterOptions?.meta || []);
    const keys = [...new Set(metaOptions.map(keyValue => keyValue.split('=')[0]))];
    const [selectedKey, setSelectedKey] = useState<string | null>(null);
    const enterPressed = useKeyPress('Enter');

    useEffect(() => {
        if (enterPressed[0]) {
            onSave();
            onClose();
        }
    }, [enterPressed, onSave, onClose]);

    if (keys.length === 0) {
        return <EmptyState label="Meta" onClose={onClose} />;
    }

    return (
        <div className="w-64">
            {selectedKey ? (
                <SelectValueScreen
                    onChange={onChange}
                    selectedKey={selectedKey}
                    parsedMetaValue={parsedMetaValue}
                    onSave={onSave}
                    onClose={() => setSelectedKey(null)}
                    metaOptions={metaOptions}
                />
            ) : (
                <SelectKeyScreen
                    onSave={onSave}
                    onChange={onChange}
                    onClose={onClose}
                    keys={keys}
                    parsedMetaValue={parsedMetaValue}
                    setSelectedKey={setSelectedKey}
                />
            )}
        </div>
    );
};

interface SelectKeyScreenProps {
    keys: string[];
    parsedMetaValue: { key: string; value: string[] }[];
    setSelectedKey: (key: string) => void;
    onSave: (value?: null) => void;
    onClose: () => void;
    onChange: (value: string | null) => void;
}

const SelectKeyScreen = ({
    keys,
    parsedMetaValue,
    setSelectedKey,
    onSave,
    onClose,
    onChange
}: SelectKeyScreenProps) => {
    const [searchMeta, setSearchMeta] = useState<string>('');
    const filteredKeys = useMemo(
        () => keys.filter((key) => key.toLowerCase().includes(searchMeta.toLowerCase())),
        [searchMeta, keys]
    );

    const onClear = () => {
        onChange(null);
        onSave(null);
        onClose();
    };

    const onDone = () => {
        onClose();
    };

    return (
        <>
            <div className="m-2">
                <Input
                    icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                    placeholder="Key name"
                    value={searchMeta}
                    onInputChange={(e) => setSearchMeta(e.target.value)}
                />
            </div>
            <div className="max-h-48 overflow-auto">
                {filteredKeys.map((key) => {
                    const isSelected = parsedMetaValue.some((meta) => meta.key === key);
                    return (
                        <div
                            key={key}
                            className={`flex cursor-pointer items-center justify-between p-3 hover:bg-slate-50`}
                            onClick={() => setSelectedKey(key)}>
                            <div className="flex items-center gap-2">
                                <CodeBracketIcon width="18" height="18" className="text-slate-400" />

                                <span>{key}</span>
                            </div>
                            {isSelected && <CheckIcon width="18" height="18" className="text-primary" />}
                        </div>
                    );
                })}
            </div>
            <div className="mt-2 flex justify-between p-2">
                <Button type={ButtonTypes.secondary} text="Clear" onClick={onClear} className="w-28" />
                <Button type={ButtonTypes.primary} text="Done" onClick={onDone} className="w-28" />
            </div>
        </>
    );
};

interface SelectValueScreenProps {
    selectedKey: string;
    parsedMetaValue: { key: string; value: string[] }[];
    onChange: (value: string) => void;
    onSave: () => void;
    onClose: () => void;
    metaOptions: string[];
}

const SelectValueScreen = ({
    selectedKey,
    parsedMetaValue,
    onChange,
    onSave,
    onClose,
    metaOptions
}: SelectValueScreenProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const valueOptions = metaOptions.filter((meta) => meta.startsWith(`${selectedKey}=`)).map((meta) => meta.split('=')[1]);
    const selectedValues = parsedMetaValue.find((meta) => meta.key === selectedKey)?.value || [];

    const onClick = (option: string, isSelected: boolean) => {
        const newValue = isSelected ? selectedValues.filter((v) => v !== option) : [...selectedValues, option];
        const newMetaValue = parsedMetaValue
            .filter((meta) => meta.key !== selectedKey)
            .concat([{ key: selectedKey, value: newValue }])
            .filter((meta) => meta.value.length > 0)
            .map((meta) => `${meta.key}=${meta.value.join(',')}`)
            .join(';');
        onChange(newMetaValue);
    };

    const onClear = () => {
        const newMetaValue = parsedMetaValue
            .filter((meta) => meta.key !== selectedKey)
            .map((meta) => `${meta.key}=${meta.value.join(',')}`)
            .join(';');
        onChange(newMetaValue);
        onClose();
    };

    const onApply = () => {
        onSave();
        onClose();
    };

    return (
        <>
            <div
                className="m-2 flex w-fit cursor-pointer items-center text-slate-400 hover:text-slate-600"
                onClick={() => onClose()}>
                <ChevronLeftIcon width="16" height="16" /> Back
            </div>
            <div className="m-2">
                <Input
                    icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                    placeholder={`Values of ${selectedKey}`}
                    value={searchValue}
                    onInputChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            <div className="max-h-64 overflow-auto">
                {valueOptions.map((option) => {
                    const isSelected = selectedValues.includes(option);
                    return (
                        <div
                            key={option}
                            className={`flex cursor-pointer items-center justify-between p-3 hover:bg-slate-50`}
                            onClick={() => onClick(option, isSelected)}>
                            <div className="flex items-center gap-2">
                                <CodeBracketIcon width="18" height="18" className="text-slate-400" />
                                <span>{option}</span>
                            </div>
                            {isSelected && <CheckIcon width="18" height="18" className="text-primary" />}
                        </div>
                    );
                })}
            </div>
            <div className="mt-2 flex justify-between p-2">
                <Button type={ButtonTypes.secondary} text="Clear" onClick={onClear} className="w-28" />
                <Button type={ButtonTypes.primary} text="Apply" onClick={onApply} className="w-28" />
            </div>
        </>
    );
};


const EmptyState = ({ label, onClose }: { label: string, onClose: () => void }) => (
    <div className="w-60 p-2">
        <div className="text-text-primary mb-2">{label}</div>
        <div className="text-text-secondary text-sm mb-2">No options available</div>
        <div className="flex gap-1 mt-5">
            <Button onClick={onClose} type={ButtonTypes.secondary} text='Close' className="w-full" />
        </div>
    </div>
);

const parseMetaValue = (value: string | null) => {
    return (value || '')
        .split(';')
        .filter((v) => v)
        .map((v) => {
            const [key, value] = v.split('=');
            return { key, value: value.split(',') };
        });
};

const stringifiedObjectsToKeyValuePair = (objects: string[]): string[] => {
    const pairs: string[] = [];
    for (const object of objects) {
        try {
            const entries = Object.entries(JSON.parse(object));
            for (const [key, value] of entries) {
                if (key && value) {
                    pairs.push([key, value].join('='));
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    return pairs;
};