import { CheckIcon } from "@heroicons/react/24/outline";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClickAway } from "react-use";
import { DisplaySettingsIcon } from "src/assets/images/icons/DelphiIcons";
import { notify } from "src/components/Toaster";
import { SUB_RESOURCE_TYPES } from "src/features/models/discover/INode";
import { events, trackEvent } from "src/infrastructure/analytics";
import showSubResouresInDataModelTableSlice, { selectShowSubResourcesInDataModelTable } from "src/infrastructure/state/slices/showSubResouresInDataModelTableSlice";
import { mapBackendNodeTypeToLocalNodeType } from "src/services/nodes/transformers";
import { BackendNodeType } from "src/services/nodes/types";

export const DataModelTableDisplaySettings = () => {
    const [showSettings, setShowSettings] = useState(false);
    const ref = useRef(null);
    useClickAway(ref, () => setShowSettings(false));
    const dispatch = useDispatch();
    const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
    useEffect(() => {
        if (!showSubResources && isSubResourceFilterApplied()) {
            dispatch(showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable(true));
        }
    }, [showSubResources, dispatch]);
    const { showDataModelDisplaySettings } = useFlags();

    if (!showDataModelDisplaySettings) {
        return null;
    }
    return (
        <div className="relative">
            <div className="w-fit p-0.5 hover:bg-slate-50 rounded cursor-pointer" onClick={() => setShowSettings(true)}>
                <DisplaySettingsIcon width="24" height="24" />
            </div>
            {
                showSettings && (
                    <div className="z-20 absolute top-8 right-0 shadow-xl bg-slate-50 p-2 flex flex-col gap-4 w-80 rounded-lg border border-border" ref={ref}>
                        <div className="flex gap-2 text-lg text-text-primary mx-auto">
                            <DisplaySettingsIcon width="24" height="24" />
                            Display settings
                        </div>
                        <ResourceSettings />
                    </div>
                )
            }
        </div>
    );
};

const ResourceSettings = () => {
    const dispatch = useDispatch();
    const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
    const setShowSubResources = (showSubResources: boolean) => {
        if (!showSubResources && isSubResourceFilterApplied()) {
            notify('Sub-resources are included in the current filter. Please remove the filter to apply this setting.', 'error');
        }
        else {
            trackEvent(events.dataModelDisplaySubresourcesToggled, { showSubResources });
            dispatch(showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable(showSubResources));
        }
    };
    const SelectedIcon = <div className="ml-auto rounded-full bg-primary w-fit p-1 text-white"><CheckIcon width={12} height={12} /></div>;
    return (
        <div className="flex gap-2">
            <div className={`flex-col flex-1 p-1 cursor-pointer rounded border ${showSubResources ? 'border-border' : 'border-primary'}`} onClick={() => setShowSubResources(false)}>
                <div className="h-10">
                    {
                        !showSubResources && SelectedIcon
                    }
                </div>
                <ShowResourcesOnlyImage />
                <div className="text-text-primary text-center flex-1 w-28 mx-auto">Display Resources Only</div><br />
                <div className="text-secondary text-xs mt-2 text-center w-28 mx-auto">View only the main resources in the table without any sub-resources.</div>
            </div>
            <div className={`flex-col flex-1 p-1 cursor-pointer rounded border ${!showSubResources ? 'border-border' : 'border-primary'}`} onClick={() => setShowSubResources(true)}>
                <div className="h-10">
                    {
                        showSubResources && SelectedIcon
                    }
                </div>
                <ShowResourcesAndSubresourcesImage />
                <div className="text-text-primary text-center flex-1 w-28 mx-auto">Display Resources and Sub-Resources</div>
                <div className="text-secondary text-xs mt-2 text-center w-28 mx-auto">Include both resources and their sub-resources (e.g., columns, dimensions, etc.) for a more detailed view.</div>
            </div>
        </div>
    );
};

const ShowResourcesOnlyImage = () => {
    return (
        <div className="h-20 pt-6">
            <div className="mx-4 shadow-lg rounded-lg border border-slate-300 p-1 flex gap-1">
                <div className="w-3 h-3 rounded-sm bg-slate-300 my-auto" />
                <div className="flex flex-col gap-0.5 my-auto">
                    <div className="w-12 h-1 rounded bg-slate-300" />
                    <div className="w-8 h-1 rounded bg-slate-300" />
                </div>
            </div>
        </div>
    );
};

const ShowResourcesAndSubresourcesImage = () => {
    return (
        <div className="h-20">
            <div className="mx-4 shadow-lg rounded-lg border border-slate-300 p-1">
                <div className="flex gap-1">
                    <div className="w-3 h-3 rounded-sm bg-slate-300 my-auto" />
                    <div className="flex flex-col gap-0.5 my-auto">
                        <div className="w-12 h-1 rounded bg-slate-300" />
                        <div className="w-8 h-1 rounded bg-slate-300" />
                    </div>
                </div>
                <div className="bg-slate-300 h-[1px] my-1 -mx-1"></div>
                <div className="flex gap-1 flex-col">
                    <div className="flex gap-1 items-center">
                        <div className="ml-1 w-2 h-2 rounded-sm bg-slate-300 my-auto" />
                        <div className="w-8 h-1 rounded bg-slate-300" />
                    </div>
                    <div className="flex gap-1 items-center">
                        <div className="ml-1 w-2 h-2 rounded-sm bg-slate-300 my-auto" />
                        <div className="w-8 h-1 rounded bg-slate-300" />
                    </div>
                    <div className="flex gap-1 items-center">
                        <div className="ml-1 w-2 h-2 rounded-sm bg-slate-300 my-auto" />
                        <div className="w-8 h-1 rounded bg-slate-300" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const isSubResourceFilterApplied = (): boolean => {
    const searchParams = new URLSearchParams(window.location.search);
    const typeSearchParam = searchParams.get('Type');
    const backendNodeTypes = (typeSearchParam || '').split(',').map(t => t as BackendNodeType);
    const localNodeTypes = backendNodeTypes.map(t => mapBackendNodeTypeToLocalNodeType.get(t));
    const typeFilterIncludesSubResources = localNodeTypes.some(t => t && SUB_RESOURCE_TYPES.includes(t));
    return typeFilterIncludesSubResources;
};