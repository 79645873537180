import { XMarkIcon } from "@heroicons/react/24/outline";
import { CollapseRightIcon } from "../../../../assets/images/icons/DelphiIcons";
import { ExpandedNode } from "../view/DAG/types";

type ExpandedNodesBadgesProps = {
    expandedNodes: ExpandedNode[];
    setExpandedNodes: (expandedNodes: ExpandedNode[]) => void;
}

export const ExpandedNodesBadges = ({ expandedNodes, setExpandedNodes }: ExpandedNodesBadgesProps) => {
    const expandedRightNodes = expandedNodes.filter(node => node.direction === 'downstream');
    const expandedLeftNodes = expandedNodes.filter(node => node.direction === 'upstream');
    const removeExpandedRightNodes = () => {
        setExpandedNodes(expandedNodes.filter(node => node.direction !== 'downstream'));
    };
    const removeExpandedLeftNodes = () => {
        setExpandedNodes(expandedNodes.filter(node => node.direction !== 'upstream'));
    };
    return (
        <div className="flex gap-1 font-medium">
            {
                expandedRightNodes.length > 0 && (
                    <div
                        className="mb-auto mt-auto flex py-1 items-center justify-center justify-between rounded bg-lilac-100 px-1.5 text-lilac-700">
                        <div className="flex items-center text-primary">{expandedRightNodes.length}
                            <CollapseRightIcon
                                width="18"
                                height="18"
                                className="rotate-180"
                            />
                        </div>
                        <XMarkIcon
                            width="16"
                            height="16"
                            className="ml-1 cursor-pointer text-lilac-400 hover:text-lilac-600"
                            onClick={removeExpandedRightNodes}
                        />
                    </div>
                )
            }
            {
                expandedLeftNodes.length > 0 && (
                    <div
                        className="mb-auto mt-auto flex py-1 items-center justify-center justify-between rounded bg-lilac-100 px-1.5 text-lilac-700">
                        <div className="flex items-center text-primary">{expandedLeftNodes.length}
                            <CollapseRightIcon width="18" height="18"/>
                        </div>
                        <XMarkIcon
                            width="16"
                            height="16"
                            className="ml-1 cursor-pointer text-lilac-400 hover:text-lilac-600"
                            onClick={removeExpandedLeftNodes}
                        />
                    </div>
                )
            }
        </div>
    );
};