import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import Table from '../../components/Table/Table';
import { ClipboardDocumentIcon, PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import Button from '../../components/button/Button';
import { ButtonTypes } from '../../components/button/types';
import Modal from '../../components/Modal/Modal';
import Input from '../../components/form/Input';
import {
  useGetIntegrationKeysQuery,
  useCreateIntegrationKeyMutation,
  useRevokeIntegrationKeyMutation
} from '../../services/integrations/integrations';
import { notify } from '../../components/Toaster';
import { extractErrorMessage } from '../../services/api';
import { Alert } from '../../components/Alert';
import IIntegrationKey from './IIntegrationKey';
import { dateFormats, utcToLocal } from '../../infrastructure/dateUtilities';

const IntegrationKeysSection = () => {
  const accountId = useSelector(selectActiveAccountId);
  const { data: integrationKeys = [] } = useGetIntegrationKeysQuery(accountId);
  const [showCreateKeyModal, setShowCreateKeyModal] = useState(false);
  const [showRevokeKeyModal, setShowRevokeKeyModal] = useState(false);
  const [integrationKeyToRevoke, setIntegrationKeyToRevoke] = useState<IIntegrationKey | null>(null);

  const onRevokeClick = (integrationKeyId: number) => {
    const integrationKey = integrationKeys.find((key) => key.id === integrationKeyId);
    setIntegrationKeyToRevoke(integrationKey || null);
    setShowRevokeKeyModal(true);
  };

  const tableColumns = [
    {
      name: 'KEY NAME',
      selector: (row: unknown) => <span className="font-medium text-text-primary">{(row as IIntegrationKey).name as string}</span>
    },
    {
      name: 'CREATED ON',
      selector: (row: unknown) => `${utcToLocal(`${(row as IIntegrationKey).createdAt}`, dateFormats.monthsDaysHoursAndMinutes)}`
    },
    {
      name: 'BY',
      selector: (row: unknown) => `${(row as IIntegrationKey).createdBy}`
    },
    {
      name: '',
      selector: (row: unknown) => (
        <span
          onClick={() => onRevokeClick((row as IIntegrationKey).id)}
          className="text-md flex cursor-pointer items-center text-danger hover:text-danger-strong">
          <XMarkIcon width="20" height="20" className="mr-1" /> Revoke
        </span>
      )
    }
  ];

  return (
    <div className="mb-8">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-text-primary text-lg">Secret Keys</span>
        <Button
          text="Create new key"
          icon={<PlusIcon width="16" height="16" />}
          type={ButtonTypes.secondary}
          onClick={() => setShowCreateKeyModal(true)}
        />
      </div>
      <Table data={integrationKeys} columns={tableColumns} />
      <CreateKeyModal isOpen={showCreateKeyModal} onClose={() => setShowCreateKeyModal(false)} accountId={accountId} />
      <RevokeKeyModal
        isOpen={showRevokeKeyModal}
        onClose={() => setShowRevokeKeyModal(false)}
        integrationKey={integrationKeyToRevoke}
        accountId={accountId}
      />
    </div>
  );
};

interface CreateKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
}

const CreateKeyModal = ({ isOpen, onClose, accountId }: CreateKeyModalProps) => {
  const [newKeyName, setNewKeyName] = useState('');
  const [createKey, { isLoading: isLoadingCreateKey }] = useCreateIntegrationKeyMutation();
  const [secretKey, setSecretKey] = useState('');
  const [createKeyError, setCreateKeyError] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setSecretKey(generateRandomSecret());
      setNewKeyName('');
    }
  }, [isOpen]);

  const onCreateKeyClick = async () => {
    if (!newKeyName) {
      setCreateKeyError('Key name is required');
      return;
    }
    try {
      await createKey({ accountId, name: newKeyName, key: secretKey }).unwrap();
      notify('Key created successfully', 'success');
      onClose();
    } catch (e) {
      notify(`Failed to create key: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const copySecretKey = () => {
    navigator.clipboard.writeText(secretKey);
    notify('Secret key copied to clipboard', 'success');
  };

  const onKeyNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewKeyName(e.target.value);
    setCreateKeyError('');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create a new secret key"
      maxWidth="max-w-lg"
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.primary, text: 'Create key', onClick: onCreateKeyClick, isLoading: isLoadingCreateKey }
      ]}>
      <div className="mb-5">
        <InputField label="Key name" inputWidth="w-80">
          <Input placeholder="Enter key name" value={newKeyName} onInputChange={onKeyNameChange} />
        </InputField>
        {createKeyError && <span className="text-danger text-sm">{createKeyError}</span>}
      </div>
      <div className="mb-5">
        <InputField label="Secret key" inputWidth="w-80">
          <div className="flex items-center justify-between">
            <div className="text-slate-500 text-sm">{secretKey}</div>
            <div
              onClick={copySecretKey}
              className="flex cursor-pointer items-center font-thin text-lilac-600 hover:text-lilac-800">
              <ClipboardDocumentIcon width="16" height="16" className="mr-1" />
              Copy
            </div>
          </div>
        </InputField>
      </div>
      <div>
        <Alert title="Attention:" text="The key will not be visible after its creation." type="warning" />
      </div>
    </Modal>
  );
};

const InputField = ({
  children,
  label,
  inputWidth = 'w-72',
  labelClassName = 'w-28'
}: {
  children?: React.ReactNode;
  label: string;
  inputWidth?: string;
  labelClassName?: string;
}) => {
  return (
    <div className="flex items-center justify-between text-text-primary">
      <div className={labelClassName}>{label}</div>
      <div className={inputWidth}>{children}</div>
    </div>
  );
};

interface RevokeKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  integrationKey: IIntegrationKey | null;
  accountId: number;
}

const RevokeKeyModal = ({ isOpen, onClose, integrationKey, accountId }: RevokeKeyModalProps) => {
  const [deleteKey, { isLoading }] = useRevokeIntegrationKeyMutation();

  if (!integrationKey) {
    return null;
  }
  const onDeleteClick = async () => {
    try {
      await deleteKey({ accountId, integrationKeyId: integrationKey.id }).unwrap();
      notify('Key revoked successfully', 'success');
      onClose();
    } catch (e) {
      notify(`Failed to delete key: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Revoke key"
      icon={<TrashIcon width="18" height="18" className="mr-2" />}
      buttons={[
        { type: ButtonTypes.secondary, text: 'Cancel', onClick: onClose },
        { type: ButtonTypes.danger, text: 'Revoke key', onClick: onDeleteClick, isLoading }
      ]}>
      <span className="flex text-lilac-950">
        Are you sure you want to revoke the key
        <span className="ml-1 font-medium">{integrationKey.name}</span>?
      </span>
    </Modal>
  );
};

const generateRandomSecret = () => {
  const length = 32;
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return retVal;
};

export default IntegrationKeysSection;
