interface ToggleProps {
  onChange: (currentState: boolean) => void;
  className?: string;
}

export const Toggle = ({ onChange, className }: ToggleProps) => {
  const enabled = className?.includes('bg-toggle-on') || false;
  const enabledClass = enabled ? 'left-5' : 'left-0';
  return (
    <div className={`w-11 h-6 rounded-2xl cursor-pointer transition-all duration-500 ${className}`} onClick={() => onChange(enabled)}>
      <div className={`w-5 h-5 bg-white rounded-2xl m-0.5 absolute ${enabledClass} transition-all duration-500 `}></div>
    </div>
  );
};
