import { useCallback } from 'react';
import { DatabaseTechnology, ISuperficialNode, NODES_TO_SHOW_CONTAINERS, NodeType, nodeNameMap } from '../../INode';
import { NodeIcon } from '../../NodeIcon';
import { InformationCircleIcon, TableCellsIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import { BlueCircleWithStarsIcon, ContainerIcon, ExpandRightFullIcon, ExpandRightShallowIcon, SidePaneIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { getNodeIdForDom } from '../../getNodeIdForDom';
import { ExpandedNode, IDAGNode, OnCollapse, SetShowNodeSidepane } from './types';
import exportedVariables from '../../../../../../exportedVariables.json';

interface DAGNodeProps {
  node: IDAGNode;
  selectedNode: ISuperficialNode | null;
  setSelectedNode: (node: ISuperficialNode | null) => void;
  criticalPath: string[];
  setShowNodeSidepane: SetShowNodeSidepane;
  onCollapse: OnCollapse;
  withFilters: boolean;
  expandedNodes: ExpandedNode[];
}

export const DAGNode = ({
  node,
  selectedNode,
  setSelectedNode,
  criticalPath,
  setShowNodeSidepane,
  onCollapse,
  withFilters,
  expandedNodes
}: DAGNodeProps) => {
  const isSelected = node.id === selectedNode?.id;
  const borderColor = isSelected ? 'border-lilac-400' : 'border-slate-200';
  const hover = isSelected ? '' : 'hover:bg-slate-50';
  const isNodePartOfCriticalPath = selectedNode && criticalPath.includes(node.id);
  const opacity = (selectedNode && !isNodePartOfCriticalPath) || (node.isConnectedNode && !isNodePartOfCriticalPath) ? 'opacity-40' : '';
  const zIndex = isSelected ? 'z-[11]' : 'z-10';
  const onNodeClick = useCallback(() => {
    if (isSelected) {
      setSelectedNode(null);
    } else {
      setSelectedNode(node);
    }
  }, [isSelected, node, setSelectedNode]);
  let typeName = nodeNameMap.get(node.type);
  if (node.type === NodeType.Table && node.databaseTechnology === DatabaseTechnology.snowflake && node.materialized === false) {
    typeName = 'View';
  }
  return (
    <>
      <div
        id={getNodeIdForDom(node.id, 'dag')}
        className={`${opacity} ${borderColor} ${hover} ${zIndex} relative w-64 rounded-lg border bg-white px-2 py-1 shadow transition-opacity duration-300`}>
        <div
          onClick={onNodeClick}
          className={`flex cursor-pointer items-center justify-between`}
          id={`header-${getNodeIdForDom(node.id, 'dag')}`}>
          <div className="flex items-center">
            <NodeIcon type={node.type} withBackground={true} withPadding={true} databaseTechnology={node.databaseTechnology} />
            <div>
              <div title={node.name} className="ml-2 w-44 whitespace-nowrap overflow-hidden text-ellipsis text-sm font-medium text-text-primary">{node.name}</div>
              <div className="ml-2 w-44 text-xs text-tertiary whitespace-nowrap overflow-hidden text-ellipsis flex items-center">{typeName}{NODES_TO_SHOW_CONTAINERS.includes(node.type) && node.parentName ? <>{' in '}<div className="bg-gray-100 px-1 text-ellipsis overflow-hidden whitespace-nowrap rounded-sm ml-1"><ContainerIcon className="mr-1 inline" />{node.parentName}</div></> : ''}</div>
            </div>
          </div>
          <div>
            {
              node.type === NodeType.GenericDataTransformation && <OrphanIndicator node={node} />
            }
            {
              node.hasProposals && <ProposalIndicator />
            }
          </div>
        </div>
      </div>
      {
        isSelected && node.type !== NodeType.GenericDataTransformation && (
          <>
            <NodeTopMenu
              node={node}
              setShowNodeSidepane={setShowNodeSidepane}
            />
          </>
        )
      }
      {
        isSelected && node.type !== NodeType.GenericDataTransformation && withFilters && (
          <>
            <NodeLeftMenu
              node={node}
              onCollapse={onCollapse}
              expandedNodes={expandedNodes}
            />
          </>
        )
      }
      {
        isSelected && node.type !== NodeType.GenericDataTransformation && withFilters && (
          <>
            <NodeRightMenu
              node={node}
              onCollapse={onCollapse}
              expandedNodes={expandedNodes}
            />
          </>
        )
      }
    </>
  );
};

const OrphanIndicator = ({ node }: { node: ISuperficialNode }) => {
  const elId = `${getNodeIdForDom(node.id, 'dag')}-orphan-information-icon`;
  return (
    <>
      <Tooltip anchorSelect={`#${elId}`}>
        <div>
          <div className="text-white gap-1 flex items-center">
            <TableCellsIcon width="12" height="12" />
            Orphan table
          </div>
          <div className="text-tertiary">
            {node.databaseSchema} schema in {node.database}
          </div>
        </div>
      </Tooltip>
      <InformationCircleIcon width="16" height="16" className="text-slate-400" id={elId} />
    </>
  );
};

const ProposalIndicator = () => {
  return (
    <BlueCircleWithStarsIcon width="22" height="22" className="absolute -top-3 right-1" />
  );
};


interface NodeTopMenuProps {
  node: IDAGNode;
  setShowNodeSidepane: SetShowNodeSidepane;
}

const NodeTopMenu = ({ node, setShowNodeSidepane }: NodeTopMenuProps) => {
  return (
    <Tooltip
      offset={2}
      noArrow={true}
      clickable={true}
      anchorSelect={`#${getNodeIdForDom(node.id, 'dag')}`}
      id="node-top-menu"
      className="z-20 flex !rounded-lg !p-0"
      isOpen={true}>
      <div
        className={`cursor-pointer rounded-lg bg-slate-950 p-1 hover:bg-slate-700`}
        onClick={() => setShowNodeSidepane(node)}>
        <SidePaneIcon width="16" height="16" fill="white" />
      </div>
    </Tooltip>
  );
};

interface NodeSideMenuProps {
  node: IDAGNode;
  onCollapse: OnCollapse;
  expandedNodes: ExpandedNode[];
}

const NodeLeftMenu = ({ node, onCollapse, expandedNodes }: NodeSideMenuProps) => {
  const expandedClass = 'bg-primary hover:bg-lilac-500';
  const collapsedClass = 'bg-white hover:bg-slate-100';
  const direction = 'upstream';
  const activeExpansion = expandedNodes.find((m) => m.nodeId === node.id && m.direction === direction);
  const isFullyExpanded = activeExpansion?.depth === null;
  const isDirectlyExpanded = activeExpansion && !isFullyExpanded;

  return (
    <div className="absolute left-[-16px] top-[3px] z-10 cursor-pointer p-[0px] rounded-md border border-slate-200">
      {
        !isFullyExpanded && (
          <div
            className={`${isDirectlyExpanded ? expandedClass : collapsedClass} p-[3px] rounded-tl-md`}
            onClick={() => onCollapse({ node, direction, depth: 1 })}
          >
            <ExpandRightShallowIcon
              width="10"
              height="10"
              stroke={isDirectlyExpanded ? 'white' : exportedVariables.primary}
              className={isDirectlyExpanded ? '' : 'rotate-180'}
            />
          </div>
        )
      }
      <div
        className={isFullyExpanded ? `${expandedClass} rounded-l-md px-[3px] py-[11px]` : `${collapsedClass} rounded-bl-md p-[3px]`}
        onClick={() => onCollapse({ node, direction, depth: null })}
      >
        <ExpandRightFullIcon
          width="10"
          height="10"
          stroke={isFullyExpanded ? 'white' : exportedVariables.primary}
          className={isFullyExpanded ? '' : 'rotate-180'}
        />
      </div>
    </div>
  );
};

const NodeRightMenu = ({ node, onCollapse, expandedNodes }: NodeSideMenuProps) => {
  const expandedClass = 'bg-primary hover:bg-lilac-500';
  const collapsedClass = 'bg-white hover:bg-slate-100';
  const direction = 'downstream';
  const activeExpansion = expandedNodes.find((m) => m.nodeId === node.id && m.direction === direction);
  const isFullyExpanded = activeExpansion?.depth === null;
  const isDirectlyExpanded = activeExpansion && !isFullyExpanded;

  return (
    <div className="absolute right-[-7px] top-[3px] z-10 cursor-pointer p-[0px] rounded-md border border-slate-200">
      {
        !isFullyExpanded && (
          <div
            className={`${isDirectlyExpanded ? expandedClass : collapsedClass} p-[3px] rounded-tr-md`}
            onClick={() => onCollapse({ node, direction, depth: 1 })}
          >
            <ExpandRightShallowIcon
              width="10"
              height="10"
              stroke={isDirectlyExpanded ? 'white' : exportedVariables.primary}
              className={isDirectlyExpanded ? 'rotate-180' : ''}
            />
          </div>
        )
      }
      <div
        className={isFullyExpanded ? `${expandedClass} rounded-r-md px-[3px] py-[11px]` : `${collapsedClass} rounded-br-md p-[3px]`}
        onClick={() => onCollapse({ node, direction, depth: null })}
      >
        <ExpandRightFullIcon
          width="10"
          height="10"
          stroke={isFullyExpanded ? 'white' : exportedVariables.primary}
          className={isFullyExpanded ? 'rotate-180' : ''}
        />
      </div>
    </div>
  );
};
