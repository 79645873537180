import { TailSpin } from 'react-loader-spinner';
import { useState } from 'react';
import { GithubIcon, PullRequestIcon } from '../../../assets/images/icons/DelphiIcons';
import Modal from '../../../components/Modal/Modal';
import { notify } from '../../../components/Toaster';
import { ButtonTypes } from '../../../components/button/types';
import { extractErrorMessage } from '../../../services/api';
import { useCreatePullRequestMutation, useGetChangeQuery } from '../../../services/changes/changes';
import { IChange } from '../IChange';
import { Operation, OperationStatus } from '../../operations/Operation';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useGetOperationLogsQuery } from '../../../services/accounts/accounts';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import Button from '../../../components/button/Button';
import ConnectedBulletPoints from '../../../components/ConnectedBulletPoints';
import waitForOperationToComplete from '../../../infrastructure/waitForOperation';
import { events, trackEvent } from '../../../infrastructure/analytics';
import exportedVariables from '../../../../exportedVariables.json';

interface CreatePullRequestModalProps {
  change: IChange;
  onClose: () => void;
  isOpen: boolean;
}

interface GetModalButtonsByOperationStatusProps {
  operation: Operation | null;
  onClose: () => void;
  createPullRequest: () => void;
  isLoadingCreatePullRequest: boolean;
}

const getModalButtonsByOperationStatus = ({
  operation,
  onClose,
  createPullRequest,
  isLoadingCreatePullRequest
}: GetModalButtonsByOperationStatusProps) => {
  if (!operation) {
    return [
      {
        type: ButtonTypes.secondary,
        text: 'Cancel',
        onClick: onClose
      },
      {
        type: ButtonTypes.primary,
        text: 'Continue',
        onClick: createPullRequest,
        isLoading: isLoadingCreatePullRequest
      }
    ];
  } else if ([OperationStatus.Running, OperationStatus.Pending].includes(operation.status)) {
    return [
      {
        type: ButtonTypes.primary,
        text: 'Continue',
        onClick: () => { },
        isLoading: true
      }
    ];
  } else {
    return [
      {
        type: ButtonTypes.primary,
        text: 'Done',
        onClick: onClose
      }
    ];
  }
};

const CreatePullRequestModal = ({ change, onClose, isOpen }: CreatePullRequestModalProps) => {
  const [createPullRequestMutation, { isLoading: isLoadingCreatePullRequest }] = useCreatePullRequestMutation();
  const accountId = useSelector(selectActiveAccountId);
  const [operation, setOperation] = useState<Operation | null>(null);
  const getChangeQuery = useGetChangeQuery({ projectId: change.projectId, changeId: change.id || 0 });

  const createPullRequest = async () => {
    try {
      if (!change.id) throw new Error('Change ID is missing');
      trackEvent(events.dataModelPRCreated, { change_type: change.changeType });
      const operation = await createPullRequestMutation({ projectId: change.projectId, changeId: change.id }).unwrap();
      setOperation(operation);
      const finalOperationState = await waitForOperationToComplete(operation.id, accountId);
      if (finalOperationState.status === OperationStatus.Completed) {
        getChangeQuery.refetch();
      }
      setOperation(finalOperationState);
    } catch (e) {
      notify(`Failed to create pull request: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const buttons = getModalButtonsByOperationStatus({
    operation,
    onClose,
    createPullRequest,
    isLoadingCreatePullRequest
  });

  return (
    <Modal buttons={buttons} title="Create pull request" onClose={onClose} isOpen={isOpen}>
      <div>
        <PullRequestIcon fill="#334155" height={30} width={30} className="ml-auto mr-auto" />
      </div>
      <div className="mt-4 flex flex-col items-center gap-4 rounded-lg border border-slate-200 bg-surface-light p-6 text-text-primary">
        <PullRequestCreationState change={change} operation={operation} />
      </div>
    </Modal>
  );
};

const BeforeOperationBanner = () => {
  const bulletContents = ['Create branch', 'Commit change', 'Submit pull request'];
  return (
    <>
      <div className="font-medium">Euno will execute the following actions on your behalf:</div>
      <ConnectedBulletPoints
        bulletPoints={bulletContents.map((content) => ({
          content,
          textColor: 'text-primary',
          bulletColor: 'bg-text-primary',
          lineColor: 'bg-slate-200'
        }))}
      />
    </>
  );
};

const RunningOperationBanner = () => {
  return (
    <>
      <TailSpin
        height="32"
        width="32"
        color={exportedVariables.primary}
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass="ml-auto mr-auto"
        visible={true}
      />
      <div className="mt-4">Creating pull request...</div>
    </>
  );
};

const FailedOperationBanner = ({ operation }: { operation: Operation }) => {
  const accountId = useSelector(selectActiveAccountId);
  const logs = useGetOperationLogsQuery({ accountId, operationId: operation.id }, { skip: !operation })?.data || '';
  return (
    <>
      <ExclamationCircleIcon width="32" height="32" className="ml-auto mr-auto text-secondary" />
      <div className="w-full">
        <div className="text-center font-medium">Something went wrong</div>
        <div className="mt-1 max-h-48 w-full overflow-auto whitespace-pre-line rounded-lg border border-slate-300 p-1 text-sm	">
          {logs}
        </div>
      </div>
    </>
  );
};

const CompletedOperationBanner = ({ change }: { change: IChange }) => {
  return (
    <>
      <CheckCircleIcon width="32" height="32" className="ml-auto mr-auto text-primary" />
      <div className="font-medium">Pull request created</div>
      <Button
        type={ButtonTypes.secondary}
        icon={<GithubIcon width="12" height="12" fill="#94A3B8" />}
        text="View in Github"
        className="mt-4"
        onClick={() => window.open(change.pullRequestUrl, '_blank')}
      />
    </>
  );
};

const PullRequestCreationState = ({ change, operation }: { change: IChange; operation: Operation | null }) => {
  if (!operation) {
    return <BeforeOperationBanner />;
  } else if ([OperationStatus.Running, OperationStatus.Pending].includes(operation.status)) {
    return <RunningOperationBanner />;
  } else if (operation.status === OperationStatus.Completed) {
    return <CompletedOperationBanner change={change} />;
  } else {
    return <FailedOperationBanner operation={operation} />;
  }
};

export default CreatePullRequestModal;
