import { mapLocalSubnodeTypeToBackendSubnodeType } from "../../../services/nodes/transformers";
import { SubnodeType } from "./INode";

export const getNodeUrl = (nodeId: string, viewType: 'table' | 'graph' = 'graph') => {
    return `/data-model?view=${viewType}&URI=${nodeId}&resourceId=${nodeId}`;
};

export const getSubnodeUrl = (nodeId: string, subnodeType?: SubnodeType, subnodeName?: string) => {
    const parameters = [nodeId];
    if (subnodeType && subnodeName) {
        const backendSubnodeType = mapLocalSubnodeTypeToBackendSubnodeType.get(subnodeType) || '';
        parameters.push(backendSubnodeType, subnodeName);
    }
    const subResourceId = parameters.join('.');
    return `/data-model?view=graph&URI=${subResourceId}&resourceId=${subResourceId}`;
};