import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { SparklesLeftArrowIcon } from "../../../assets/images/icons/DelphiIcons";
import { IChange } from "../IChange";
import { notify } from "../../../components/Toaster";
import { useNavigate } from "react-router-dom";
import { getNodeUrl } from "../../models/discover/getNodeUrl";

export const ModelChangeSection = ({ change, children }: { change: IChange, children: JSX.Element }) => {
    const navigate = useNavigate();

    const goToSourceNode = () => {
        const sourceNodeUtl = change.sourceUtl;
        if (!sourceNodeUtl) {
            notify('No source node found', 'error');
        }
        else {
            navigate(getNodeUrl(sourceNodeUtl));
        }
    };

    return (
        <div className="rounded-lg border border-slate-200 bg-white p-4 text-secondary flex-1 flex flex-col gap-4">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-2"><SparklesLeftArrowIcon width="20" height="20" fill="#94a3b8" /> Model change</div>
                <div onClick={goToSourceNode} className="p-1 rounded hover:bg-slate-100 cursor-pointer bg-surface-light text-primary flex gap-2">View source and usage <ArrowUpRightIcon width="16" height="16" /></div>
            </div>
            <div>
                {children}
            </div>
        </div>
    );
};