import { AnalyticsBrowser } from '@segment/analytics-next';
import { store } from "./state/store";
import config from '../config';

const client = AnalyticsBrowser.load({ writeKey: config.segmentWriteKey });
let cachedIdentity = '';

export enum events {
  pageView = 'page_view',
  newFolder = 'new_folder',
  stakeholderRoleUpdated = 'stakeholder_role_updated',
  magicLinkLogin = 'magic_link_login',
  dataModelFilterAdded = 'data_model_filter_added',
  dataModelEnteredNode = 'data_model_entered_node',
  nodeSidepaneOpened = 'node_sidepane_opened',
  dagSearched = 'dag_searched',
  dagExpanded = 'dag_expanded',
  nodeUsageExpanded14Days = 'node_usage_expanded_14d',
  nodeUsageExpanded30Days = 'node_usage_expanded_30d',
  nodeUsageExpanded60Days = 'node_usage_expanded_60d',
  dataModelPRCreated = 'data_model_pr_created',
  dataModelChangeStateUpdated = 'data_model_change_state_updated',
  dataModelChangeEdited = 'data_model_change_edited',
  dataModelChangeCreated = 'data_model_change_created',
  pageVisit = 'page_visit',
  resourceOpenedInDataModelFromDashboard = 'resource_opened_in_data_model_from_dashboard',
  resourceOpenedInSidepaneFromDashboard = 'resource_opened_in_sidepane_from_dashboard',
  resourceSidepaneOpened = 'resource_sidepane_opened',
  resourceSidepaneNavigatedToTab = 'resource_sidepane_navigated_to_tab',
  resourceSidepaneBreadcrumbClick = 'resource_sidepane_breadcrumb_click',
  resourceSidepaneCopyUrlClick = 'resource_sidepane_copy_url_click',
  resourceSidepaneViewCodeClick = 'resource_sidepane_view_code_click',
  resourceSidepaneSearched = 'resource_sidepane_searched',
  dataModelColumnAdded = 'data_model_column_added',
  dataModelColumnRemoved = 'data_model_column_removed',
  dataModelColumnReordered = 'data_model_column_reordered',
  dataModelDisplaySubresourcesToggled = 'data_model_display_subresources_toggled',
  dataModelViewModeToggled = 'data_model_view_mode_toggled',
  dataModelTooLarge = 'data_model_too_large',
  dataModelPerformanceWarningDisplayed = 'data_model_performance_warning_displayed',
  dataModelWorkbooksNotShownErrorDisplayed = 'data_model_workbooks_not_shown_error_displayed',
  dataModelCsvExport = 'data_model_csv_export',
  errorToasterShown = 'error_toaster_shown',
}

export const identifyUser = (user: string, traits: Record<string, unknown>) => {
  const hashedIdentity = JSON.stringify({ user, traits });
  if (cachedIdentity === hashedIdentity) return;
  cachedIdentity = hashedIdentity;
  client.identify(user, traits);
};

export const trackEvent = (eventName: events, properties?: object) => {
  properties = properties || {};
  const accountId = store.getState().activeAccount;
  client.track(eventName, { ...properties, accountId });
};

export const resetIdentity = () => {
  client.reset();
};