import { Bars4Icon, BookOpenIcon, InformationCircleIcon, PresentationChartLineIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import { useRef } from "react";
import { useHoverDirty } from "react-use";
import { RelationshipIcon } from "src/assets/images/icons/DelphiIcons";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

const TabToIconMap: Record<ResourceTab, React.ReactNode> = {
    [ResourceTab.Overview]: <BookOpenIcon width="16" height="16" className="mx-auto" />,
    [ResourceTab.Schema]: <Bars4Icon width="16" height="16" className="mx-auto" />,
    [ResourceTab.Contents]: <Squares2X2Icon width="16" height="16" className="mx-auto" />,
    [ResourceTab.UsedFields]: <Bars4Icon width="16" height="16" className="mx-auto" />,
    [ResourceTab.Usage]: <PresentationChartLineIcon width="16" height="16" className="mx-auto" />,
    [ResourceTab.Relationships]: <RelationshipIcon width="16" height="16" className="mx-auto" />,
    [ResourceTab.About]: <InformationCircleIcon width="16" height="16" className="mx-auto" />,
};

type ResourceSidepaneTabProps = {
    tab: ResourceTab;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneTab = ({ tab, activeTab, setActiveTab }: ResourceSidepaneTabProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(ref);
    return (
        <div ref={ref} key={tab} className={`cursor-pointer text-center flex flex-col gap-1 ${activeTab === tab ? 'text-primary' : 'hover:text-primary'}`} onClick={() => setActiveTab(tab)}>
            <div className={`w-fit p-2 rounded mx-auto ${(isHovering || activeTab === tab) && 'bg-primary-light'}`}>
                {TabToIconMap[tab]}
            </div>
            {tab}
        </div>
    );
};