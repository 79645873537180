import api from './api';
import IStakeholder, { Roles } from '../features/stakeholders/IStakeholder';

interface StakeholdersResponse {
  id: number;
  stakeholders: [
    {
      role: Roles;
      user: string;
    }
  ];
  inherited_stakeholders: [
    {
      role: Roles;
      user: string;
      inherited_from: {
        name: string;
        parent: number;
        id: number;
      };
    }
  ];
}

export const branchesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBranchStakeholders: build.query<IStakeholder[], { id: number }>({
      query: (data) => `branches/${data.id}/stakeholders`,
      providesTags: ['Stakeholders'],
      transformResponse: (response: StakeholdersResponse) => {
        return [...response.stakeholders, ...response.inherited_stakeholders];
      }
    }),
    setBranchStakeholders: build.mutation<void, { id: number; role: Roles; user: string }>({
      query(data) {
        return {
          url: `branches/${data.id}/stakeholders`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Stakeholders']
    }),
    deleteBranchStakeholders: build.mutation<void, { entityId: number; user: string }>({
      query(data) {
        return {
          url: `branches/${data.entityId}/stakeholders`,
          method: 'DELETE',
          body: { user: data.user }
        };
      },
      invalidatesTags: ['Stakeholders']
    }),
    archiveBranch: build.mutation<void, { id: number }>({
      query(data) {
        return {
          url: `branches/${data.id}/archive`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Branches', 'Projects']
    })
  })
});

export const {
  useGetBranchStakeholdersQuery,
  useSetBranchStakeholdersMutation,
  useArchiveBranchMutation,
  useDeleteBranchStakeholdersMutation
} = branchesApi;
