import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import GithubIntegration from './GithubIntegration';
import DbtCloudIntegration from './DbtCloudIntegration';
import GitlabIntegration from './GitlabIntegration';
import LookerIntegration from "./LookerIntegration";
import TableauIntegration from "./TableauIntegration";
import MetabaseIntegration from "./MetabaseIntegration";
import SnowflakeIntegration from "./SnowflakeIntegration";


const IntegrationsSection = () => {
  const accountId = useSelector(selectActiveAccountId);
  const { showSourcesTabInNavigation } = useFlags();

  if (showSourcesTabInNavigation) {
    return (
      <div className="mb-10">
        <div className="mb-2 h-8 text-lg">
          <span className="text-text-primary">Linked Accounts</span>
        </div>
        <div className="flex gap-3">
          <GithubIntegration accountId={accountId}/>
          <DbtCloudIntegration accountId={accountId}/>
          <GitlabIntegration accountId={accountId}/>
        </div>
      </div>
    );
  }
  return (
    <div className="mb-10">
      <div className="mb-2 h-8 text-lg">
        <span className="text-text-primary">Integrations</span>
      </div>
      <div className="flex gap-3">
        <GithubIntegration accountId={accountId} />
        <LookerIntegration accountId={accountId} />
        <MetabaseIntegration accountId={accountId} />
        <DbtCloudIntegration accountId={accountId} />
        <TableauIntegration accountId={accountId} />
        <GitlabIntegration accountId={accountId} />
        <SnowflakeIntegration accountId={accountId} />
      </div>
    </div>
  );
};


export default IntegrationsSection;
