import { ButtonTypes } from 'src/components/button/types';
import Modal from 'src/components/Modal/Modal';
import {ClipboardDocumentIcon} from "@heroicons/react/24/solid";
import {notify} from "src/components/Toaster";
import {Alert} from "src/components/Alert";

interface IntegrationInfoModalProps {
  info: string;
  onClose: () => void;
  modalTitle: string;
  infoTitle: string;
  infoSubTitle?: string;
  label: string;
  withAlert?: boolean
}

export const IntegrationInfoModal = ({ info, onClose, modalTitle, infoTitle, infoSubTitle, label, withAlert}: IntegrationInfoModalProps) => {
  const copy = () => {
    navigator.clipboard.writeText(info);
    notify('Copied to clipboard', 'success');
  };

  return (
    <Modal
      isOpen={info.length > 0}
      onClose={onClose}
      title={modalTitle}
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl"
    >
      <div className="flex flex-col text-center mt-2">
        <div className="text-text-primary text-2xl">{infoTitle}</div>
        {infoSubTitle && (
          <div className="text-tertiary">{infoSubTitle}</div>
        )}
        <div className="flex flex-row justify-between bg-slate-50 border border-slate-200 p-4 my-4 rounded-lg">
          <div className="text-secondary">{label}</div>
          <div className="text-slate-500">{info}</div>
          <div className="flex items-center cursor-pointer select-none" onClick={copy}>
            <ClipboardDocumentIcon width={12} height={12} className="text-lilac-600"/>
            <span className="text-lilac-600 ml-1">Copy</span>
          </div>
        </div>
        {withAlert && (
          <Alert
            className="!border-0 rounded-lg mb-4"
            title="Attention:"
            text="The key will not be visible after its creation."
            type="warning"
          />
        )}
      </div>
    </Modal>
  );
};
