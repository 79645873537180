import { useCallback, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import Button from '../../../../../components/button/Button';
import { ButtonTypes } from '../../../../../components/button/types';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../../../infrastructure/state/slices/activeAccountSlice';
import waitForOperationToComplete from '../../../../../infrastructure/waitForOperation';
import { extractErrorMessage } from '../../../../../services/api';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useGetProjectConfigurationQuery } from '../../../../../services/projects/projects';
import { selectActiveProject } from '../../../../../infrastructure/state/slices/activeProjectSlice';
import { OperationStatus } from '../../../../operations/Operation';
import exportedVariables from '../../../../../../exportedVariables.json';

enum ApplyChangesStatus {
  applying,
  failed,
  success
}

interface SyncConfigurationApplyChangesProps {
  onClose: () => void;
  operationId: number | null;
}

const SyncConfigurationApplyChanges = ({ onClose, operationId }: SyncConfigurationApplyChangesProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const [applyChangesStatus, setApplyChangesStatus] = useState(ApplyChangesStatus.applying);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const projectId = useSelector(selectActiveProject);
  const getProjectConfigurationQuery = useGetProjectConfigurationQuery({ projectId });

  const waitToApply = useCallback(async () => {
    if (!operationId || isLoading) return;
    try {
      setIsLoading(true);
      const result = await waitForOperationToComplete(operationId, accountId);
      if (result.status === OperationStatus.Failed) {
        throw Error('Failed applying configuration changes');
      }
      getProjectConfigurationQuery.refetch();
      setApplyChangesStatus(ApplyChangesStatus.success);
    } catch (e) {
      setError(extractErrorMessage(e).message);
      setApplyChangesStatus(ApplyChangesStatus.failed);
    } finally {
      setIsLoading(false);
    }
  }, [operationId, isLoading, accountId, getProjectConfigurationQuery]);

  useEffect(() => {
    waitToApply();
  }, [waitToApply]);

  return (
    <div>
      <div className="rounded-lg border border-slate-200 bg-slate-50">
        <div className="flex flex-col gap-2 p-4 text-center text-tertiary">
          {applyChangesStatus === ApplyChangesStatus.applying && (
            <>
              <TailSpin height="24" width="24" color={exportedVariables.primary} wrapperClass="mx-auto" radius="1" visible={true} />
              Applying changes...
            </>
          )}
          {applyChangesStatus === ApplyChangesStatus.success && (
            <>
              <CheckCircleIcon width="24" height="24" className="mx-auto text-green-500" />
              Changes applied successfully
            </>
          )}
          {applyChangesStatus === ApplyChangesStatus.failed && (
            <>
              <ExclamationCircleIcon width="24" height="24" className="mx-auto text-red-500" />
              {`Couldn't apply changes`}
            </>
          )}
        </div>
        {error && (
          <div className="max-h-40 overflow-auto p-2 text-sm text-text-primary">
            <span className="font-medium">Error:</span> {error}
          </div>
        )}
      </div>
      <div className="ml-auto mt-5 flex w-fit gap-2">
        <Button
          type={ButtonTypes.primary}
          onClick={onClose}
          className="w-36"
          text="Done"
          isDisabled={applyChangesStatus === ApplyChangesStatus.applying}
        />
      </div>
    </div>
  );
};

export default SyncConfigurationApplyChanges;
