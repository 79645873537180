import {ChangeEvent} from 'react';
import Input from "src/components/form/Input";
import {FormField} from "src/components/form/FormField";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/24/solid";
import {useState} from "react";

type MappingType = {
	label: string,
	existingMappings: { [key: string]: string; }
	sourcePlaceholder: string,
	targetPlaceholder: string,
	setMappings: (newMapping: { [key: string]: string|object; }) => void
}

const Mapping = ({label, existingMappings, sourcePlaceholder, targetPlaceholder, setMappings }: MappingType) => {
	const [source, setSource] = useState('');
	const [target, setTarget] = useState('');

	const row = (newSource: string, newTarget: string, existing= true, index: number) =>  (
		<div className='flex flex-row gap-2 mt-2' key={`${index}`}>
			<Input
				placeholder={sourcePlaceholder}
				value={newSource}
				disabled={existing}
				onInputChange={(e: ChangeEvent<HTMLInputElement>) => setSource(e.target.value)}
			/>
			<Input
				placeholder={targetPlaceholder}
				value={typeof newTarget === 'object' ? JSON.stringify(newTarget) : newTarget}
				disabled={existing}
				onInputChange={(e: ChangeEvent<HTMLInputElement>) => setTarget(e.target.value)}
			/>
			{ existing && (
				<MinusCircleIcon
					className='text-slate-400 w-10 cursor-pointer'
					onClick={() => setMappings(Object.fromEntries(Object.entries(existingMappings).filter(mapping => mapping[0] !== newSource)))}
				/>
			)}
			{ !existing && (
				<PlusCircleIcon
					className={`
						${source.length > 0 && target.length > 0 ? 'text-slate-400' : 'disabled text-slate-300'}
						w-10 cursor-pointer
					`}
					onClick={() => {
						if (source.length > 0 && target.length > 0) {
							setMappings({...existingMappings, [source]: target});
							setSource('');
							setTarget('');
						}
					}}
				/>
			)}
		</div>
	);

	return (
		<div>
			<FormField
				label={label}
				className="flex-col !items-start"
				fieldWrapperClassName='w-full'
				labelClassName='text-secondary mb-1'
			>
				{ Object.entries(existingMappings || []).map((entry, index) => row(entry[0], entry[1], true, index))}
				{ row(source, target, false, -1) }
			</FormField>
		</div>
	);
};

export default Mapping;