import {useMemo, useState} from "react";
import {useGetAccountOperationsQuery} from "src/services/accounts/accounts";
import PageLoader from "src/components/loaders/PageLoader";
import Table from "src/components/Table/Table";
import {Operation} from "src/features/operations/Operation";
import {dateFormats, utcToLocal} from "src/infrastructure/dateUtilities";
import OperationStatusLabel from "src/features/operations/OperationStatusLabel";
import {ButtonTypes} from "src/components/button/types";
import Button from "src/components/button/Button";
import {ExternalLinkIcon} from "src/assets/images/icons/DelphiIcons";
import {OperationModal} from "./OperationModal";


const getTableColumns = (setOpenedOperation: (o: Operation) => void) => {
  const tableColumns = [
    {
      name: 'Run ID',
      selector: (row: unknown) => {
        return (row as Operation).id;
      },
    },
    {
      name: 'Timestamp',
      selector: (row: unknown) => (
        <span className="text-text-primary">{utcToLocal((row as Operation).start_time, dateFormats.monthsDaysHoursAndMinutes)}</span>
      ),
    },
    {
      name: 'Run Trigger',
      selector: (row: unknown) => (
        <span className="text-text-primary capitalize">{(row as Operation).trigger}</span>
      )
    },
    {
      name: 'Run Status',
      selector: (row: unknown) => {
        return (
          <div className="flex items-center">
            <OperationStatusLabel status={(row as Operation).status} />
            <Button
              className='absolute right-3'
              type={ButtonTypes.primary}
              text="Open Report"
              icon={<ExternalLinkIcon width="16" height="16"/>}
              onClick={() => setOpenedOperation(row as Operation)}
            />
          </div>
        );
      }
    }
  ];
  return tableColumns;
};


const SourceOperations = ({ accountId, integrationId }: { accountId: number, integrationId: string }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openedOperation, setOpenedOperation] = useState<Operation|null>(null);
  const getAccountOperations = useGetAccountOperationsQuery({ accountId, page, pageSize, integrationId });
  const tableColumns = useMemo(
    () => getTableColumns(setOpenedOperation),
    [setOpenedOperation,]
  );
  const { total = 0, items: operations = [] } = getAccountOperations.data || {};

  if (getAccountOperations.isLoading) {
    return (<PageLoader />);
  }

  return (
    <div className="px-10 py-8">
      <Table
        data={operations}
        columns={tableColumns}
        pagination={{ page, pageSize, total, setPage, setPageSize }}
        maxBodyHeight='65vh'
      />
      <OperationModal isOpen={openedOperation !== null} operation={openedOperation} onClose={() => setOpenedOperation(null)}/>
    </div>
  );
};

export default SourceOperations;
