import { ArrowUpRightIcon, BookOpenIcon, ClockIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { NodeUsageAppType } from "src/services/nodes/types";
import { IExpandedNode, ISourceNodeUsage, IUserNodeUsage, LOOKER_TYPES, TABLEAU_TYPES } from "src/features/models/discover/INode";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { useHoverDirty } from "react-use";
import { formatSecondsToHoursAndMinutes } from "src/infrastructure/dateUtilities";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneUsageOverviewProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneUsageOverview = ({ resource, setResourceId, setActiveTab }: ResourceSidepaneUsageOverviewProps) => {
    const showUsage = !!resource.usage;
    const showImpressions = !!resource.impressions && (!!resource.impressions.usage14Days || !!resource.impressions.usage30Days || !!resource.impressions.usage60Days);
    const showPdt = !!resource.pdtBuildsLast30d;
    const showViews = !!resource.last30DaysViews;

    if (!showUsage && !showImpressions && !showPdt && !showViews) {
        if ([...TABLEAU_TYPES, ...LOOKER_TYPES].includes(resource.type)) {
            return <NoUsage />;
        } else {
            return null;
        }
    }

    const openUsageTab = () => {
        setActiveTab(ResourceTab.Usage);
    };

    return (
        <div className="px-4 w-[45%] mt-4 p-2 rounded bg-surface-light flex flex-col gap-2 text-sm border border-border">
            <div className="flex justify-between">
                <div className="text-secondary">Usage</div>
                <ArrowUpRightIcon width="12" height="12" className="-mr-2 text-slate-400 cursor-pointer hover:text-slate-500" onClick={openUsageTab} />
            </div>
            {
                showUsage && <Usage setResourceId={setResourceId} resource={resource} />
            }
            {
                showImpressions && <Impressions setResourceId={setResourceId} resource={resource} />
            }
            {
                showPdt && <Pdt resource={resource} />
            }
            {
                showViews && <Views resource={resource} />
            }
        </div >
    );
};

type UsageProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
}
const Usage = ({ resource, setResourceId }: UsageProps) => {
    if (!resource.usage) return null;
    let totalCount;
    let sources;
    let users;
    let timeframeLabel;
    if (resource.usage?.usage60Days) {
        totalCount = resource.usage.usage60Days;
        sources = resource.usage.sources60Days || [];
        users = resource.usage.users60Days || [];
        timeframeLabel = 'last 60 days';
    }
    else if (resource.usage?.usage30Days) {
        totalCount = resource.usage.usage30Days;
        sources = resource.usage.sources30Days || [];
        users = resource.usage.users30Days || [];
        timeframeLabel = 'last 30 days';
    }
    else {
        totalCount = resource.usage?.usage14Days || 0;
        sources = resource.usage?.sources14Days || [];
        users = resource.usage?.users14Days || [];
        timeframeLabel = 'last 14 days';
    }
    return (
        <div>
            <div className="flex gap-2">
                <div className="text-text-primary font-medium text-base">{totalCount} Queries</div>
                <div className="text-tertiary self-end">{timeframeLabel}</div>
            </div>
            <div className="flex gap-2 text-tertiary">
                <WithSourcesBreakdown setResourceId={setResourceId} sources={sources}>
                    <div>{sources.length} Sources</div>
                </WithSourcesBreakdown>
                <WithUsersBreakdown users={users}>
                    <div className="flex gap-1 items-center"><UsersIcon width="14" height="14" /> {users.length} Users</div>
                </WithUsersBreakdown>
            </div>
        </div>
    );
};

type WithSourcesBreakdownProps = {
    sources: ISourceNodeUsage[];
    children: React.ReactNode;
    setResourceId: (id: string) => void;
}
const WithSourcesBreakdown = ({ sources, children, setResourceId }: WithSourcesBreakdownProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const sourcesToShow = useMemo(() => (sources || []).slice(0, 5), [sources]);
    const ref = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(ref);
    const sourceResources = useGetDataModelResourcesQuery({
        accountId,
        page: 1,
        pageSize: 100,
        eql: `uri in (${sourcesToShow.map(s => s.utl).filter(u => !!u).map(utl => `'${utl}'`).join(',')})`
    }, { skip: sourcesToShow.length === 0 || !isHovering });
    const sourcesWithTitles = useMemo(() => sourcesToShow.map(s => {
        const utl = s?.utl || '';
        const title = sourceResources.data?.items.find(r => r.id === utl)?.name || '';
        return { ...s, title };
    }), [sourcesToShow, sourceResources.data]);
    return (
        <div className="relative" ref={ref}>
            {children}
            {
                isHovering && sources.length > 0 && (
                    <div className="absolute bg-white z-10 flex-1 border border-border p-2 rounded shadow whitespace-nowrap">
                        <div className="text-text-primary font-semibold">Top sources</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                [...sourcesWithTitles || []].sort((a, b) => b.number - a.number).slice(0, 5).map(source => (
                                    <Source key={source.utl} source={source} setResourceId={setResourceId} />
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

const WithUsersBreakdown = ({ users, children }: { users: IUserNodeUsage[], children: React.ReactNode }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHovering = useHoverDirty(ref);
    return (
        <div className="relative" ref={ref}>
            {children}
            {
                isHovering && users.length > 0 && (
                    <div className="absolute bg-white z-10 flex-1 border border-border p-2 rounded shadow whitespace-nowrap">
                        <div className="text-text-primary font-semibold">Top users</div>
                        <div className="flex flex-col gap-2 mt-2">
                            {
                                [...users || []].sort((a, b) => b.number - a.number).slice(0, 5).map(user => (
                                    <div className="flex items-center justify-between gap-8" key={user.email}>
                                        <div className="text-text-primary">{user.email}</div>
                                        <div className="text-tertiary">{user.number}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
};

type ImpressionsProps = {
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
}

const Impressions = ({ resource, setResourceId }: ImpressionsProps) => {
    const totalCount = resource.impressions?.usage60Days || 0;
    const sources = resource.impressions?.sources60Days || [];
    const users = resource.impressions?.users60Days || [];
    if (!resource.impressions) return null;
    return (
        <div>
            <div className="flex gap-2">
                <div className="text-text-primary font-medium text-base">{totalCount} Impressions</div>
                <div className="text-tertiary self-end">last 60 days</div>
            </div>
            <div className="flex gap-2 text-tertiary">
                <WithSourcesBreakdown sources={sources} setResourceId={setResourceId}>
                    <div>{sources.length} Sources</div>
                </WithSourcesBreakdown>
                <WithUsersBreakdown users={users}>
                    <div className="flex gap-1 items-center"><UsersIcon width="14" height="14" /> {users.length} Users</div>
                </WithUsersBreakdown>
            </div>
        </div>
    );
};

type SourceProps = {
    source: ISourceNodeUsage & { title?: string };
    setResourceId: (id: string) => void;
}
const Source = ({ source, setResourceId }: SourceProps) => {
    const goToSource = () => {
        if (source.utl) {
            setResourceId(source.utl);
        }
    };
    let title = source.title || 'Other';
    let subtitle = sourceTypeMap[source.type] || '';

    if (source.type == NodeUsageAppType.other) {
        subtitle = '';
    } else if (source.type === NodeUsageAppType.explore) {
        title = 'Explore';
        subtitle = '';
    } else if (source.dashboardTitle) {
        subtitle = `${subtitle}: ${source.dashboardTitle}`;
    }
    return (
        <div className="flex gap-8 items-center justify-between">
            <div className="flex gap-1 items-center">
                <div className={`text-sm ${source.utl ? 'cursor-pointer text-text-primary' : 'text-slate-400 italic'}`} onClick={goToSource}>{title}</div>
                <div className="text-tertiary">{subtitle}</div>
            </div>
            <div className="text-tertiary">{source.number}</div>
        </div>
    );
};

const NoUsage = () => {
    const openUsageDocs = () => {
        window.open('https://docs.euno.ai/data-model-screen', '_blank');
    };
    return (
        <div className="border border-border rounded w-[45%] mt-4 text-center flex flex-col gap-2 min-h-[100px]">
            <div className="w-fit m-auto">
                <div className="text-base text-tertiary">
                    No usage data
                </div>
                <div className="flex gap-1 w-fit cursor-pointer text-slate-400 hover:text-slate-500" onClick={openUsageDocs}>
                    <BookOpenIcon width="16" height="16" />
                    Learn more about usage
                </div>
            </div>
        </div>
    );
};

const Pdt = ({ resource }: { resource: IExpandedNode }) => {
    return (
        <div>
            <div className="flex gap-2">
                <div className="text-text-primary font-medium text-base">{resource.pdtBuildsLast30d || 0} PDT builds</div>
                <div className="text-tertiary self-end">last 30 days</div>
            </div>
            <div className="text-tertiary flex gap-1 items-center">
                <ClockIcon width="12" height="12" />
                {formatSecondsToHoursAndMinutes(resource.pdtTotalBuildTime30d || 0)} total build time
            </div>
        </div>
    );
};

const Views = ({ resource }: { resource: IExpandedNode }) => {
    return (
        <div>
            <div className="flex gap-2">
                <div className="text-text-primary font-medium text-base">{resource.last30DaysViews || 0} Views</div>
                <div className="text-tertiary self-end">last 30 days</div>
            </div>
        </div>
    );
};

const sourceTypeMap = {
    [NodeUsageAppType.look]: 'Look',
    [NodeUsageAppType.dashboard_element]: 'Tile',
    [NodeUsageAppType.api_query]: 'API',
    [NodeUsageAppType.explore]: 'Explore',
    [NodeUsageAppType.other]: 'Other'
};