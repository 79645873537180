import Select, { Option } from "../../../components/form/Select";
import { TagIcon } from "@heroicons/react/24/outline";

type TagsFormProps = {
    tags: string[];
    setTags: (tags: string[]) => void;
    label: string;
    labelClassName?: string;
    disabled?: boolean;
};

export const TagsForm = ({ tags, setTags, label, labelClassName = 'w-36', disabled = false }: TagsFormProps) => {
    const tagOptions = tags.map((tag) => ({ label: tag, value: tag }));
    const onChange = (options: unknown) => {
        setTags((options as Option[]).map((option) => option.value as string));
    };
    return (
        <div className="flex items-center">
            <div className={`text-secondary text-sm font-medium flex items-center gap-1 ${labelClassName}`}>
                <TagIcon width="12" height="12" />
                {label} Tags
            </div>
            <div className="flex-1">
                <Select
                    options={tagOptions}
                    value={tags}
                    onChange={onChange}
                    placeholder="Enter tags"
                    className="w-full rounded-md border shadow"
                    isMulti
                    isCreatable
                    height="h-9"
                    dataTestId="tags-form-input"
                    isDisabled={disabled}
                />
            </div>
        </div>
    );
};