import Modal from '../../components/Modal/Modal';
import { ButtonTypes } from '../../components/button/types';
import { notify } from '../../components/Toaster';
import { extractErrorMessage } from '../../services/api';
import { useAcceptEulaMutation, useGetLatestEulaQuery } from '../../services/eula';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';

const Eula = () => {
  const accountId = useSelector(selectActiveAccountId);
  const { data } = useGetLatestEulaQuery({ accountId });
  const [accept, { isLoading }] = useAcceptEulaMutation();
  const isAccepted = data?.is_accepted;
  const onContinueClick = async () => {
    try {
      await accept({ version: data?.version || '', accountId }).unwrap();
    } catch (e) {
      notify(`Failed to accept EULA: ${extractErrorMessage(e).message}`, 'error');
    }
  };
  if (isAccepted || !data) {
    return null;
  }
  return (
    <Modal
      isOpen={!isAccepted}
      onClose={() => {}}
      buttons={[{ isLoading, type: ButtonTypes.primary, text: 'Continue', onClick: onContinueClick, dataTestId: 'eula-continue' }]}
      title="End User License Agreement">
      By continuing, you confirm you are accepting Euno’s
      <a className="ml-1 cursor-pointer text-primary" target="_blank" href={data?.url} rel="noreferrer">
        License Agreement
      </a>
      .
    </Modal>
  );
};

export default Eula;
