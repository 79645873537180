import { Logo } from '../../assets/images/icons/DelphiIcons';
import { useEffect } from 'react';

export const GithubAppInstalledPage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = 'https://euno.ai';
    }, 5000);
  }, []);

  return (
    <div className="pt-8 pb-4 px-4 items-center justify-center bg-slate-50 w-96 mx-auto mt-[20vh] border border-border rounded-lg">
        <Logo className="mx-auto" />
        <h1 className="text-xl font-bold text-center mt-8 text-text-primary">Euno application was successfully approved in Github.</h1>
        <hr className="border-gray-300 mt-8 mb-4 -mx-4" />
        <p className="text-slate-400 text-base text-center">You will now be redirected to the Euno landing page</p>
    </div>
  );
};
