import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useSelector } from "react-redux";
import Input from "src/components/form/Input";
import { List } from "src/components/List";
import { DASHBOARD_TYPES, IExpandedNode, nodeNameMap, nodeTypePlurals } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { sortListAlphabeticallyByName } from "src/features/models/discover/resourceSidepane/sortListAlphabeticallyByName";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { shortenString } from "src/utils/stringsUtils";

export const ResourceSidepaneContentsTab = ({ resource, setResourceId }: { resource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const [inputSearch, setInputSearch] = useState<string>('');
    const isDashboard = DASHBOARD_TYPES.includes(resource.type);
    const accountId = useSelector(selectActiveAccountId);
    const getUpstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 100, eql: `has downstream(uri='${resource.id}', 1)` }, { skip: !isDashboard });
    const upstreamResources = getUpstreamResources.data?.items || [];
    const contentResources = isDashboard ? upstreamResources : resource.containedNodes;
    const filteredContentResources = contentResources.filter(r => r.name.toLowerCase().includes(inputSearch.toLowerCase()));
    const resourceTypes = Array.from(new Set(filteredContentResources.map(n => n.type))).sort();
    return (
        <div className="bg-surface-light flex-1">
            <div className="p-4 flex-1 overflow-y-auto flex flex-col gap-4 max-h-[80vh] h-full">
                <div className="flex justify-between mx-2 items-center">
                    <div className="text-secondary font-semibold">{filteredContentResources.length} items</div>
                    <Input
                        placeholder="Search"
                        value={inputSearch}
                        onInputChange={e => setInputSearch(e.target.value)}
                        border="border-primary"
                        rounded="rounded-full"
                        className="!w-60"
                        height="h-8"
                        icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    {
                        resourceTypes.map((type, i) => {
                            const items = filteredContentResources
                                            .sort(sortListAlphabeticallyByName)
                                            .filter(n => n.type === type)
                                            .map(r => ({
                                                title: shortenString(r.name, 50),
                                                value: nodeNameMap.get(r.type) || '',
                                                onClick: () => setResourceId(r.id),
                                                icon: <NodeIcon type={r.type} iconSize={14} />
                                            }));
                            return (
                                <List
                                    key={type}
                                    label={nodeTypePlurals[type]}
                                    items={items}
                                    isCollapsible={true}
                                    defaultCollapsed={i !== 0}
                                    headerActions={<div className="ml-auto text-slate-400">{items.length}</div>}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};