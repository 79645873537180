import { ButtonTypes } from 'src/components/button/types';
import Modal from 'src/components/Modal/Modal';
import {Operation} from "src/features/operations/Operation";
import { dateFormats, utcToLocal } from 'src/infrastructure/dateUtilities';

interface LogsModalProps {
  isOpen: boolean;
  operation: Operation | null;
  onClose: () => void;
}

export const LogsModalContent = ({ operation }: { operation: Operation }) => {

  const contentMap = {
    ID: operation.id,
    Name: operation.name,
    'Start Time': utcToLocal(operation.start_time, dateFormats.monthsDaysHoursAndMinutes),
    'End Time': operation.end_time ? utcToLocal(operation.end_time, dateFormats.monthsDaysHoursAndMinutes): '',
    'Project ID': operation.project_id,
    'Observer ID': operation.observer_id,
    'Observer Type': operation.observer_type,
    Status: operation.status,
    Trigger: operation.trigger
  };

  return (
    <div className="mt-4 max-h-64 overflow-auto whitespace-pre-line rounded-lg border border-slate-200 bg-surface-light
      p-2 text-sm text-text-primary">
      {Object.entries(contentMap).map(([label, value]) => (
        <div className="text-text-primary text-base" key={label}>
          {label}:
          <span className="ml-2 text-sm text-slate-500">{value}</span>
        </div>
      ))}
    </div>
  );
};

export const OperationModal = ({ isOpen, operation, onClose}: LogsModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Run Report"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose }]}
      maxWidth="max-w-2xl"
    >
      {operation && <LogsModalContent operation={operation} />}
    </Modal>
  );
};
