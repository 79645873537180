import loginImg from '../../assets/images/login.png';
import { Descope, useSession, useUser } from '@descope/react-sdk';
import Container from '../../components/layout/Container';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/images/icons/DelphiIcons';
import config from '../../config';
import PageLoader from '../../components/loaders/PageLoader';
import { MagicLinkLogin } from './MagicLinkLogin';
import { SparklesIcon } from '@heroicons/react/24/solid';
import * as Sentry from '@sentry/react';

const DESCOPE_LOG_IN_TIMEOUT_MS = 10000;
const useLoggingIn = () => {
  const params = new URLSearchParams(window.location.search);
  const [loggingIn, setLoggingIn] = useState(!!params.get('logging-in'));

  useEffect(() => {
    const loggingInTimeout = setTimeout(() => {
      setLoggingIn(false);
    }, DESCOPE_LOG_IN_TIMEOUT_MS);

    return () => {
      clearTimeout(loggingInTimeout);
    };
  }, []);

  return loggingIn;
};

export const Login = () => {
  const { isSessionLoading } = useSession();
  const { isUserLoading } = useUser();
  const loggingIn = useLoggingIn();
  const [withMagicLink, setWithMagicLink] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleGeneralError = useCallback((e: Error | string | Event) => {
    Sentry.captureException(e);
    console.error(e);
    setErrorMessage(e.toString());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const magicLinkToken = params.get('t');
    if (magicLinkToken) {
      setWithMagicLink(true);
    }
  }, []);

  if (isUserLoading || loggingIn || isSessionLoading) {
    return (
      <>
        <PageLoader label="Logging In" />
        {loggingIn && <DescopeLogin onError={handleGeneralError} />}
      </>
    );
  }
  else {
    return (
      <div className="flex h-screen w-full items-center justify-center bg-gray-100">
        <div className="mr-32 flex flex-col items-center md:flex-row">
          <Container>
            <div className="h-72 w-72">
              <Logo className="mx-auto mt-8" />
              {
                errorMessage && <div className="text-red-500 text-sm -mb-4 w-fit mx-auto">{errorMessage}</div>
              }
              {
                withMagicLink && <MagicLinkLogin onError={handleGeneralError} onClose={() => setWithMagicLink(false)} />
              }
              {
                !withMagicLink && (
                  <>
                    <DescopeLogin onError={handleGeneralError} />
                    <div onClick={() => setWithMagicLink(true)} className="w-fit mt-2 cursor-pointer text-sm text-primary mx-auto flex gap-1 items-center">
                      <SparklesIcon width="12" height="12" />
                      Send me a magic link
                    </div>
                  </>
                )
              }
            </div>
          </Container>
        </div>
        <img src={loginImg} className="max-w-[30vw]" />
      </div>
    );
  }
};

const DescopeLogin = ({ onError }: { onError: (e: Error | string | Event) => void }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="mx-auto w-fit mt-8 text-md mb-2">Welcome back</div>
      <Descope
        flowId={config.descopeAuthFlowId}
        redirectUrl={`${window.location.href}?logging-in=true`}
        onError={onError}
        onSuccess={() => {
          navigate('/');
        }}
      />
    </>
  );
};