import { ChangeEvent, useCallback, useEffect, useRef } from 'react';

interface InputProps {
  icon?: JSX.Element;
  placeholder?: string;
  value: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  onSelectChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  onEnter?: () => void;
  selectOptions?: {
    value: string;
    label: string;
    disabled?: boolean;
  }[];
  type?: 'text' | 'password' | 'number' | 'email';
  height?: 'h-8' | 'h-9' | 'h-10';
  background?: 'bg-white' | 'bg-slate-100' | 'bg-slate-50';
  border?: string;
  shadow?: '' | 'shadow-sm' | 'shadow-md';
  rounded?: 'rounded-2xl' | 'rounded-md' | 'rounded-full';
  className?: string;
  inputClassName?: string;
  highlightBorder?: '' | 'border-lilac-400';
  dataTestId?: string;
  focus?: boolean;
  disabled?: boolean;
}

const Input = ({
  type = 'text',
  icon,
  placeholder,
  value,
  onInputChange,
  error,
  onSelectChange,
  selectOptions,
  onEnter,
  height = 'h-9',
  background = 'bg-white',
  border = 'border-grey-300',
  shadow = 'shadow-sm',
  rounded = 'rounded-md',
  className = '',
  inputClassName = '',
  highlightBorder = 'border-lilac-400',
  dataTestId,
  focus = false,
  disabled = false,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onEnter && inputRef.current) {
        onEnter();
      }
    },
    [onEnter]
  );

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus]);

  const disabledClassName = disabled ? 'bg-slate-50 cursor-not-allowed' : '';

  return (
    <div className={`w-full ${className}`} data-test-id={dataTestId}>
      <div className={`relative ${rounded} ${shadow}`}>
        {icon && <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">{icon}</div>}
        <input
          type={type}
          value={value}
          onChange={onInputChange}
          className={`form-control w-full ${rounded} border text-secondary ${shadow} outline-0 ${border} hover:${highlightBorder} focus:${highlightBorder} active:${highlightBorder} ${
          icon ? 'pl-8 pr-3' : 'px-3'
          } ${height} ${disabledClassName ? disabledClassName : background} ${inputClassName}`}
          placeholder={placeholder}
          ref={inputRef}
          onKeyPress={onKeyPress}
          disabled={disabled}
        />
        {selectOptions && onSelectChange && (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <div className="mr-2 w-px bg-gray-300" style={{ height: 'calc(100% - 20px)' }}></div>
            <select
              onChange={onSelectChange}
              className="mr-2 h-full rounded-md border-0 bg-transparent text-secondary outline-0">
              {selectOptions.map((option) => (
                <option value={option.value} key={option.value} disabled={option.disabled || false}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      {error && <p className="mt-1 text-danger">{error}</p>}
    </div>
  );
};

export default Input;
