import { NodeIcon } from '../../NodeIcon';
import { ISuperficialNode, nodeNameMap } from '../../INode';
import { TableColumnProps } from '../../../../../components/Table/types';
import { DescriptionColumn } from './DescriptionColumn';
import { SparklesLeftArrowIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { dateFormats, formatSecondsToHoursAndMinutes, utcToLocal } from 'src/infrastructure/dateUtilities';
import { CellWithTooltipAndCopy } from 'src/components/Table/CellWithTooltipAndCopy';
export const discoverTableColumns: TableColumnProps[] = [
  {
    name: 'Type',
    property: 'type',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center"><NodeIcon type={(row as ISuperficialNode).type} databaseTechnology={(row as ISuperficialNode).databaseTechnology} /> {nodeNameMap.get((row as ISuperficialNode).type)}</div>,
    width: '200px',
    isNotRemovable: true,
    sortBy: 'type',
  },
  {
    name: 'Name',
    property: 'name',
    selector: row => <CellWithTooltipAndCopy text={(row as ISuperficialNode).name} maxChars={40} />,
    grow: 10,
    isNotRemovable: true,
    sortBy: 'name',
  },
  {
    name: 'Description',
    property: 'description',
    selector: row => <DescriptionColumn row={row as ISuperficialNode} />,
    width: '300px',
    sortBy: 'description',
  },
  {
    name: 'Columns',
    property: 'number_of_columns',
    selector: row => printNumber((row as ISuperficialNode).numberOfColumns),
    width: '110px',
    sortBy: 'number_of_columns',
  },
  {
    name: 'Dimensions',
    selector: row => printNumber((row as ISuperficialNode).numberOfDimensions),
    width: '140px',
    property: 'number_of_dimensions',
    sortBy: 'number_of_dimensions',
  },
  {
    name: 'Measures',
    selector: row => printNumber((row as ISuperficialNode).numberOfMeasures),
    width: '110px',
    property: 'number_of_measures',
    sortBy: 'number_of_measures',
  },
  {
    name: 'Entities',
    selector: row => printNumber((row as ISuperficialNode).numberOfEntities),
    width: '110px',
    property: 'number_of_entities',
    sortBy: 'number_of_entities',
  },
  {
    name: 'Related Metrics',
    selector: row => printNumber((row as ISuperficialNode).numberOfMetrics),
    width: '150px',
    property: 'number_of_metrics',
    sortBy: 'number_of_metrics',
  },
  {
    name: 'Custom Fields',
    selector: row => printNumber((row as ISuperficialNode).numberOfCustomFields),
    width: '140px',
    property: 'number_of_custom_fields',
    sortBy: 'number_of_custom_fields',
  },
  {
    name: 'Proposals',
    property: 'has_shift_left_potential',
    selector: row => (row as ISuperficialNode).hasProposals ? <SparklesLeftArrowIcon width="16" height="16" className='text-primary' data-tag="allowRowEvents" /> : '',
    sortBy: 'has_shift_left_potential',
    width: '120px'
  },
  {
    name: 'Is Trivial SQL',
    property: 'is_trivial_sql',
    selector: row => {
      const value = (row as ISuperficialNode).isTrivialSql;
      if (value === true) {
        return 'Yes';
      } else if (value === false) {
        return 'No';
      }
      else {
        return '';
      }
    },
    sortBy: 'is_trivial_sql',
    width: '150px'
  },
  {
    name: 'First seen',
    property: 'first_seen_at',
    selector: row => (row as ISuperficialNode).firstSeen && (new Date((row as ISuperficialNode).firstSeen)).toISOString().split('T')[0],
    width: '150px',
    sortBy: 'first_seen_at'
  },
  {
    name: 'Materialized',
    property: 'materialized',
    selector: row => {
      if ((row as ISuperficialNode).materialized !== null) {
        return (row as ISuperficialNode).materialized ? 'True' : 'False';
      }
    },
    width: '150px',
    sortBy: 'materialized',
  },
  {
    name: 'dbt materialization strategy',
    property: 'dbt_materialization_strategy',
    selector: row => (row as ISuperficialNode).dbtMaterializationStrategy,
    width: '220px',
    sortBy: 'dbt_materialization_strategy',
  },
  {
    name: 'Tableau has extracts',
    property: 'tableau_has_extracts',
    selector: row => {
      if ((row as ISuperficialNode).tableauHasExtracts !== null) {
        return (row as ISuperficialNode).tableauHasExtracts ? 'True' : 'False';
      }
    },
    width: '180px',
    sortBy: 'tableau_has_extracts',
  },
  {
    name: 'Extract last updated',
    property: 'native_last_data_update',
    selector: row => (row as ISuperficialNode).nativeLastDataUpdate && utcToLocal((row as ISuperficialNode).nativeLastDataUpdate || '', dateFormats.dateHoursAndMinutes),
    width: '250px',
    sortBy: 'native_last_data_update',
  },
  {
    name: 'Refresh frequency',
    property: 'tableau_extract_refresh_frequency',
    selector: row => (row as ISuperficialNode).refreshFrequency,
    width: '180px',
    sortBy: 'tableau_extract_refresh_frequency',
  },
  {
    name: 'Tableau workbook',
    property: 'tableau_workbook',
    selector: row => (row as ISuperficialNode).tableauWorkbook,
    width: '200px',
    sortBy: 'tableau_workbook',
  },
  {
    name: 'Tableau project',
    property: 'tableau_project',
    selector: row => (row as ISuperficialNode).tableauProject,
    width: '200px',
    sortBy: 'tableau_project',
  },
  {
    name: 'Tags',
    property: 'tags',
    selector: row => (row as ISuperficialNode).tags?.join(', '),
    sortBy: 'tags',
  },
  {
    name: 'dbt project',
    property: 'dbt_project',
    selector: row => (row as ISuperficialNode).dbtProject,
    width: '150px',
    sortBy: 'dbt_project',
  },
  {
    name: 'Database schema',
    property: 'database_schema',
    selector: row => (row as ISuperficialNode).databaseSchema,
    width: '200px',
    sortBy: 'database_schema',
  },
  {
    name: 'Database',
    property: 'database',
    selector: row => (row as ISuperficialNode).database,
    sortBy: 'database',
    width: '150px',
  },
  {
    name: 'Looker folder',
    property: 'looker_folder',
    selector: row => (row as ISuperficialNode).lookerFolder,
    width: '150px',
    sortBy: 'looker_folder',
  },
  {
    name: 'Looker model',
    property: 'looker_model',
    selector: row => (row as ISuperficialNode).lookerModel,
    width: '150px',
    sortBy: 'looker_model',
  },
  {
    name: 'Looker instance',
    property: 'looker_host',
    selector: row => (row as ISuperficialNode).lookerHost,
    width: '150px',
    sortBy: 'looker_host',
  },
  {
    name: 'Source directory',
    property: 'source_directory',
    selector: row => (row as ISuperficialNode).sourceDirectory,
    width: '150px',
    sortBy: 'source_directory',
  },
  {
    name: 'UTL',
    property: 'uri',
    selector: row => <CellWithTooltipAndCopy text={(row as ISuperficialNode).id} maxChars={100} />,
    width: '700px',
    sortBy: 'uri',
  },
  {
    name: 'Owner',
    property: 'owner',
    selector: row => (row as ISuperficialNode).owner,
    width: '200px',
    sortBy: 'owner',
  },
  {
    name: 'Total views 30 days',
    selector: row => printNumber((row as ISuperficialNode).totalViews30Days),
    width: '210px',
    property: 'last_30d_views',
    sortBy: 'last_30d_views',
  },
  {
    name: 'Total views 7 days',
    selector: row => printNumber((row as ISuperficialNode).totalViews7Days),
    width: '210px',
    property: 'last_7d_views',
    sortBy: 'last_7d_views',
  },
  {
    name: 'Total queries 14 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries14Days),
    width: '210px',
    property: 'total_queries_14d',
    sortBy: 'total_queries_14d',
  },
  {
    name: 'Total queries 30 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries30Days),
    width: '210px',
    property: 'total_queries_30d',
    sortBy: 'total_queries_30d',
  },
  {
    name: 'Total queries 60 days',
    selector: row => printNumber((row as ISuperficialNode).totalQueries60Days),
    width: '210px',
    property: 'total_queries_60d',
    sortBy: 'total_queries_60d'
  },
  {
    name: 'Total impressions 14 days',
    selector: row => printNumber((row as ISuperficialNode).totalImpressions14Days),
    width: '210px',
    property: 'total_impressions_14d',
    sortBy: 'total_impressions_14d',
  },
  {
    name: 'Total impressions 30 days',
    selector: row => printNumber((row as ISuperficialNode).totalImpressions30Days),
    width: '210px',
    property: 'total_impressions_30d',
    sortBy: 'total_impressions_30d',
  },
  {
    name: 'Total impressions 60 days',
    selector: row => printNumber((row as ISuperficialNode).totalImpressions60Days),
    width: '210px',
    property: 'total_impressions_60d',
    sortBy: 'total_impressions_60d',
  },
  {
    name: 'Distinct user impressions 14 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUserImpressions14Days),
    width: '250px',
    property: 'distinct_impressions_users_14d',
    sortBy: 'distinct_impressions_users_14d',
  },
  {
    name: 'Distinct user impressions 30 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUserImpressions30Days),
    width: '250px',
    property: 'distinct_impressions_users_30d',
    sortBy: 'distinct_impressions_users_30d',
  },
  {
    name: 'Distinct user impressions 60 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUserImpressions60Days),
    width: '250px',
    property: 'distinct_impressions_users_60d',
    sortBy: 'distinct_impressions_users_60d',
  },
  {
    name: 'Distinct users 14 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers14Days),
    width: '210px',
    property: 'distinct_users_14d',
    sortBy: 'distinct_users_14d'
  },
  {
    name: 'Distinct users 30 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers30Days),
    width: '210px',
    property: 'distinct_users_30d',
    sortBy: 'distinct_users_30d'
  },
  {
    name: 'Distinct users 60 days',
    selector: row => printNumber((row as ISuperficialNode).distinctUsers60Days),
    width: '210px',
    property: 'distinct_users_60d',
    sortBy: 'distinct_users_60d'
  },
  {
    name: 'Is identity',
    selector: row => {
      const value = (row as ISuperficialNode).isIdentityTransformation;
      if (value === true) {
        return 'Yes';
      } else if (value === false) {
        return 'No';
      }
      else {
        return '';
      }
    },
    width: '150px',
    property: 'is_identity_transformation',
    sortBy: 'is_identity_transformation',
  },
  {
    name: 'PDT trigger',
    selector: row => (row as ISuperficialNode).lookmlViewPersistency,
    width: '150px',
    property: 'lookml_view_persistency',
    sortBy: 'lookml_view_persistency',
  },
  {
    name: 'PDT builds 30d',
    selector: row => printNumber((row as ISuperficialNode).pdtBuildsLast30d),
    width: '150px',
    property: 'pdt_builds_last_30d',
    sortBy: 'pdt_builds_last_30d',
  },
  {
    name: 'Total PDT build time 30d',
    selector: row => {
      const value = (row as ISuperficialNode).pdtTotalBuildTime30d;
      if (value) {
        return formatSecondsToHoursAndMinutes(value);
      }
      else {
        return '';
      }
    },
    width: '200px',
    property: 'pdt_total_build_time_30d',
    sortBy: 'pdt_total_build_time_30d',
  },
  {
    name: 'Derived table type',
    selector: row => (row as ISuperficialNode).derivedType,
    width: '200px',
    property: 'derived_type',
    sortBy: 'derived_type',
  },
  {
    name: 'Source path',
    selector: row => (row as ISuperficialNode).sourcePath,
    width: '150px',
    property: 'source_path',
    sortBy: 'source_path',
  },
  {
    name: 'Source directory',
    selector: row => (row as ISuperficialNode).sourceDirectory,
    width: '150px',
    property: 'source_directory',
    sortBy: 'source_directory',
  },
  {
    name: 'Refinements',
    selector: row => {
      const hasRefinements = (row as ISuperficialNode).hasRefinements;
      if (hasRefinements === null) {
        return '';
      }
      else if (hasRefinements) {
        return 'Includes refinements';
      }
      else {
        return 'Does not include refinements';
      }
    },
    width: '150px',
    property: 'has_refinements',
    sortBy: 'has_refinements',
  },
];

export const subResourcesDiscoverColumns: TableColumnProps[] = [
  {
    name: 'Parent',
    property: 'parent_name',
    selector: row => <div data-tag="allowRowEvents" className="flex gap-1 items-center">{(row as ISuperficialNode).parentName}</div>,
    width: '200px',
    isNotRemovable: true,
  },
  {
    name: 'Is calculated',
    property: 'is_calculated',
    selector: row => {
      if ((row as ISuperficialNode).isCalculated !== null) {
        return (row as ISuperficialNode).isCalculated ? 'True' : 'False';
      }
    },
    width: '150px',
    sortBy: 'is_calculated',
  },
];

const printNumber = (value?: number | null) => typeof value === 'number' ? `${Math.round(value)}` : '';
