import {ChangeEvent} from "react";
import {FormField} from "src/components/form/FormField";
import Input from "src/components/form/Input";
import {
  GenericIntegration,
  GenericIntegrationConfiguration,
  GenericIntegrationType
} from "src/services/integrations/types";
import Checkbox from "src/components/form/Checkbox";
import {FIELDS_BY_TYPE} from "./ConnectedSourcesConsts";
import {ButtonTypes} from "src/components/button/types";
import Button from "src/components/button/Button";


type ConfigurationDetailsBlockType = {
  integrationType: GenericIntegrationType,
  configuration: GenericIntegrationConfiguration,
  integration: GenericIntegration,
  setConfiguration: (configuration: GenericIntegrationConfiguration) => void,
  update: (data?: Partial<GenericIntegration>, fieldsForUpdate?: (keyof GenericIntegrationConfiguration)[] ) => void,
  updateInProgress: boolean,
  setUpdateInProgress: (updateInProgress: boolean) => void,
}

const ConfigurationDetailsBlock = ({
   integrationType,
   configuration,
   integration,
   setConfiguration,
   update,
   updateInProgress,
   setUpdateInProgress
}: ConfigurationDetailsBlockType) => {

  return (
    <>
      <span className="text-lg">Configuration details</span>
      <div className="flex flex-col justify-between mt-4">
        {
          FIELDS_BY_TYPE[integrationType].map((field, index) => {
            const key = `${integrationType}-${field.title}`;
            const value = configuration ? configuration[field.title as keyof GenericIntegrationConfiguration] : '';
            return (
              <FormField
                key={key}
                label={field.component === Input ? field.label : ""}
                className={`flex-col !items-start ${index != 0 ? 'mt-3' : ''}`}
                fieldWrapperClassName='w-full'
                labelClassName='text-secondary mb-1'
                helper={field?.optional ? 'Optional' : ''}
                helperClassName={field?.optional ? 'text-tertiary !text-base' : ''}
              >
                { field.component === Input && (
                  <field.component
                    placeholder={field.placeholder}
                    value={value}
                    type={field?.sensitive ? 'password' : 'text'}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setConfiguration({...configuration, [field.title]: e.target.value})}
                  />
                )}
                { field.component === Checkbox && (
                  <field.component
                      value={Boolean(value)}
                      setValue={(value: boolean) =>
                        setConfiguration({...configuration, [field.title]: value})}
                      label={field.label}
                  />
                )}
              </FormField>
            );
          })
        }
      </div>
      { integration && (
        <div className="flex justify-end mt-6 pt-4 border-t">
          <Button
            className="ml-4 w-24 !text-base"
            type={ButtonTypes.primary}
            text="Save"
            onClick={() => {
              setUpdateInProgress(true);
              const fieldsForUpdate = FIELDS_BY_TYPE[integrationType].map(field =>
                field.title as keyof GenericIntegrationConfiguration);
              update({configuration}, fieldsForUpdate);
            }}
            isDisabled={updateInProgress || (configuration && FIELDS_BY_TYPE[integrationType].every(key =>
              integration.configuration[key.title as keyof GenericIntegrationConfiguration] ===
              configuration[key.title as keyof GenericIntegrationConfiguration]))}
          />
        </div>
      )}
    </>
  );
};

export default ConfigurationDetailsBlock;
