import { useNavigate, useParams } from 'react-router-dom';
import Button from "src/components/button/Button";
import {ButtonTypes} from "src/components/button/types";
import { useDeleteGenericIntegrationMutation } from 'src/services/integrations/integrations';
import {useSelector} from "react-redux";
import {selectActiveAccountId} from "src/infrastructure/state/slices/activeAccountSlice";
import {notify} from "src/components/Toaster";
import {extractErrorMessage} from "src/services/api";
import {useState} from "react";


const DisconnectBlock = () => {
  const navigate = useNavigate();
  const [ deleteIntegration ] = useDeleteGenericIntegrationMutation();
  const [ deleteInProgress, setDeleteInProgress ] = useState(false);
  const accountId = useSelector(selectActiveAccountId);
  const integrationId = parseInt(useParams().id || '');

  const disconnect = async () => {
    try {
      setDeleteInProgress(true);
      await deleteIntegration({accountId, integrationId}).unwrap();
      navigate('/sources');
    } catch (e) {
      notify(`Error deleting integration: ${extractErrorMessage(e).message}`, 'error');
    }
    setDeleteInProgress(false);
  };

  return (
    <>
      <div className="text-lg">Disconnect source</div>
      <span className="text-tertiary">The source will be permanently deleted, including configurations</span>
      <div className="flex justify-end mt-6 pt-4 border-t">
        <Button
          className="ml-4 w-24 !text-base"
          type={ButtonTypes.danger}
          text="Disconnect"
          onClick={disconnect}
          isDisabled={deleteInProgress}
        />
      </div>
    </>
  );
};

export default DisconnectBlock;
