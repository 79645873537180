import {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { useParams } from 'react-router-dom';
import Layout from "src/components/layout/Layout";
import {
  useUpdateGenericIntegrationMutation,
  useGetGenericIntegrationQuery
} from 'src/services/integrations/integrations';
import {notify} from "src/components/Toaster";
import {extractErrorMessage} from "src/services/api";
import {selectActiveAccountId} from "src/infrastructure/state/slices/activeAccountSlice";
import { GenericIntegration, GenericIntegrationConfiguration, ScheduleType } from "src/services/integrations/types";
import SourcePageHeader from "src/features/sources/connectedSources/SourcePageHeader";
import {PageTabs} from "src/components/PageTabs";
import GeneralTab from "./GeneralTab";
import AdvancedTab from "./AdvancedTab";
import TopBar from "./TopBar";


const ConnectSource = () => {
  const accountId = useSelector(selectActiveAccountId);
  const [tab, setTab] = useState<'general' | 'advanced'>('general');
  const [updateIntegration] = useUpdateGenericIntegrationMutation();
  const [name, setName] = useState('');
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [schedule, setSchedule] = useState<ScheduleType|undefined>();
  const [configuration, setConfiguration] = useState<GenericIntegrationConfiguration>();
  const integrationId = useParams().id || '';
  const getIntegration = useGetGenericIntegrationQuery({ accountId, integrationId }, { skip: !integrationId });
  const integration = getIntegration.data as GenericIntegration;

  const update = async (newData?: Partial<GenericIntegration>, fieldsForUpdate?: (keyof GenericIntegrationConfiguration)[]) => {
    try {
      if (fieldsForUpdate && newData?.configuration) {
        const configurationForUpdate = {} as GenericIntegrationConfiguration;
        fieldsForUpdate.forEach((field) => configurationForUpdate[field] = (newData?.configuration as GenericIntegrationConfiguration)[field]);
        newData.configuration = configurationForUpdate;
      }
      await updateIntegration({
        accountId,
        integrationId: integration.id,
        integrationName: name,
        integrationType: integration.integration_type,
        ...(newData || {})
      }).unwrap();
    } catch (e) {
      setFormFields();
      notify(`Error updating integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const generalTab = (
    <GeneralTab
      name={name}
      setName={setName}
      configuration={configuration as GenericIntegrationConfiguration}
      setConfiguration={setConfiguration}
      schedule={schedule}
      setSchedule={setSchedule}
      integration={integration}
      update={update}
      updateInProgress={updateInProgress}
      setUpdateInProgress={setUpdateInProgress}
    />
  );

  const advancedTab = (
    <AdvancedTab
      configuration={configuration as GenericIntegrationConfiguration}
      integration={integration}
      setConfiguration={setConfiguration}
      update={update}
      updateInProgress={updateInProgress}
      setUpdateInProgress={setUpdateInProgress}
    />
  );

  const setFormFields = useCallback(() => {
    if (integration) {
      setName(integration.name);
      setConfiguration(integration.configuration);
      setUpdateInProgress(false);
    }
  }, [integration]);

  useEffect(() => {
    if (integration) {
      setFormFields();
    }
  }, [integration, setFormFields]);

  return (
    <Layout>
      <TopBar integrationName={name} schedule={schedule} configuration={configuration}/>
      {integrationId && (
        <>
          <div className="flex flex-col bg-white border-b">
            <div className="w-[40rem] h-40 flex flex-col m-auto">
              <div className="text-2xl mt-10">Source Settings</div>
              <div className="text-tertiary mt-1 mb-auto">Add and manage connected sources and set up their schedule.</div>
              <PageTabs
                withUnderscore
                tabs={[
                  { name: 'General', isActive: tab === 'general', onClick: () => setTab('general') },
                  { name: 'Advanced', isActive: tab === 'advanced', onClick: () => setTab('advanced') }
                ]}
              />
            </div>
          </div>
          <div>
            { tab === 'general' ? generalTab : advancedTab }
          </div>
        </>
      )}
      {!integrationId && (
        <div className='w-[40rem] m-auto'>
          <SourcePageHeader/>
          { generalTab }
        </div>
      )}
    </Layout>
  );
};

export default ConnectSource;
