import { Materialization } from "../../IChange";
import { MaterilizationIcon } from "../../../../assets/images/icons/DelphiIcons";
import RadioButton from "../../../../components/form/RadioButton";

type ModelMaterializationFormProps = {
    materialization: Materialization;
    setMaterialization: (materialization: Materialization) => void;
    labelClassName?: string;
}

export const ModelMaterializationForm = ({ materialization, setMaterialization, labelClassName = 'w-36' }: ModelMaterializationFormProps) => {
    return (
        <div className="flex gap-2 items-start">
            <div className={`text-secondary text-sm font-medium flex items-center gap-1 ${labelClassName}`}>
                <MaterilizationIcon width="12" height="12" />
                Model materialization
            </div>
            <div className="text-sm flex flex-col gap-1 -ml-1">
                <RadioButton value={materialization === 'view'} setValue={() => setMaterialization('view')} label={'View'} />
                <RadioButton value={materialization === 'table'} setValue={() => setMaterialization('table')} label={'Table'} />
            </div>
        </div>
    );
};