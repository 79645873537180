import { Filter } from './Types';
import { DocumentDuplicateIcon, EyeIcon, LanguageIcon, ServerStackIcon, Squares2X2Icon, TableCellsIcon, TagIcon, CodeBracketIcon, BriefcaseIcon, FolderIcon, ViewfinderCircleIcon, ChartBarIcon, UserIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import { mapBackendNodeTypeToLocalNodeType } from '../../../../../services/nodes/transformers';
import { DBT_TYPES, LOOKER_TYPES, NodeType, SUB_RESOURCE_TYPES, TABLEAU_TYPES, nodeNameMap } from '../../INode';
import { CalculatedFieldIcon, DbtCloudIcon, IdentityTransformationIcon, LookerIcon, LookerInstanceIcon, ModelIcon, PDTBuildsIcon, SourceDirectoryIcon, SparklesLeftArrowIcon, TableauIcon, UsersIcon } from '../../../../../assets/images/icons/DelphiIcons';
import { MetaFilter } from './MetaFilter';
import { NodeIcon } from '../../NodeIcon';
import { BackendNodeType } from '../../../../../services/nodes/types';
import { NameFilter } from './NameFilter';
import { FreeTextFilter } from './filterComponents/FreeTextFilter';
import { BadgeUtlToResourceName } from 'src/features/models/discover/toolbar/filters/BadgeUtlToResourceName';
import { SelectFilter } from 'src/components/Table/TableFilters/SelectFilter';
import { store } from 'src/infrastructure/state/store';
import { getKeyByValue } from 'src/utils/objectUtils';

type FilterValue = string | null;

const FIRST_OBSERVED_METRICS_MILLISECONDS = 1726415774000; // 15-09-2024, to be removed after invalidating old metrics.

export const discoverFilterList: Filter[] = [
  {
    name: 'Type',
    menuIcon: <Squares2X2Icon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><Squares2X2Icon width="16" height="16" className="text-lilac-400" /> Type: {value?.split(',').map(v => nodeNameMap.get(mapBackendNodeTypeToLocalNodeType.get(v as BackendNodeType)!)).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => {
      if (!value) {
        return '';
      }
      const values = value.split(',');
      const metricIndex = values.indexOf('dbt_metric');
      if (metricIndex !== -1) {
        values.splice(metricIndex, 1)[0];
        const metricFilter = `(type='dbt_metric' AND last_observed > ${FIRST_OBSERVED_METRICS_MILLISECONDS})`;
        if (values.length) {
          return `(${metricFilter} OR (type IN ('${values.join("','")}')))`;
        }
        return metricFilter;
      }
      return `(type IN ('${values.join("','")}'))`;
    },
    component: ({ value, onChange, onSave, onClose, filterOptions }) => {
      const showSubResources = store.getState().showSubResourcesInDataModelTable;
      const typeFacet = filterOptions?.type?.filter((type) => {
        const localType = mapBackendNodeTypeToLocalNodeType.get(type as BackendNodeType);
        return showSubResources || !SUB_RESOURCE_TYPES.includes(localType as NodeType);
      }) || [];
      const options = typeFacet.map((type) => {
        const localType = mapBackendNodeTypeToLocalNodeType.get(type as BackendNodeType);
        return {
          label: nodeNameMap.get(localType!) || '',
          value: type,
          icon: <NodeIcon iconSize={16} type={localType as NodeType} />
        };
      }).filter(t => t.label).sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
        return 0;
      }) || [];
      return (
        <SelectFilter
          label="Type"
          options={options}
          isMulti={true}
          value={value}
          onSave={onSave}
          onClose={onClose}
          onChange={onChange}
          search='inMemory'
        />
      );
    }
  },
  {
    name: 'Name',
    menuIcon: <LanguageIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LanguageIcon width="16" height="16" className="text-lilac-400" />Name: {value}</>,
    isDisabled: false,
    component: NameFilter,
    getEql: (value: FilterValue) => multiSelectEql('name', value),
  },
  {
    name: 'Proposals',
    menuIcon: <SparklesLeftArrowIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><SparklesLeftArrowIcon width="16" height="16" className="text-primary" />Proposals: {Object.entries(proposalsOptions).find((entry) => entry[1] === value)?.[0]}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `has_shift_left_potential ${value === 'false' ? 'IS NOT' : 'IS'} TRUE` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Proposals"
        options={Object.entries(proposalsOptions).map(([label, value]) => ({ label, value, icon: <SparklesLeftArrowIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerTile, NodeType.LookerView, NodeType.LookerDerivedView],
  },
  {
    name: 'Is trivial SQL',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-lilac-400" /> Is trivial SQL: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => booleanEql('is_trivial_sql', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="SQL"
        options={Object.entries(booleanOptions).map(([label, value]) => ({ label, value, icon: <TableauIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.TableauCustomQuery],
  },
  {
    name: 'Total views 7D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-lilac-400" /> Total views 7D: {value?.split(',').map(v => logarithmicRangeOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `last_7d_views ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 7D"
        options={logarithmicRangeOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerDashboard],
  },
  {
    name: 'Total views 30D',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-lilac-400" /> Total views 30D: {value?.split(',').map(v => logarithmicRangeOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `last_30d_views ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total views 30D"
        options={logarithmicRangeOptions.map(({ label, value }) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerLook, NodeType.LookerDashboard],
  },
  {
    name: 'Total queries 14D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total queries 14D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_queries_14d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total queries 14D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'Total queries 30D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total queries 30D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    value: null,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_queries_30d ${v}`).join(' OR ')})` : '',
    isDisabled: false,
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total queries 30D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'Total queries 60D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total queries 60D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    value: null,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_queries_60d ${v}`).join(' OR ')})` : '',
    isDisabled: false,
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total queries 60D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.LookerView, NodeType.LookerDerivedView, NodeType.TableauView, NodeType.TableauDashboard],
  },
  {
    name: 'Total impressions 14D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total impressions 14D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_impressions_14d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total impressions 14D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Total impressions 30D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total impressions 30D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_impressions_30d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total impressions 30D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Total impressions 60D',
    menuIcon: <ChartBarIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Total impressions 60D: {value?.split(',').map(v => usageOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `total_impressions_60d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Total impressions 60D"
        options={usageOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct users 14D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct users 14D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_users_14d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct users 14D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct users 30D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct users 30D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_users_30d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct users 30D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct users 60D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct users 60D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_users_60d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct users 60D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct user impressions 14D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct user impressions 14D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_impressions_users_14d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct user impressions 14D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct user impressions 30D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct user impressions 30D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_impressions_users_30d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct user impressions 30D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Distinct user impressions 60D',
    menuIcon: <UsersIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ChartBarIcon width="16" height="16" className="text-lilac-400" /> Distinct user impressions 60D: {value?.split(',').map(v => distinctUsersImpressionsOptions.find(o => v === o.value)?.label).join(',')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `distinct_impressions_users_60d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Distinct user impressions 60D"
        options={distinctUsersImpressionsOptions.map(({ label, value }) => ({ label, value, icon: <ChartBarIcon width="16" height="16" /> }))}
        isMulti={true}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'First seen',
    menuIcon: <EyeIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><EyeIcon width="16" height="16" className="text-lilac-400" /> First seen: {Object.entries(firstSeenOptions).find((entry) => entry[1] === value)?.[0] || ''}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `first_observation ${mapFirstSeenValueToEql(value)}` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="First seen"
        options={Object.entries(firstSeenOptions).map(([label, value]) => ({ label, value, icon: <EyeIcon width="16" height="16" /> }))}
        isMulti={false}
        search='inMemory'
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'dbt materialization strategy',
    menuIcon: <DocumentDuplicateIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><DocumentDuplicateIcon width="16" height="16" className="text-lilac-400" /> dbt materialization Strategy: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('dbt_materialization_strategy', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="dbt materialization strategy"
        options={filterOptions?.dbt_materialization_strategy?.map((dbt_materialization_strategy) => ({ label: dbt_materialization_strategy, value: dbt_materialization_strategy, icon: <DocumentDuplicateIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [NodeType.DataModel],
  },
  {
    name: 'Materialized',
    menuIcon: <DocumentDuplicateIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><DocumentDuplicateIcon width="16" height="16" className="text-lilac-400" /> materialized: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => booleanEql('materialized', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Materialized"
        options={Object.entries(booleanOptions).map(([label, value]) => ({ label, value, icon: <TableauIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'Tags',
    menuIcon: <TagIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TagIcon width="16" height="16" className="text-lilac-400" /> Tags: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('tags', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tags"
        options={filterOptions?.tags?.map((tag) => ({ label: tag, value: tag, icon: <TagIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [...DBT_TYPES, NodeType.TableauEmbeddedDataSource, NodeType.TableauPublishedDataSource, NodeType.TableauCustomQuery],
  },
  {
    name: 'Meta',
    menuIcon: <CodeBracketIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TagIcon width="16" height="16" className="text-lilac-400" /> Meta: {value}</>,
    isDisabled: false,
    getEql: (kv: FilterValue) => {
      if (!kv) return '';
      const keyValues = kv.split(';');
      const filterStrings = keyValues.map((kv) => {
        const [key, values] = kv.split('=');
        return `(meta.${key} IN ('${values.split(',').join("', '")}'))`;
      });
      return filterStrings.join(' AND ');
    },
    component: MetaFilter,
    nodeTypes: DBT_TYPES,
  },
  {
    name: 'dbt project',
    menuIcon: <DbtCloudIcon width="18" height="18" className="mr-1.5" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><DbtCloudIcon width="16" height="16" className="text-primary" /> dbt project: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('dbt_project', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="dbt project"
        options={filterOptions?.dbt_project?.map((dbt_project) => ({ label: dbt_project, value: dbt_project, icon: <DbtCloudIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: DBT_TYPES,
  },
  {
    name: 'Database schema',
    menuIcon: <TableCellsIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableCellsIcon width="16" height="16" className="text-lilac-400" /> Database schema: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('schema', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database schema"
        options={filterOptions?.database_schema?.map((schema) => ({ label: schema, value: schema, icon: <TableCellsIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: DBT_TYPES,

  },
  {
    name: 'Database',
    menuIcon: <ServerStackIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ServerStackIcon width="16" height="16" className="text-lilac-400" /> Database: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('database', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Database"
        options={filterOptions?.database?.map((database) => ({ label: database, value: database, icon: <ServerStackIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: DBT_TYPES,
  },
  {
    name: 'Looker project',
    menuIcon: <BriefcaseIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><BriefcaseIcon width="16" height="16" className="text-lilac-400" /> Looker project: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('looker_project', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker project"
        options={filterOptions?.looker_project?.map((looker_project) => ({ label: looker_project, value: looker_project, icon: <BriefcaseIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES,
  },
  {
    name: 'Looker folder',
    menuIcon: <FolderIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><FolderIcon width="16" height="16" className="text-lilac-400" /> Looker folder: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('looker_folder', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker folder"
        options={filterOptions?.looker_folder?.map((looker_folder) => ({ label: looker_folder, value: looker_folder, icon: <FolderIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES,
  },
  {
    name: 'Looker model',
    menuIcon: <ModelIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ModelIcon width="16" height="16" className="text-primary" /> Looker model: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('looker_model', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker model"
        options={filterOptions?.looker_model?.map((looker_model) => ({ label: looker_model, value: looker_model, icon: <ModelIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES,
  },
  {
    name: 'Looker instance',
    menuIcon: <LookerInstanceIcon width="18" height="18" className="mr-1.5" fill="#475569" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LookerInstanceIcon width="16" height="16" className="text-primary" /> Looker instance: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('looker_host', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Looker instance"
        options={filterOptions?.looker_host?.map((looker_host) => ({ label: looker_host, value: looker_host, icon: <LookerInstanceIcon width="16" height="16" fill="#475569" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES,
  },
  {
    name: 'Derived table type',
    menuIcon: <LookerIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LookerIcon width="16" height="16" className="text-lilac-400" /> Derived table type: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('derived_type', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Derived table type"
        options={filterOptions?.derived_type?.map((derived_type) => ({ label: derived_type, value: derived_type, icon: <LookerIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES
  },
  {
    name: 'Refinements',
    menuIcon: <LookerIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><LookerIcon width="16" height="16" /> {value?.split(',').map(v => getKeyByValue(refinementsOptions, v)).join(', ')}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => booleanEql('has_refinements', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Refinements"
        options={Object.entries(refinementsOptions).map(([label, value]) => ({ label, value, icon: <LookerIcon width="16" height="16" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES
  },
  {
    name: 'Source directory',
    menuIcon: <SourceDirectoryIcon width="20" height="20" className="mr-1" fill="#64748B" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><SourceDirectoryIcon width="16" height="16" className="text-primary" /> Source directory: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('source_directory', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Source directory"
        options={filterOptions?.source_directory?.map((source_directory) => ({ label: source_directory, value: source_directory, icon: <SourceDirectoryIcon width="16" height="16" fill="#64748B" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [...DBT_TYPES, NodeType.LookerView, NodeType.LookerExplore]
  },
  {
    name: 'URI',
    menuIcon: <ViewfinderCircleIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ViewfinderCircleIcon width="16" height="16" className="text-lilac-400" /> URI: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `uri = '${value}'` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <FreeTextFilter
        value={value}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
        placeholder={'URI'}
        label={'URI'}
      />
  },
  {
    name: 'Parent container URI',
    menuIcon: <ViewfinderCircleIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><ViewfinderCircleIcon width="16" height="16" className="text-lilac-400" /> Parent container: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `parent_container = '${value}'` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <FreeTextFilter
        value={value}
        onChange={onChange}
        onClose={onClose}
        onSave={onSave}
        placeholder={'URI'}
        label={'Parent container'}
      />
  },
  {
    name: 'Tableau project',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-lilac-400" /> Tableau project: {value}</> || '',
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('tableau_project', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau project"
        options={filterOptions?.tableau_project?.map((tableau_project) => ({ label: tableau_project, value: tableau_project, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
        search='inMemory'
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Tableau has extracts',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    isDisabled: false,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-primary" />Tableau has extracts: {value}</>,
    getEql: (value: FilterValue) => booleanEql('tableau_has_extracts', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Tableau has extracts"
        options={Object.entries(booleanOptions).map(([label, value]) => ({ label, value, icon: <TableauIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Refresh frequency',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-lilac-400" /> Refresh frequency: {value}</> || '',
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('tableau_extract_refresh_frequency', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau workbook"
        options={filterOptions?.tableau_extract_refresh_frequency?.map((tableau_extract_refresh_frequency) => ({ label: tableau_extract_refresh_frequency, value: tableau_extract_refresh_frequency, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
        search='inMemory'
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Tableau workbook',
    menuIcon: <TableauIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><TableauIcon width="16" height="16" className="text-lilac-400" /> Tableau workbook: {value}</> || '',
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('tableau_workbook', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Tableau workbook"
        options={filterOptions?.tableau_workbook?.map((tableau_workbook) => ({ label: tableau_workbook, value: tableau_workbook, icon: <TableauIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
        search='inMemory'
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Owner',
    menuIcon: <UserIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><UserIcon width="16" height="16" className="text-lilac-400" /> Owner: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('owner', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="Owner"
        options={filterOptions?.owner?.map((owner) => ({ label: owner, value: owner, icon: <UserIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
        search='inMemory'
      />,
    nodeTypes: TABLEAU_TYPES,
  },
  {
    name: 'Upstream dependencies',
    menuIcon: <ChevronDoubleLeftIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <BadgeUtlToResourceName label='Upstream dependencies' value={value} icon={<ChevronDoubleLeftIcon width="16" height="16" className="text-lilac-400" />} />,
    getEql: (value: FilterValue) => value ? `(has downstream(uri='${value}'))` : '',
    showInMenu: false
  },
  {
    name: 'Downstream dependents',
    menuIcon: <ChevronDoubleRightIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <BadgeUtlToResourceName label='Downstream dependents' value={value} icon={<ChevronDoubleRightIcon width="16" height="16" className="text-lilac-400" />} />,
    getEql: (value: FilterValue) => value ? `(has upstream(uri='${value}'))` : '',
    showInMenu: false
  },
  {
    name: 'Is identity',
    menuIcon: <IdentityTransformationIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><IdentityTransformationIcon width="16" height="16" className="text-lilac-400" /> Is identity: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => booleanEql('is_identity_transformation', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Is identity"
        options={Object.entries(booleanOptions).map(([label, value]) => ({ label, value, icon: <IdentityTransformationIcon width="16" height="16" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
  },
  {
    name: 'PDT trigger',
    menuIcon: <PDTBuildsIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><PDTBuildsIcon width="16" height="16" className="text-lilac-400" /> PDT trigger: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => multiSelectEql('lookml_view_persistency', value),
    component: ({ value, onChange, onSave, onClose, filterOptions }) =>
      <SelectFilter
        label="PDT trigger"
        options={filterOptions?.lookml_view_persistency?.map((lookml_view_persistency) => ({ label: lookml_view_persistency, value: lookml_view_persistency, icon: <PDTBuildsIcon width="16" height="16" /> })) || []}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES
  },
  {
    name: 'PDT builds 30d',
    menuIcon: <PDTBuildsIcon width="20" height="20" className="mr-1" />,
    value: null,
    badge: ({ value }: { value: FilterValue }) => <><PDTBuildsIcon width="16" height="16" className="text-lilac-400" /> PDT builds 30d: {value}</>,
    isDisabled: false,
    getEql: (value: FilterValue) => value ? `(${value.split(',').map(v => `pdt_builds_last_30d ${v}`).join(' OR ')})` : '',
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="PDT builds 30d"
        options={logarithmicRangeOptions.map(({ label, value }) => ({ label, value, icon: <PDTBuildsIcon width="16" height="16" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: LOOKER_TYPES
  },

];

export const subresourceDiscoverFilters: Filter[] = [
  {
    name: 'Is calculated',
    menuIcon: <CalculatedFieldIcon width="20" height="20" className="mr-1" />,
    value: null,
    isDisabled: false,
    badge: ({ value }: { value: FilterValue }) => <><CalculatedFieldIcon width="16" height="16" className="text-primary" />Is calculated: {value?.split(',').map(v => Object.entries(booleanOptions).find((entry) => entry[1] === v)?.[0]).join(', ')}</>,
    getEql: (value: FilterValue) => booleanEql('is_calculated', value),
    component: ({ value, onChange, onSave, onClose }) =>
      <SelectFilter
        label="Is calculated"
        options={Object.entries(booleanOptions).map(([label, value]) => ({ label, value, icon: <CalculatedFieldIcon width="16" height="16" fill="#64748B" /> }))}
        isMulti={true}
        value={value}
        onSave={onSave}
        onClose={onClose}
        onChange={onChange}
      />,
    nodeTypes: [...TABLEAU_TYPES, ...LOOKER_TYPES],
  },
];

const firstSeenOptions = {
  'Last 7 days': `last_7_days`,
  'Last 30 days': `last_30_days`,
  'Last 90 days': `last_90_days`,
};

const mapFirstSeenValueToEql = (value: string) => {
  const now = Date.now();
  const nowIso = new Date(now).toISOString();
  switch (value) {
    case 'last_7_days':
      return `BETWEEN '${new Date(now - 7 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    case 'last_30_days':
      return `BETWEEN '${new Date(now - 30 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    case 'last_90_days':
      return `BETWEEN '${new Date(now - 90 * 24 * 60 * 60 * 1000).toISOString()}' AND '${nowIso}'`;
    default:
      return '';
  }
};

const logarithmicRangeOptions = [
  { label: '1-10', value: 'BETWEEN 1 AND 10' },
  { label: '10-100', value: 'BETWEEN 10 AND 100' },
  { label: '100-1,000', value: 'BETWEEN 100 AND 1000' },
  { label: '1,000-10,000', value: 'BETWEEN 1000 AND 10000' },
  { label: '10,000+', value: '>= 10000' },
];

const proposalsOptions = {
  'Has a proposal': 'true',
  'Doesn\'t have a proposal': 'false',
};

const booleanOptions = {
  'True': 'true',
  'False': 'false',
};

const refinementsOptions = {
  'Includes refinements': 'true',
  'Does not include refinements': 'false',
};

const usageOptions = [
  { label: '0', value: '= 0' },
  { label: '1-100', value: 'BETWEEN 1 AND 100' },
  { label: '101-500', value: 'BETWEEN 101 AND 500' },
  { label: '501-1,000', value: 'BETWEEN 501 AND 1000' },
  { label: '1,001-5,000', value: 'BETWEEN 1001 AND 5000' },
  { label: '5,001-10,000', value: 'BETWEEN 5001 AND 10000' },
  { label: '10,001+', value: ' > 10000' },
];

const distinctUsersImpressionsOptions = [
  { label: '0', value: '= 0' },
  { label: '1-5', value: 'BETWEEN 1 AND 5' },
  { label: '6-10', value: 'BETWEEN 6 AND 10' },
  { label: '11-20', value: 'BETWEEN 11 AND 20' },
  { label: '21-50', value: 'BETWEEN 21 AND 50' },
  { label: '51-100', value: 'BETWEEN 51 AND 100' },
  { label: '100+', value: ' > 100' },
];

const booleanEql = (property: string, value: FilterValue) => value ? `(${value.split(',').map((v) => `(${property} IS ${v})`).join(' OR ')})` : '';
const multiSelectEql = (property: string, value: FilterValue) => value ? `(${property} IN ('${value.split(',').join("','")}'))` : '';