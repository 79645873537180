import { getSwimlaneForNodeType } from 'src/services/nodes/transformers';
import { ISuperficialNode, distanceBetweenNodes, nodeWidth, swimlaneOrder } from '../../../INode';

type DiscoverViewSwimlanesProps = {
    nodes: ISuperficialNode[];
    nodesPositions: Map<string, { x: number; y: number }>;
}

export const DiscoverViewSwimlanes = ({ nodes, nodesPositions}: DiscoverViewSwimlanesProps) => {
    const swimlanesWithNodes = swimlaneOrder.filter((swimlane) => nodes.some((node) => getSwimlaneForNodeType(node.type) === swimlane));
    const nodesYaxisValues = nodes.map((node) => nodesPositions.get(node.id)!.y);
    const yMax = Math.max(...nodesYaxisValues);
    const height = yMax + (distanceBetweenNodes * 2);
    return (
        swimlanesWithNodes.map((swimlane, index) => {
            const nodesXaxisValues = nodes.filter((node) => getSwimlaneForNodeType(node.type) === swimlane).map((node) => nodesPositions.get(node.id)!.x);
            const xMax = Math.max(...nodesXaxisValues);
            const xMin = Math.min(...nodesXaxisValues);
            const left = index === 0 ? 0 : xMin - distanceBetweenNodes / 2;
            const width = xMax - left + nodeWidth + distanceBetweenNodes / 2;
            const background = index % 2 === 0 ? 'bg-white' : 'bg-slate-50';
            const swimlaneStyle = {
                width,
                left,
                minHeight: height
            };
            return (
                <div key={index} className={`${background} absolute bg-black h-full`} style={swimlaneStyle} id={`swimlane-${swimlane}`}>
                    <div className={`w-fit hover:bg-slate-100 uppercase p-1 mt-2 ml-6 bg-surface-light cursor-pointer text-tertiary rounded-sm`}>
                        {swimlane}
                    </div>
                </div>
            );
        })
    );
};