import { OperationStatus } from '../operations/Operation';
import BuildLifecycleState from './BuildLifecycleState';

interface IBuild {
  id: number;
  lifecycleState: BuildLifecycleState;
  buildType: string;
  buildTime: string | null;
  revisionSha: string;
  errorMessage: string;
  branchNames: string[];
  uploadStartTime: string;
  buildTarget: string;
  modelsCount: number;
  sourcesCount: number;
  operationId: number | null;
  dataSyncRuns: {
    status: OperationStatus;
    operationId: number;
    name: string;
  }[]
}

export enum DataSyncStatus {
  NONE = 'None',
  SUCCESS = 'Success',
  PARTIAL = 'Partial',
  FAILED = 'Failed',
  PENDING = 'Pending'
}

export default IBuild;
