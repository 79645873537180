import {FunctionComponent, SVGProps} from "react";
import {DbtCloudIcon, LookerIcon, SnowflakeIcon, TableauIcon} from "src/assets/images/icons/DelphiIcons";


type SourceIconsType = {
  [key: string]: FunctionComponent<SVGProps<SVGSVGElement>>;
}
export const SOURCE_ICONS: SourceIconsType = {
  looker: LookerIcon,
  tableau: TableauIcon,
  dbt_core: DbtCloudIcon,
  snowflake: SnowflakeIcon
};