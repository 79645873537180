import { CONTAINER_TYPES, DASHBOARD_TYPES, IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneContentsTabSelectorProps = {
    resource: IExpandedNode;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneContentsTabSelector = ({ resource, setActiveTab, activeTab }: ResourceSidepaneContentsTabSelectorProps) => {
    const isContainer = CONTAINER_TYPES.includes(resource.type);
    const isDashboard = DASHBOARD_TYPES.includes(resource.type);
    if (!isContainer && !isDashboard) return null;
    return (
        <ResourceSidepaneTab tab={ResourceTab.Contents} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};