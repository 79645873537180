import { useEffect } from 'react';
import { MainNavigation } from './MainNavigation';
import { Toaster } from 'react-hot-toast';
import { useAppSelector } from '../../infrastructure/state/hooks';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import AccountSwitch from '../../features/account/AccountSwitch';
import EulaModal from '../../features/eula/EulaModal';
import { useIntercom } from 'react-use-intercom';
import { useGetUserQuery } from '../../services/users';
import isMenuCollpasedSlice, { selectIsMenuCollpased } from '../../infrastructure/state/slices/isMenuCollpasedSlice';
import { useDispatch } from 'react-redux';
import { SidebarProjectsList } from '../../features/projects/SidebarProjectsList';

interface LayoutProps {
  children: React.ReactNode;
  withMenu?: boolean;
  withEula?: boolean;
}

const Layout = ({
  children,
  withMenu = true,
  withEula = true
}: LayoutProps) => {
  const getUser = useGetUserQuery();
  const { boot } = useIntercom();
  const isMenuCollapsed = useAppSelector(selectIsMenuCollpased);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getUser.data && import.meta.env.VITE_INTERCOM_TOKEN) {
      boot({ userId: getUser.data.user_id.toString(), email: getUser.data.email });
    }
  }, [getUser.data, boot]);

  const Menu = () => (
    <div className="bg-white top-0 sticky border-r border-slate-200 self-start min-h-screen" id="menu-layout">
      <div className="flex justify-between h-14 items-center px-2 py-4">
        <AccountSwitch />
        <div>
          <ChevronLeftIcon
            className="cursor-pointer text-slate-400"
            width="20"
            height="20"
            onClick={() => dispatch(isMenuCollpasedSlice.actions.setIsMenuCollapsed(!isMenuCollapsed))}
          />
        </div>
      </div>
      <MainNavigation />
      <SidebarProjectsList />
    </div>
  );

  const CollapsedMenu = () => (
    <div className="bg-white top-0 sticky border-r border-slate-200 self-start min-h-screen" id="menu-layout">
      <div className="w-fit ml-auto px-2 py-4">
        <ChevronRightIcon
          className="cursor-pointer text-slate-400"
          width="20"
          height="20"
          onClick={() => dispatch(isMenuCollpasedSlice.actions.setIsMenuCollapsed(!isMenuCollapsed))}
        />
      </div>
    </div>
  );
  return (
    <div className={`flex min-h-screen text-base`}>
      {withMenu && isMenuCollapsed && <CollapsedMenu />}
      {withMenu && !isMenuCollapsed && <Menu />}
      <div className="w-full bg-surface-light">{children}</div>
      <Toaster />
      {withEula && <EulaModal />}
    </div>
  );
};

export default Layout;
