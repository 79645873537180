import { DataSyncStatus } from "./IBuild";

const BuildSyncStatusLabel = ({ status }: { status: DataSyncStatus }) => {
    const statusLabelMap = {
        [DataSyncStatus.FAILED]: {
            label: 'Failed',
            textColor: 'text-red-600',
        },
        [DataSyncStatus.PENDING]: {
            label: 'Pending',
            textColor: 'text-slate-400',
        },
        [DataSyncStatus.PARTIAL]: {
            label: 'Partial',
            textColor: 'text-slate-400',
        },
        [DataSyncStatus.SUCCESS]: {
            label: 'Success',
            textColor: 'text-green-600',
        },
        [DataSyncStatus.NONE]: {
            label: '',
            textColor: '',
        },
    };

    return (
      <span
        className={`font-medium ${statusLabelMap[status].textColor}`}>
        {statusLabelMap[status].label}
      </span>
    );
  };

  export default BuildSyncStatusLabel;