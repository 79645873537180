import { IChange, IChangePage, Pat, SuperficialPat } from "../../features/evolution/IChange";
import { dateFilterToTimeRange } from "../../features/evolution/changesPage/filters/dateFilter";
import { Operation } from "../../features/operations/Operation";
import api from "../api";
import { statusToState, transformChangeResponse, transformChangeToRequest, transformCreateChangeToRequest, transformChangePageResponse, transformBackedPatToLocalPat, transformPatResponse, mapLocalChangeTypeToBackendChangeType } from "./transformers";
import { BackedPat, BackendChangeType, GetChangesPageResponse, GetChangesRequest } from "./types";

export const changesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getChanges: build.query<{ items: IChangePage[], total: number }, GetChangesRequest>({
      query: ({ projectId, sortBy = 'newest', statuses, search = '', page = 1, pageSize = 100, owner = '', createdAt = null, types }) => {
        const { start: created_after, end: created_before } = dateFilterToTimeRange(createdAt);
        return {
          url: `projects/${projectId}/changes`,
          params: {
            sort: sortBy,
            state: statuses.map((status) => statusToState[status]).join(','),
            search,
            page,
            page_size: pageSize,
            created_by_email: owner,
            created_after,
            created_before,
            types: types ? types.map(t => mapLocalChangeTypeToBackendChangeType.get(t) as BackendChangeType).join(',') : undefined
          }
        };
      },
      providesTags: ['Changes'],
      transformResponse: (response: { items: GetChangesPageResponse[], total: number }) => ({
        items: response.items.map(transformChangePageResponse),
        total: response.total
      })
    }),
    proposeChangeToDataModel: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/propose`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    getChange: build.query<IChange, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    getChangeInAccountContext: build.query<IChange, { accountId: number; changeId: number }>({
      query(data) {
        return {
          url: `accounts/${data.accountId}/changes/${data.changeId}`
        };
      },
      providesTags: ['Changes'],
      transformResponse: transformChangeResponse,
      keepUnusedDataFor: 0
    }),
    updateChange: build.mutation<void, { projectId: number; changeId: number; change: IChange }>({
      query: ({ projectId, changeId, change }) => {
        return {
          url: `projects/${projectId}/changes/${changeId}`,
          method: 'PATCH',
          body: transformChangeToRequest(change)
        };
      },
      invalidatesTags: ['Changes']
    }),
    createPullRequest: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/create_pull_request`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    rejectChange: build.mutation<void, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/reject`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Changes']
    }),
    generateChangeDiff: build.mutation<Operation, { projectId: number; changeId: number }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes/${data.changeId}/generate_diff`,
          method: 'POST'
        };
      }
    }),
    createChange: build.mutation<IChange, { projectId: number; change: Pat }>({
      query(data) {
        return {
          url: `projects/${data.projectId}/changes`,
          method: 'POST',
          body: transformCreateChangeToRequest(data.change)
        };
      },
      invalidatesTags: ['Changes'],
      transformResponse: transformChangeResponse
    }),
    getPat: build.query<Pat | null, { accountId: number; uri: string }>({
      query(data) {
        const properties = ['shift_left_potential', 'shift_left_type'];
        return {
          url: `accounts/${data.accountId}/data_model/resources/${data.uri}?properties=${properties.join(',')}`
        };
      },
      transformResponse: transformPatResponse
    }),
    getPats: build.query<{ items: SuperficialPat[], total: number }, { accountId: number, page: number, pageSize: number, eql?: string }>({
      query: ({ accountId, page, pageSize, eql = '' }) => {
        const queryParams = {
          page: page.toString(),
          page_size: pageSize.toString(),
          properties: ['shift_left_date', 'shift_left_owner', 'shift_left_project', 'shift_left_source', 'shift_left_title', 'shift_left_type','total_queries_14d', 'total_queries_30d', 'total_queries_60d'].join(','),
          eql: encodeURIComponent(eql),
          sorting: `observed_at:desc`,
          include_count: 'true'
        };
        const queryString = new URLSearchParams(queryParams).toString();
        return `accounts/${accountId}/data_model/search?${queryString}`;
      },
      transformResponse: (response: { resources: BackedPat[], count: number }) => {
        return {
          items: response.resources.map(transformBackedPatToLocalPat).filter(n => n !== null),
          total: response.count
        };
      }
    }),
  })
});

export const {
  useGetChangesQuery,
  useProposeChangeToDataModelMutation,
  useGetChangeQuery,
  useUpdateChangeMutation,
  useCreatePullRequestMutation,
  useRejectChangeMutation,
  useGenerateChangeDiffMutation,
  useGetChangeInAccountContextQuery,
  useCreateChangeMutation,
  useLazyGetPatQuery,
  useGetPatQuery,
  useGetPatsQuery
} = changesApi;
