import {ChangeEvent} from "react";
import {FormField} from "src/components/form/FormField";
import Input from "src/components/form/Input";
import {
  GenericIntegration,
  GenericIntegrationConfiguration,
  GenericIntegrationType
} from "src/services/integrations/types";
import Checkbox from "src/components/form/Checkbox";
import { ADVANCED_FIELDS_BY_TYPE } from "./ConnectedSourcesConsts";
import {ButtonTypes} from "src/components/button/types";
import Button from "src/components/button/Button";
import {useParams} from "react-router-dom";
import {useGetGenericIntegrationQuery} from "src/services/integrations/integrations";
import {useSelector} from "react-redux";
import {selectActiveAccountId} from "src/infrastructure/state/slices/activeAccountSlice";


type AdvancedBlockType = {
  configuration: GenericIntegrationConfiguration,
  setConfiguration: (configuration: GenericIntegrationConfiguration) => void,
  update: (data?: Partial<GenericIntegration>, fieldsForUpdate?: (keyof GenericIntegrationConfiguration)[] ) => void,
  updateInProgress: boolean,
  setUpdateInProgress: (updateInProgress: boolean) => void,
}

const AdvancedBlock = ({
   configuration,
   setConfiguration,
   update,
   updateInProgress,
   setUpdateInProgress
}: AdvancedBlockType) => {
  const accountId = useSelector(selectActiveAccountId);
  const integrationType = useParams().type as GenericIntegrationType;
  const integrationId = useParams().id || '';
  const getIntegration = useGetGenericIntegrationQuery({ accountId, integrationId });
  const integration = getIntegration.data as GenericIntegration;

  return (
    <>
      <span className="text-lg">Advanced</span>
      <div className="flex flex-col justify-between mt-4">
        {
          ADVANCED_FIELDS_BY_TYPE[integrationType].map((field, index) => {
            const key = `${integrationType}-${field.title}`;
            const value = configuration ? configuration[field.title as keyof GenericIntegrationConfiguration] : '';
            return (
              <FormField
                key={key}
                label={field.component === Input ? field.label : ""}
                className={`flex-col !items-start ${index != 0 ? 'mt-3' : ''}`}
                fieldWrapperClassName='w-full'
                labelClassName='text-secondary mb-1'
                helper={field?.optional ? 'Optional' : ''}
                helperClassName={field?.optional ? 'text-tertiary !text-base' : ''}
              >
                { field.component === Input && (
                  <field.component
                    placeholder={field.placeholder}
                    value={value || ''}
                    type={field?.sensitive ? 'password' : 'text'}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setConfiguration({...configuration, [field.title]: e.target.value})}
                  />
                )}
                { field.component === Checkbox && (
                  <field.component
                      value={Boolean(value)}
                      setValue={(value: boolean) =>
                        setConfiguration({...configuration, [field.title]: value})}
                      label={field.label}
                  />
                )}
              </FormField>
            );
          })
        }
      </div>
      <div className="flex justify-end mt-6 pt-4 border-t">
        <Button
          className="ml-4 w-24 !text-base"
          type={ButtonTypes.primary}
          text="Save"
          onClick={() => {
            setUpdateInProgress(true);
            const fieldsForUpdate = ADVANCED_FIELDS_BY_TYPE[integrationType].map(field =>
              field.title as keyof GenericIntegrationConfiguration);
            update({configuration}, fieldsForUpdate);
          }}
          isDisabled={updateInProgress || (configuration && ADVANCED_FIELDS_BY_TYPE[integrationType].every(key =>
            integration.configuration[key.title as keyof GenericIntegrationConfiguration] ===
            configuration[key.title as keyof GenericIntegrationConfiguration]))}
        />
      </div>
    </>
  );
};

export default AdvancedBlock;
