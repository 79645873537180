import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import { ChangeEvent } from "react";
import {useParams} from "react-router-dom";
import {GenericIntegration, GenericIntegrationType} from "src/services/integrations/types";
import Button from "src/components/button/Button";
import {ButtonTypes} from "src/components/button/types";


type GeneralBlockType = {
  name: string,
  setName: (name: string) => void,
  integration: GenericIntegration,
  update: (data?: GenericIntegration) => void,
  updateInProgress: boolean,
  setUpdateInProgress: (updateInProgress: boolean) => void,
}

const GeneralBlock = ({name, setName, integration, update, updateInProgress, setUpdateInProgress}: GeneralBlockType) => {
  const integrationType = useParams().type as GenericIntegrationType;

  return (
    <>
      <span className="text-lg">General</span>
      <div className="mt-4">
        <FormField
          label="Name"
          className="flex-col !items-start"
          fieldWrapperClassName='w-full'
          labelClassName='text-secondary mb-1'
        >
          <Input
            placeholder={`e.g my ${integrationType} integration`}
            value={name}
            onInputChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          />
        </FormField>
      </div>
      { integration && (
        <div className="flex justify-end mt-6 pt-4 border-t">
          <Button
            className="ml-4 w-24 !text-base"
            type={ButtonTypes.primary}
            text="Save"
            onClick={() => {
              setUpdateInProgress(true);
              update();
            }}
            isDisabled={updateInProgress || name === integration.name}
          />
        </div>
      )}
    </>
  );
};

export default GeneralBlock;
