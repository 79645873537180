import { DASHBOARD_TYPES, IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneSchemaTabSelectorProps = {
    resource: IExpandedNode;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneSchemaTabSelector = ({ resource, setActiveTab, activeTab }: ResourceSidepaneSchemaTabSelectorProps) => {
    const isDashboard = DASHBOARD_TYPES.includes(resource.type);
    if (resource.subnodes.length === 0 || isDashboard) return null;
    return (
        <ResourceSidepaneTab tab={ResourceTab.Schema} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};