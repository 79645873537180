import { useEffect, useMemo } from "react";
import PageLoader from "../../../components/loaders/PageLoader";
import { useNavigate } from 'react-router-dom';
import { getSubnodeUrl } from "./getNodeUrl";
import { notify } from "../../../components/Toaster";
import { Toaster } from "react-hot-toast";
import { store } from "src/infrastructure/state/store";
import { nodesApi } from "src/services/nodes/nodes";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";

export const NodeLookup = () => {
    const params = useMemo(() => new URLSearchParams(window.location.search), []);
    const navigate = useNavigate();
    const accountId = useSelector(selectActiveAccountId);

    useEffect(() => {
        const eql = `unique_id = '${params.get('unique_id')}' AND type = '${params.get('type')}' AND dbt_project = '${params.get('dbt_project')}'`;
        store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({ accountId, eql, page: 1, pageSize: 1, additionalProperties: ['subnodes'] })).then(res => {
            const node = res.data?.items[0];
            if (node) {
                const column = node.subnodes.find(c => c.name === params.get('column'));
                navigate(getSubnodeUrl(node.id, column?.type, column?.name));
            } else {
                notify('Node not found', 'error');
            }
        });
    }, [navigate, params, accountId]);
    return <>
        <PageLoader />
        <Toaster />
    </>;
};