import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from '@descope/react-sdk';
import App from './App.tsx';
import './assets/css/index.css';
import './assets/css/fonts.css';
import { Provider } from 'react-redux';
import { store } from './infrastructure/state/store.ts';
import config from './config.ts';
import * as sentry from './infrastructure/sentry';
import { IntercomProvider } from 'react-use-intercom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

sentry.init();

(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID });

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_TOKEN}>
        <Provider store={store}>
          <AuthProvider projectId={config.descopeProjectId}>
            <LDProvider>
              <App />
            </LDProvider>
          </AuthProvider>
        </Provider>
      </IntercomProvider>
    </React.StrictMode>
  );
})();