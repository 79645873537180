import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneUsageTabSelectorProps = {
    resource: IExpandedNode;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneUsageTabSelector = ({ resource, setActiveTab, activeTab }: ResourceSidepaneUsageTabSelectorProps) => {
    if (!resource.usage && !resource.impressions && !resource.last7DaysViews && !resource.last30DaysViews) return null;
    return (
        <ResourceSidepaneTab tab={ResourceTab.Usage} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};