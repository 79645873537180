import { useParams } from 'react-router-dom';
import { LookerIcon, TableauIcon, SnowflakeIcon, DbtCloudIcon } from "src/assets/images/icons/DelphiIcons";
import { GenericIntegrationType } from "src/services/integrations/types";


const SourcePageHeader = ( ) => {
  const integrationType = useParams().type as GenericIntegrationType;
  const headerAttrs = {
    looker: {
      icon: LookerIcon,
      title: "Looker",
      description: "Explore Looker Views, Explores, Models, Dashboards and Looks in Euno"
    },
    tableau: {
      icon: TableauIcon,
      title: "Tableau",
      description: "Explore your Tableau assets in Euno, from data sources to dashboards"
    },
    snowflake: {
      icon: SnowflakeIcon,
      title: "Snowflake",
      description: "Explore your snowflake views, and snowflake usage patterns in Euno"
    },
    dbt_core: {
      icon: DbtCloudIcon,
      title: "dbt core",
      description: "Gain insights from your dbt sources, models and metrics from any dbt orchestrator"
    }
  };
  const Icon = headerAttrs[integrationType].icon;
  return (
    <div className="flex flex-row mt-8">
      <Icon width="48" height="48" className="mr-4" fill="#FF694A"/>
      <div className="flex flex-col">
        <div className="text-xl">{headerAttrs[integrationType].title}</div>
        <div className="text-tertiary mt-1">
          {headerAttrs[integrationType].description}
        </div>
      </div>
    </div>
  );
};

export default SourcePageHeader;
