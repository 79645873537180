import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Toggle} from "src/components/Toggle";
import {TimeSelector} from "src/components/TimeSelector";
import {WeekDays} from "src/components/WeekDays";
import RadioButton from "src/components/form/RadioButton";
import Select, {Option} from "src/components/form/Select";
import {GenericIntegration, GenericIntegrationConfiguration, ScheduleType} from "src/services/integrations/types";
import Button from "src/components/button/Button";
import {ButtonTypes} from "src/components/button/types";


type ScheduleBlockType = {
  schedule: ScheduleType|undefined,
  setSchedule: (schedule: ScheduleType|undefined) => void,
  integration: GenericIntegration,
  update: (data?:Partial<GenericIntegration>) => void,
  updateInProgress: boolean,
  setUpdateInProgress: Dispatch<SetStateAction<boolean>>
}

const ScheduleBlock = ({schedule, setSchedule, integration, update, updateInProgress, setUpdateInProgress}: ScheduleBlockType) => {
  const [blockOpened, setBlockOpened] = useState(false);
  const [repeatOn, setRepeatOn] = useState(new Set(['Thu']));
  const [repeatTime, setRepeatTime] = useState('10:00:00');
  const [frequency, setFrequency] = useState('weekly');
  const [repeatPeriod, setRepeatPeriod] = useState('2 hours');
  const repeatOptions = [1, 2, 4, 8, 12].map(repeat => `${repeat} hours`);

  const toggleWeekDay = (day:string) => {
    if (repeatOn.has(day)) {
      repeatOn.delete(day);
    } else {
      repeatOn.add(day);
    }
    setRepeatOn(new Set(repeatOn));
  };

  useEffect(() => {
    if (integration?.schedule) {
      setBlockOpened(true);
      if (integration.schedule.repeat_period) {
        setFrequency('daily');
        setRepeatPeriod(`${integration.schedule.repeat_period} hours`);
      } else {
        setRepeatOn(new Set(integration.schedule.repeat_on));
        if (integration.schedule.repeat_time) {
          setRepeatTime(integration.schedule.repeat_time);
        }
      }
    }
  }, [integration, setSchedule]);

  useEffect(() => {
    if (!blockOpened) {
      setSchedule(undefined);
    } else {
      const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (frequency === 'weekly') {
        setSchedule({time_zone, repeat_on: Array.from(repeatOn), repeat_time: repeatTime});
      } else {
        setSchedule({time_zone, repeat_period: parseInt(repeatPeriod.split(" ")[0])});
      }
    }
  }, [blockOpened, repeatOn, repeatTime, frequency, repeatPeriod, setSchedule]);

  const updateDisabled = updateInProgress || (
    schedule &&
    integration?.schedule !== null &&
    Object.keys(schedule).length === Object.keys(integration?.schedule || []).length &&
    Object.keys(schedule).every(key =>
      JSON.stringify((integration?.schedule || [])[key as keyof GenericIntegrationConfiguration]) ===
      JSON.stringify(schedule[key as keyof GenericIntegrationConfiguration])));

  return (
    <div className='relative'>
      <span className="text-lg">Schedule</span>
      <Toggle
        onChange={(blockOpened) => {
          if (blockOpened) {
            update({schedule: undefined});
          }
          setBlockOpened(!blockOpened);
        }}
        className={`absolute top-0 right-0 ${blockOpened ? 'bg-toggle-on' : 'bg-toggle-off'}`}
      />
      { blockOpened && (
        <div className='mt-6'>
          <RadioButton
            value={frequency === 'weekly'}
            setValue={() => setFrequency('weekly')}
            label={'Weekly'}
          />
          {frequency === 'weekly' && (
            <div className='mt-2.5 flex items-center'>
              <span className='mr-6 text-tertiary'>Repeat on</span>
              <WeekDays
                selected={repeatOn}
                onChange={toggleWeekDay}
              />
              <span className='ml-6 mr-2.5 text-tertiary'>at</span>
              <TimeSelector value={repeatTime} onChange={(time) => setRepeatTime(time)}/>
            </div>
          )}
          <RadioButton
            value={frequency === 'daily'}
            setValue={() => setFrequency('daily')}
            label={'Daily'}
            className='mt-4'
          />
          {frequency === 'daily' && (
            <div className='mt-2.5 flex items-center'>
              <span className='mr-2.5 text-tertiary'>Repeat every</span>
              <Select
                options={repeatOptions.map(i => ({ label: i, value: i }))}
                value={repeatPeriod}
                onChange={(r) => setRepeatPeriod((r as Option).value.toString())}
                className='border'
              />
            </div>
          )}
          {integration && (
            <div className="flex justify-end mt-6 pt-4 border-t">
              <Button
                className="ml-4 w-24 !text-base"
                type={ButtonTypes.primary}
                text="Save"
                onClick={() => {
                  setUpdateInProgress(true);
                  update({schedule});
                }}
                isDisabled={updateDisabled}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduleBlock;
