export type SortOptions = 'newest' | 'oldest';
export type FilterOptions = { [param: string]: string[] };

export enum ModelChangesTab {
  Proposals = 'Proposals',
  InProgress = 'In progress',
  Archived = 'Archived'
}

export enum DataModelFilter {
  owner = 'Owner',
  createdAt = 'Created at',
  type = 'Type',
  sourceType = 'Source',
  usage14d = 'Total queries 14D',
  usage30d = 'Total queries 30D',
  usage60d = 'Total queries 60D',
}
