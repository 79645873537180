export const FormField = ({
    children,
    label,
    labelClassName = 'w-28',
    helper = '',
    helperClassName = '',
    error = '',
    className = '',
    fieldWrapperClassName = ''
}: {
    children?: React.ReactNode;
    label: string;
    labelClassName?: string;
    helper?: string;
    helperClassName?: string;
    error?: string;
    className?: string;
    fieldWrapperClassName?: string;
}) => {
    return (
        <div>
            <div className={`flex items-center text-text-primary ${className}`}>
                <div className={labelClassName}>{label}</div>
                <div className={`flex-1 ${fieldWrapperClassName}`}>{children}</div>
            </div>
            {helper && <div className={`text-xs text-tertiary mt-2 ${helperClassName}`}>{helper}</div>}
            {error && <div className="text-xs text-danger mt-2">{error}</div>}
        </div>
    );
};