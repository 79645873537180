import { useSelector } from "react-redux";
import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";

type ResourceSidepaneRelationshipsTabSelectorProps = {
    resource: IExpandedNode;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneRelationshipsTabSelector = ({ resource, setActiveTab, activeTab }: ResourceSidepaneRelationshipsTabSelectorProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getUpstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has downstream(uri='${resource.id}', 1)` });
    const getDownstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has upstream(uri='${resource.id}', 1)` });
    const upstreamResources = (getUpstreamResources.data?.items || []);
    const downstreamResources = (getDownstreamResources.data?.items || []);
    if (upstreamResources.length === 0 && downstreamResources.length === 0) return null;
    return (
        <ResourceSidepaneTab tab={ResourceTab.Relationships} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};