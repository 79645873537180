import { KeyIcon } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon, PlayIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { SparklesLeftArrowIcon } from "src/assets/images/icons/DelphiIcons";
import Input from "src/components/form/Input";
import { IExpandedNode, ISubnode, SubnodeType, subnodeTypePlurals } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { sortListAlphabeticallyByName } from "src/features/models/discover/resourceSidepane/sortListAlphabeticallyByName";

type SchemaFilters = {
    onlyCalculatedFields: boolean;
    onlyNonTrivialFields: boolean;
}

const defaultSchemaFilters: SchemaFilters = {
    onlyCalculatedFields: false,
    onlyNonTrivialFields: false,
};

export const ResourceSidepaneSchemaTab = ({ resource, setResourceId }: { resource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const [inputSearch, setInputSearch] = useState<string>('');
    const [schemaFilters] = useState<SchemaFilters>(defaultSchemaFilters);
    const filteredSubnodes = filterSubnodes(resource.subnodes, schemaFilters, inputSearch);
    const subnodeTypes = Array.from(new Set(resource.subnodes.map(n => n.type))).sort();
    return (
        <div className="bg-surface-light flex-1">
            <div className="p-4 flex-1 overflow-y-auto max-h-[80vh] flex flex-col gap-4 h-full">
                <div className="flex mx-2 items-center gap-2">
                    <div className="text-secondary font-semibold">{filteredSubnodes.length} items</div>
                    <Input
                        placeholder="Search"
                        value={inputSearch}
                        onInputChange={e => setInputSearch(e.target.value)}
                        border="border-primary"
                        rounded="rounded-full"
                        className="!w-60 ml-auto"
                        height="h-8"
                        icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
                    />
                    {/* <SchemaFiltersMenu schemaFilters={schemaFilters} setSchemaFilters={setSchemaFilters} /> */}
                </div>
                <div className="flex flex-col gap-2">
                    {
                        subnodeTypes.map((type, i) => <SchemaList index={i} setResourceId={setResourceId} parentResource={resource} key={type} type={type} subnodes={filteredSubnodes.filter(n => n.type === type)} />)
                    }
                </div>
            </div>
        </div>
    );
};

type SchemaListProps = {
    type: SubnodeType;
    subnodes: ISubnode[];
    parentResource: IExpandedNode;
    setResourceId: (id: string) => void;
    index: number;
}

const SchemaList = ({ type, subnodes, parentResource, setResourceId, index }: SchemaListProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(index === 0);
    return (
        <div className="rounded-lg border border-border">
            <div className="p-2 flex rounded-t-lg gap-1 bg-gradient-to-b from-lilac-50 items-center cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
                <div className="w-fit">
                    {
                        isExpanded ? <PlayIcon width="14" height="14" className="text-tertiary rotate-90" /> : <PlayIcon width="14" height="14" className="text-tertiary" />
                    }
                </div>
                <span className="text-secondary font-base">{subnodeTypePlurals[type]}</span>
                <span className="ml-auto text-slate-600">{subnodes.length}</span>
            </div>
            {
                isExpanded && (
                    <div className="rounded-b-lg overflow-y-auto max-h-96">
                        {
                            subnodes.sort(sortListAlphabeticallyByName).map((n, i) => (
                                <SchemaListItem
                                    setResourceId={setResourceId}
                                    isLastItem={i === subnodes.length - 1}
                                    key={n.id}
                                    index={i}
                                    subnode={n}
                                    parentResource={parentResource}
                                />
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

type SchemaListItemProps = {
    subnode: ISubnode;
    parentResource: IExpandedNode;
    isLastItem: boolean;
    setResourceId: (id: string) => void;
    index: number;
}

const SchemaListItem = ({ subnode, parentResource, isLastItem, setResourceId, index }: SchemaListItemProps) => {
    const unClickableTypes = [SubnodeType.Entity];
    const isClickable = !unClickableTypes.includes(subnode.type) && subnode.id;
    const badges: JSX.Element[] = [];
    if (subnode.subType === 'primary') {
        badges.push(<div className="rounded p-1 text-slate-400 bg-slate-100"><KeyIcon width="12" height="12" /></div>);
    }
    if (subnode.hasProposals) {
        badges.push(<div className="rounded p-1 text-primary bg-lilac-50"><SparklesLeftArrowIcon width="12" height="12" /></div>);
    }
    if (subnode.isCalculated) {
        badges.push(<div className="rounded text-sm py-0.5 px-1.5 text-slate-500 bg-slate-200">Calculated field</div>);
    }
    const onClick = () => {
        isClickable && subnode.id && setResourceId(subnode.id);
    };

    return (
        <div className={`flex items-center gap-2 py-2 px-2 border-border ${index % 2 === 0 ? 'bg-white hover:bg-slate-50' : 'bg-slate-50 hover:bg-slate-100'} ${isLastItem ? 'rounded-b-lg' : 'border-b'} ${isClickable && 'cursor-pointer'}`} onClick={onClick}>
            <NodeIcon type={parentResource.type} subnodeType={subnode.type} iconSize={16} />
            <div className="text-text-primary">{subnode.name}</div>
            {
                ...badges
            }
            <div className="text-slate-400 text-sm ml-auto uppercase">{subnode.subType}</div>
        </div>
    );
};

//Commented out until we migrate to resources in schema instead of subnodes
// type SchemaFiltersProps = {
//     schemaFilters: SchemaFilters;
//     setSchemaFilters: (filters: SchemaFilters) => void;
// }

// const SchemaFiltersMenu = ({ schemaFilters, setSchemaFilters }: SchemaFiltersProps) => {
//     const [isOpen, setIsOpen] = useState<boolean>(false);
//     const ref = useRef<HTMLDivElement>(null);
//     useClickAway(ref, () => setIsOpen(false));
//     const items = [
//         {
//             name: 'Only calcualated fields',
//             isChecked: schemaFilters.onlyCalculatedFields,
//             onClick: () => setSchemaFilters({ ...schemaFilters, onlyCalculatedFields: !schemaFilters.onlyCalculatedFields }),
//         },
//         {
//             name: 'Only non-trivial fields',
//             isChecked: schemaFilters.onlyNonTrivialFields,
//             onClick: () => setSchemaFilters({ ...schemaFilters, onlyNonTrivialFields: !schemaFilters.onlyNonTrivialFields }),
//         }
//     ];
//     return (
//         <div className="relative" ref={ref}>
//             <div className="cursor-pointer hover:bg-slate-50 text-tertiary rounded border border-border bg-white p-1" onClick={() => setIsOpen(!isOpen)}>
//                 <FilterIcon width="14" height="14" />
//             </div>
//             {
//                 isOpen && (
//                     <div className="absolute top-0 right-0 mt-8 bg-white rounded-lg shadow text-text-primary">
//                         {
//                             items.map(item => (
//                                 <div className="font-normal px-3 py-2 gap-8 whitespace-nowrap flex justify-between" key={item.name}>
//                                     {
//                                         item.name
//                                     }
//                                     <Checkbox value={item.isChecked} setValue={item.onClick} />
//                                 </div>
//                             ))
//                         }
//                     </div>
//                 )
//             }
//         </div>
//     );
// };

const filterSubnodes = (subnodes: ISubnode[], filters: SchemaFilters, search: string): ISubnode[] => {
    return subnodes.filter(n => {
        if (filters.onlyCalculatedFields && !n.isCalculated) return false;
        if (filters.onlyNonTrivialFields && n.isTrivialSql) return false;
        if (search && !n.name.toLowerCase().includes(search.toLowerCase())) return false;
        return true;
    });
};