import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { XMarkIcon, ChevronRightIcon, LinkIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { notify } from "src/components/Toaster";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { IChainedNode, IExpandedNode, NodeType } from "src/features/models/discover/INode";
import { events, trackEvent } from "src/infrastructure/analytics";

type ResourceSidpaneHeaderProps = {
    onClose: () => void;
    resource: IExpandedNode | null;
    setResourceId: (resourceId: string) => void;
    historyStack: string[];
    setHistoryStack: (historyStack: string[]) => void;
}
export const ResourceSidpaneHeader = ({ onClose, resource, setResourceId, historyStack, setHistoryStack }: ResourceSidpaneHeaderProps) => {
    useEffect(() => {
        if (resource) {
            if (historyStack[historyStack.length - 1] !== resource.id) {
                setHistoryStack([...historyStack, resource.id]);
            }
        }
    }, [resource, historyStack, setHistoryStack]);

    const canGoBack = historyStack.length > 1;
    const goBack = () => {
        if (canGoBack) {
            const newStack = [...historyStack];
            newStack.pop();
            setHistoryStack(newStack);
            setResourceId(newStack[newStack.length - 1]);
        }
    };

    const maxResourceNameLength = 25;

    const copyLink = () => {
        if (!resource) return;
        navigator.clipboard.writeText(`${window.location.origin}${getNodeUrl(resource.id, 'table')}`);
        trackEvent(events.resourceSidepaneCopyUrlClick);
        notify('Link copied to clipboard', 'success');
    };

    return (
        <div className="flex p-1.5 bg-gradient-to-b from-lilac-50 to-50% rounded-tl-2xl">
            <div className="flex gap-1 items-center">
                <div className="p-1 bg-slate-100 rounded-full w-fit cursor-pointer hover:bg-slate-200 text-slate-600" onClick={onClose}><XMarkIcon width="16" height="16" /></div>
                <div onClick={goBack} className={`p-1 bg-slate-100 rounded-full w-fit ${canGoBack ? 'text-slate-600 hover:bg-slate-200 cursor-pointer' : 'text-slate-300 cursor-not-allowed'}`}><ChevronLeftIcon width="16" height="16" /></div>
            </div>
            {
                resource && (
                    <div className="flex flex-1 justify-center items-center gap-1">
                        {
                            resource.chainedNodes.slice(-3).map(n => <ChainedResource key={n.id} resource={n} setResourceId={setResourceId} />)
                        }
                        <div className='text-tertiary'>
                            <TextWithEllipsisAndTooltip text={resource.name} maxChars={maxResourceNameLength} />
                        </div>
                        <LinkIcon width="12" height="12" className="text-slate-400 hover:text-slate-500 cursor-pointer" onClick={copyLink} />
                    </div>
                )
            }
        </div>
    );
};

const ChainedResource = ({ resource, setResourceId }: { resource: IChainedNode, setResourceId: (resourceId: string) => void }) => {
    const isClickable = Object.values(NodeType).map(t => `${t}`).includes(resource.type);
    const onClick = () => {
        if (isClickable) {
            trackEvent(events.resourceSidepaneBreadcrumbClick);
            setResourceId(resource.id);
        }
    };
    return (
        <>
            <div className={`text-slate-300 ${isClickable && 'cursor-pointer hover:text-slate-400'}`} onClick={onClick}>
                <TextWithEllipsisAndTooltip text={resource.name} maxChars={25} />
            </div>
            <ChevronRightIcon width="16" height="16" className="text-slate-300" />
        </>
    );
};
