import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneTab } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTab";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";

type ResourceSidepaneUsedFieldsTabSelectorProps = {
    resource: IExpandedNode;
    activeTab: ResourceTab;
    setActiveTab: (tab: ResourceTab) => void;
}

export const ResourceSidepaneUsedFieldsTabSelector = ({ resource, setActiveTab, activeTab }: ResourceSidepaneUsedFieldsTabSelectorProps) => {
    if (!resource.inputFields) return null;
    return (
        <ResourceSidepaneTab tab={ResourceTab.UsedFields} setActiveTab={setActiveTab} activeTab={activeTab} />
    );
};