import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from '../../localStorage/localStorage';
import localStorageKeys from '../../localStorage/localStorageKeys';
import { RootState } from '../store';

export default createSlice({
  name: 'showSubResourcesInDataModelTable',
  initialState: get(localStorageKeys.showSubResourcesInDataModelTable) || false,
  reducers: {
    setShowSubResourcesInDataModelTable: (_state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const selectShowSubResourcesInDataModelTable = (state: RootState) => state.showSubResourcesInDataModelTable;
