import { useKeyPressEvent } from 'react-use';
import { ButtonProps, ButtonTypes } from './types';

const buttonTypeToClass = (type: ButtonTypes) => {
  switch (type) {
    case 'primary':
      return 'bg-primary text-white hover:bg-lilac-800';
    case 'secondary':
      return 'bg-white text-secondary border border-gray-300 active:border-gray-300 hover:bg-slate-100';
    case 'danger':
      return 'bg-danger-light text-danger border border-danger';
  }
};

export const Button = ({ type, text, isLoading, isLoadingText, onClick, className, icon, isDisabled, dataTestId, enterToSubmit = false }: ButtonProps) => {

  useKeyPressEvent('Enter', () => {
    if (enterToSubmit) {
      onClick?.();
    }
  });

  const overloadedOnClick = () => {
    if (isDisabled || isLoading) {
      return;
    }
    onClick?.();
  };

  return (
    <button
      type="button"
      data-test-id={dataTestId}
      className={`flex h-8 items-center justify-center rounded-md px-3 py-2 text-sm outline-0 ${buttonTypeToClass(
        type
      )} ${className ? className : ''} ${isLoading || isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
      onClick={overloadedOnClick}>
      <div className="mr-1">{icon}</div>
      {isLoading ? isLoadingText || 'Loading...' : text}
    </button>
  );
};

export default Button;
