import { Tooltip } from "react-tooltip";
import { ISuperficialNode } from "../../INode";
import { getNodeIdForDom } from "../../getNodeIdForDom";

export const DescriptionColumn = ({ row }: { row: ISuperficialNode }) => {
  const divId = getNodeIdForDom(row.id, 'description');
  const showTooltip = (row.description || '').length > 80;
  return (
    <>
      <div data-tag="allowRowEvents" className="line-clamp-2 !whitespace-pre-line" id={divId}>{row.description}</div>
      {
        showTooltip && (
          <Tooltip anchorSelect={`#${divId}`} className='max-w-xs whitespace-normal'>
            {row.description}
          </Tooltip>
        )
      }
    </>
  );
};