import { Bars3BottomLeftIcon } from "@heroicons/react/24/solid";
import { ChangeEvent } from "react";
import Textarea from "../../../components/form/Textarea";

type DescriptionFormProps = {
    description: string;
    setDescription: (description: string) => void;
    label: string;
    labelClassName?: string;
    disabled?: boolean;
}

export const DescriptionForm = ({ description, setDescription, label, labelClassName = 'w-36', disabled = false }: DescriptionFormProps) => {
    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    };
    return (
        <div className="flex">
            <div className={`text-secondary mt-2 self-baseline text-sm font-medium flex items-center gap-1 ${labelClassName}`}>
                <Bars3BottomLeftIcon width="12" height="12" />
                {label} Description
            </div>
            <div className="flex-1">
                <Textarea
                    value={description}
                    onChange={onChange}
                    placeholder="Enter description"
                    rows={4}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};