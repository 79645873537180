import { MetabaseIcon } from '../../../assets/images/icons/DelphiIcons';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  useCreateMetabaseIntegrationMutation,
  useGetMetabaseIntegrationsQuery,
  useUnlinkMetabaseIntegrationMutation
} from '../../../services/integrations/integrations';
import { notify } from '../../../components/Toaster';
import { extractErrorMessage } from '../../../services/api';
import Container from '../../../components/layout/Container';
import DropdownMenu from '../../../components/DropdownMenu/DropdownMenu';
import { EllipsisVerticalIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import Modal from '../../../components/Modal/Modal';
import { IMetabaseIntegration } from './types';
import { Alert } from '../../../components/Alert';
import Input from '../../../components/form/Input';

const MetabaseIntegration = ({ accountId }: { accountId: number }) => {
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const { data: integrations = [] } = useGetMetabaseIntegrationsQuery({ accountId }, { skip: !accountId });

  const menuOptions = [
    {
      name: 'Edit configuration',
      className: 'hover:bg-slate-50',
      onClick: () => {
        setShowConfigurationModal(true);
      }
    }
  ];

  return (
    <Container className="flex h-40 w-40 flex-col items-center justify-center px-4 py-4">
      <div className="-mr-2 -mt-2 ml-auto">
        <DropdownMenu items={menuOptions} className="bg-white">
          <EllipsisVerticalIcon className="text-slate-400" width={20} height={20} />
        </DropdownMenu>
      </div>
      <MetabaseIcon width="56" height="56" className="mb-2 text-black" color="black" />
      <span className="mt-2 font-medium">Metabase</span>
      {integrations.length === 0 ? (
        <Button
          type={ButtonTypes.secondary}
          text="Configure"
          onClick={() => setShowConfigurationModal(true)}
          className="-mb-2 mt-1 h-6"
        />
      ) : (
        <span className="text-sm font-thin text-green-600">
          {integrations.length} active integration{integrations.length > 1 ? 's' : ''}
        </span>
      )}
      <IntegrationConfigurationModal
        isOpen={showConfigurationModal}
        onClose={() => setShowConfigurationModal(false)}
        accountId={accountId}
        integrations={integrations}
      />
    </Container>
  );
};

interface IntegrationConfigurationModalProps {
  isOpen: boolean;
  onClose: () => void;
  accountId: number;
  integrations: IMetabaseIntegration[];
}

const IntegrationConfigurationModal = ({
  isOpen,
  onClose,
  accountId,
  integrations
}: IntegrationConfigurationModalProps) => {
  const [unlinkIntegration, { originalArgs }] = useUnlinkMetabaseIntegrationMutation();
  const [addingInstance, setAddingInstance] = useState(false);
  const [createIntegration, { isLoading }] = useCreateMetabaseIntegrationMutation();
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [host, setHost] = useState('');
  const [error, setError] = useState<string>('');

  const onCreateClick = async () => {
    if (!user) {
      setError('User is required');
      return;
    }
    if (!password) {
      setError('Password is required');
      return;
    }
    if (!host) {
      setError('Metabase host is required');
      return;
    }
    try {
      await createIntegration({ accountId, password, host, user }).unwrap();
      notify('Metabase integration created successfully', 'success');
    } catch (e) {
      notify(`Failed to create integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  useEffect(() => {
    setError('');
  }, [isOpen, user, password, host]);

  useEffect(() => {
    if (isOpen) {
      setUser('');
      setPassword('');
      setHost('');
      setError('');
      if (integrations.length === 0) {
        setAddingInstance(true);
      }
    }
  }, [isOpen, integrations]);

  const onUnlinkClick = async (integration: IMetabaseIntegration) => {
    try {
      await unlinkIntegration({ accountId, integrationId: integration.id }).unwrap();
      notify('Integration unlinked successfully', 'success');
    } catch (e) {
      setError(`Error unlinking integration: ${extractErrorMessage(e).message}`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Configure Metabase Integration"
      maxWidth="max-w-lg"
      buttons={[{ type: ButtonTypes.primary, text: 'Done', onClick: onClose, className: 'w-32' }]}>
      <div className="rounded border border-slate-200 bg-slate-50">
        {integrations.map((integration) => (
          <div key={integration.id} className="flex items-center justify-between border-b border-slate-200 px-4 py-3">
            <div className="flex items-center">
              <MetabaseIcon width="20" height="20" className="mr-2" />
              <span className="text-sm">{integration.host}</span>
            </div>
            {originalArgs?.integrationId === integration.id ? (
              <span className="flex items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Loading...
              </span>
            ) : (
              <span
                onClick={() => onUnlinkClick(integration)}
                className="flex cursor-pointer items-center text-sm text-danger hover:text-danger-strong">
                <MinusCircleIcon width="16" height="16" className="mr-1" /> Remove
              </span>
            )}
          </div>
        ))}
        <div className="px-4 py-3">
          {!addingInstance && (
            <div
              className="flex items-center text-slate-600 hover:text-slate-800"
              onClick={() => setAddingInstance(!addingInstance)}>
              <PlusCircleIcon width="16" height="16" className="mr-1 cursor-pointer" />
              <span className="cursor-pointer text-sm">Add instance</span>
            </div>
          )}
          <div
            className={`transition-max-height overflow-auto duration-200 ${addingInstance ? 'max-h-72' : 'max-h-0'}`}>
            <InputField label="Host">
              <Input
                placeholder="Enter host address"
                value={host}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setHost(e.target.value)}
              />
            </InputField>
            <InputField label="User">
              <Input
                placeholder="Enter user"
                value={user}
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setUser(e.target.value)}
              />
            </InputField>
            <InputField label="Password">
              <Input
                placeholder="Enter password"
                value={password}
                type="password"
                onInputChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
            </InputField>
            <div>{error && <Alert title="Error:" text={error} type="error" />}</div>
            <div className="mt-4 flex h-8 flex-row flex-row-reverse">
              <Button
                type={ButtonTypes.secondary}
                text="Save"
                onClick={onCreateClick}
                isLoading={isLoading}
                className="w-24"
              />
              {integrations.length > 0 && (
                <Button
                  type={ButtonTypes.secondary}
                  text="Cancel"
                  onClick={() => setAddingInstance(false)}
                  className="w-24 mr-2"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const InputField = ({ children, label }: { children: React.ReactNode; label: string }) => {
  return (
    <div className="mb-4 flex items-center justify-between text-text-primary">
      <div className="w-20">{label}</div>
      <div className="w-80">{children}</div>
    </div>
  );
};

export default MetabaseIntegration;
