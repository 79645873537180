import Modal from '../../components/Modal/Modal';
import Input from '../../components/form/Input';
import EntityTypes from './stakeholdersEntityTypes';
import { notify } from '../../components/Toaster';
import { events, trackEvent } from '../../infrastructure/analytics';
import { useCallback, useEffect, useState } from 'react';
import Button from '../../components/button/Button';
import { ButtonTypes } from '../../components/button/types';
import { FolderIcon, CircleStackIcon } from '@heroicons/react/24/solid';
import { useDeleteFolderStakeholderMutation, useGetFolderStakeholdersQuery, useSetFolderStakeholdersMutation } from '../../services/folders';
import { useDeleteProjectStakeholdersMutation, useGetProjectStakeholdersQuery, useSetProjectStakeholdersMutation } from '../../services/projects/projects';
import stakeholdersEntityTypes from './stakeholdersEntityTypes';
import IStakeholder, { Roles, roleHierarchy } from './IStakeholder';
import Avatar from 'react-avatar';
import Select, { Option } from '../../components/form/Select';
import { extractErrorMessage } from '../../services/api';
import { validateEmail } from '../../utils/stringsUtils';
import { useGetUserQuery } from '../../services/users';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../infrastructure/state/slices/activeAccountSlice';
import { useDeleteBranchStakeholdersMutation, useGetBranchStakeholdersQuery, useSetBranchStakeholdersMutation } from 'src/services/branches';
import { BranchIcon } from 'src/assets/images/icons/DelphiIcons';

const roleOptions = [
  { value: Roles.developer, label: 'Developer' },
  { value: Roles.admin, label: 'Account Admin' },
  { value: Roles.contributr, label: 'Contributor' },
  { value: Roles.viewer, label: 'Viewer' },
  { value: Roles.owner, label: 'Owner' }
];

interface InviteStakeholdersModalProps {
  isOpen: boolean;
  onClose: () => void;
  entityType: EntityTypes;
  entityId: number;
  entityName: string;
}

const stakeholderEntityMap = {
  [stakeholdersEntityTypes.folder]: {
    query: useGetFolderStakeholdersQuery,
    set: useSetFolderStakeholdersMutation,
    delete: useDeleteFolderStakeholderMutation,
    icon: <FolderIcon width="20" height="20" className="ml-2 mr-1" />
  },
  [stakeholdersEntityTypes.project]: {
    query: useGetProjectStakeholdersQuery,
    set: useSetProjectStakeholdersMutation,
    delete: useDeleteProjectStakeholdersMutation,
    icon: <CircleStackIcon width="20" height="20" className="ml-2 mr-1" />
  },
  [stakeholdersEntityTypes.branch]: {
    query: useGetBranchStakeholdersQuery,
    set: useSetBranchStakeholdersMutation,
    delete: useDeleteBranchStakeholdersMutation,
    icon: <BranchIcon fill="#94A3B8" width="16" height="16" className="ml-2 mr-1" />
  }
};

const InviteStakeholdersModal = ({
  isOpen,
  onClose,
  entityType,
  entityId,
  entityName
}: InviteStakeholdersModalProps) => {
  const [inviteEmails, setInviteEmails] = useState('');
  const [inviteEmailsError, setInviteEmailsError] = useState('');
  const [inviteRole, setInviteRole] = useState<Roles>(Roles.developer);
  const { data: stakeholders = [], error: errorLoadingStakeholders } = stakeholderEntityMap[entityType].query({
    id: entityId
  });
  const [inviteRequest, { isLoading }] = stakeholderEntityMap[entityType].set();
  const accountId = useSelector(selectActiveAccountId);
  const isRootFolder = entityType === EntityTypes.folder && entityId === accountId;

  useEffect(() => {
    if (errorLoadingStakeholders) {
      // notify(`Error loading stakeholders: ${extractErrorMessage(errorLoadingStakeholders)}`, 'error');
      console.error(errorLoadingStakeholders);
      onClose();
    }
  }, [errorLoadingStakeholders, onClose]);

  const onInviteEmailsChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInviteEmails(e.target.value);
    setInviteEmailsError('');
  }, []);

  const onRoleChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setInviteRole(e.target.value as Roles);
  }, []);

  const submitInvite = useCallback(async () => {
    setInviteEmailsError('');
    if (!inviteEmails) {
      setInviteEmailsError('Email is required');
      return;
    }
    const emails = inviteEmails.split(',');
    const invalidEmails = emails.filter((email) => !validateEmail(email.trim()));
    if (invalidEmails.length > 0) {
      setInviteEmailsError(`Invalid email(s): ${invalidEmails.join(', ')}`);
      return;
    }
    try {
      await Promise.all(
        emails.map((email) => {
          return inviteRequest({ id: entityId, user: email.trim(), role: inviteRole }).unwrap();
        })
      );
      setInviteEmails('');
      notify('Invites sent successfully', 'success');
    } catch (e: unknown) {
      notify(`Error sending invites: ${extractErrorMessage(e).message}`, 'error');
      console.error(e);
    }
  }, [inviteEmails, inviteRole, entityId, inviteRequest]);

  const title = (
    <div className="flex items-center">
      Invite people to collaborate on {stakeholderEntityMap[entityType].icon}
      <span className="font-semibold">{entityName}</span>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} buttons={[]} maxWidth="max-w-xl" containerClassName='!m-0'>
      <div className="py-4">
        <div className="flex px-4">
          <Input
            placeholder="Email, comma separated"
            value={inviteEmails}
            onInputChange={onInviteEmailsChange}
            onSelectChange={onRoleChange}
            selectOptions={roleOptions.map((option) => ({ ...option, disabled: option.value === Roles.admin && !isRootFolder }))}
            dataTestId='invite-stakeholder-input'
            onEnter={submitInvite}
          />
          <Button
            isLoading={isLoading}
            type={ButtonTypes.primary}
            text="Add User"
            onClick={submitInvite}
            className="ml-2 !h-10 w-32 self-center !text-base"
          />
        </div>
        {inviteEmailsError && <small className="text-danger px-4">{inviteEmailsError}</small>}
        <StakeholderList stakeholders={stakeholders} entityId={entityId} entityType={entityType} />
      </div>
    </Modal>
  );
};

const StakeholderList = ({
  stakeholders,
  entityId,
  entityType
}: {
  stakeholders: IStakeholder[];
  entityId: number;
  entityType: EntityTypes;
}) => {
  const inheritedFromFolder = [...new Set(stakeholders.map((stakeholder) => stakeholder.inherited_from?.name))].filter(
    (name) => name
  );
  return (
    <div className="mt-5 max-h-[25rem] overflow-auto px-4">
      {stakeholders
        .filter((stakeholder) => !stakeholder.inherited_from)
        .map((stakeholder, i) => (
          <StakeholderRow
            hasHigherHierarchy={getHasHigherRoleFromInheritedFolder(stakeholder, stakeholders)}
            entityType={entityType}
            stakeholder={stakeholder}
            key={i}
            entityId={entityId}
          />
        ))}
      {inheritedFromFolder.map((folderName, i) => (
        <div key={i} className="mt-3">
          <div className="mt-5 flex font-thin text-slate-800">
            Inherited from {stakeholderEntityMap[EntityTypes.folder].icon} {folderName}
          </div>
          {stakeholders
            .filter((stakeholder) => stakeholder.inherited_from?.name === folderName)
            .map((stakeholder, i) => (
              <StakeholderRow entityType={entityType} stakeholder={stakeholder} key={i} entityId={entityId} />
            ))}
        </div>
      ))}
    </div>
  );
};

interface StakeholderRowProps {
  stakeholder: IStakeholder;
  entityId: number;
  entityType: EntityTypes;
  hasHigherHierarchy?: boolean;
}

const StakeholderRow = ({ stakeholder, entityId, entityType, hasHigherHierarchy = false }: StakeholderRowProps) => {
  const [setStakeholder, { isLoading: isLoadingStakeholderMutation, originalArgs, error: setStakeholderError }] = stakeholderEntityMap[entityType].set();
  const [deleteStakeholderMutation] = stakeholderEntityMap[entityType].delete();
  const { isLoading: isLoadingStakeholders } = stakeholderEntityMap[entityType].query({ id: entityId });
  const { data: currentUser } = useGetUserQuery();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const changeStakeholderRole = useCallback(
    async (user: string, role: Roles) => {
      try {
        await setStakeholder({ id: entityId, user, role });
        trackEvent(events.stakeholderRoleUpdated);
      } catch (e) {
        notify(`Error updating stakeholder role: ${extractErrorMessage(e).message}`, 'error');
        console.error(e);
      }
    },
    [entityId, setStakeholder]
  );

  const deleteStakeholder = useCallback(
    async (user: string) => {
      try {
        await deleteStakeholderMutation({ entityId, user }).unwrap();
        notify('User has been successfully removed.', 'success');
        setShowDeleteModal(false);
      } catch (e) {
        notify(`Error deleting stakeholder: ${extractErrorMessage(e).message}`, 'error');
        console.error(e);
      }
    },
    [deleteStakeholderMutation, entityId]
  );

  useEffect(() => {
    if (setStakeholderError) {
      notify(`Error updating stakeholder role: ${extractErrorMessage(setStakeholderError).message}`, 'error');
    }
  }, [setStakeholderError]);

  const onChange = (role: string) => {
    if (role === 'remove') {
      setShowDeleteModal(true);
    } else {
      changeStakeholderRole(stakeholder.user, role as Roles);
    }
  };

  const isCurrentUser = currentUser?.email === stakeholder.user;

  // We want to show loading state for the stakeholder that is being updated and not for all the stakeholders
  const isLoading = isLoadingStakeholderMutation || (originalArgs?.id === entityId && isLoadingStakeholders);

  return (
    <div className="mt-2 flex items-center" data-test-id={`stakeholder-${stakeholder.user}`}>
      <Avatar textSizeRatio={2} value={stakeholder.user.slice(0, 2).toLocaleUpperCase()} size="30" round={true} />
      <div>
        <div className="ml-3 font-light">{stakeholder.user}</div>
        {
          hasHigherHierarchy && (
            <div className="ml-3 font-light text-sm text-slate-600">* User role inherited from parent folder</div>
          )
        }
      </div>
      {isCurrentUser && <span className="ml-2 text-slate-400">You</span>}
      <div className="ml-auto">
        {isCurrentUser ? (
          <span className="capitalize text-slate-500">{stakeholder.role}</span>
        ) : (
          <div className="flex items-center gap-1">
            <Select
              options={[...roleOptions, { value: 'remove', label: 'Remove' }]}
              value={isLoading ? 'updating..' : stakeholder.role}
              isLoading={isLoading}
              onChange={(option) => onChange((option as Option).value as string)}
              dataTestId='stakeholder-role-select'
            />
          </div>
        )}
      </div>
      <Modal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        title="Remove user"
        buttons={[
          { type: ButtonTypes.secondary, text: "Cancel", onClick: () => setShowDeleteModal(false) },
          { type: ButtonTypes.danger, text: "Remove user", onClick: () => deleteStakeholder(stakeholder.user) }
        ]}
        maxWidth="max-w-md"
      >
        <div className="text-slate-800">Are you sure you want to remove this user from the {entityType}?</div>
      </Modal>
    </div>
  );
};

const getHasHigherRoleFromInheritedFolder = (currentStakeholder: IStakeholder, stakeholders: IStakeholder[]) => {
  const currentEntityRoleRank = roleHierarchy.indexOf(currentStakeholder.role);
  const inheritedFolderStakeholders = stakeholders.filter((s) => s.user === currentStakeholder.user);
  for (const inheritedStakeholder of inheritedFolderStakeholders) {
    const inheritedEntityRoleRank = roleHierarchy.indexOf(inheritedStakeholder.role);
    if (inheritedEntityRoleRank < currentEntityRoleRank) {
      return true;
    }
  }
  return false;
};

export default InviteStakeholdersModal;
