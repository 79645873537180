import { AdjustmentsVerticalIcon, Bars3Icon, MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import Button from "../../button/Button";
import { ButtonTypes } from "../../button/types";
import { useState, useRef, useEffect } from "react";
import { DropResult, DragDropContext, Draggable, DroppableProps, Droppable } from "react-beautiful-dnd";
import { useClickAway } from "react-use";
import DropdownMenu from "../../DropdownMenu/DropdownMenu";
import { TableColumnProps } from "../types";
import { events, trackEvent } from "src/infrastructure/analytics";

type SelectorColumnProps = {
    columns: TableColumnProps[];
    updateColumns: (columns: TableColumnProps[]) => void;
    selectedColumns: TableColumnProps[];
    tableName: string
}

export const SelectorColumn = ({ columns, updateColumns, selectedColumns, tableName }: SelectorColumnProps) => {
    const [showSelector, setShowSelector] = useState(false);
    const selectorRef = useRef<HTMLDivElement>(null);
    const hideSelector = () => setShowSelector(false);
    useClickAway(selectorRef, hideSelector);
    return (
        <div>
            <Button className="mb-2" type={ButtonTypes.secondary} text="Customize columns" onClick={() => setShowSelector(true)} icon={<AdjustmentsVerticalIcon width="16" height="16" />} />
            {
                showSelector && (
                    <div ref={selectorRef} className="py-2 absolute min-w-[300px] top-18 z-20 rounded-md border border-slate-200 bg-white shadow">
                        <div className="flex items-center gap-1 text-text-primary px-2">
                            <AdjustmentsVerticalIcon height={16} width={16} />
                            Customize columns
                            <Button text="Done" type={ButtonTypes.secondary} onClick={hideSelector} className="ml-auto" />
                        </div>
                        <DraggableColumList tableName={tableName} columns={selectedColumns} updateColumns={updateColumns} />
                        <AddColumn tableName={tableName} columns={selectedColumns} updateColumns={updateColumns} columnOptions={columns} />
                    </div>
                )
            }
        </div>
    );
};


type DraggableColumListProps = {
    columns: TableColumnProps[];
    updateColumns: (columns: TableColumnProps[]) => void;
    tableName: string;
}

const DraggableColumList = ({ columns, updateColumns, tableName }: DraggableColumListProps) => {
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;
        const newColumns = Array.from(columns);
        const [reorderedItem] = newColumns.splice(result.source.index, 1);
        trackEvent(events.dataModelColumnReordered, { column: reorderedItem.name, tableName });
        newColumns.splice(result.destination.index, 0, reorderedItem);
        updateColumns(newColumns);
    };

    const remove = (name: string) => {
        trackEvent(events.dataModelColumnRemoved, { column: name, tableName });
        updateColumns(columns.filter(column => column.name !== name));
    };

    return (
        <div className="mt-2 px-2 overflow-y-auto max-h-[50vh]">
            <div className="border border-slate-200 rounded-md bg-surface-light">
                <DragDropContext onDragEnd={onDragEnd}>
                    <StrictModeDroppable droppableId="table-column-selector">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="text-text-primary py-1"
                            >
                                {columns.map((column, index) => {
                                    const key = `column-selector-${column.name.toLowerCase().replace(/ /g, '-')}`;
                                    return (
                                        <Draggable key={key} draggableId={key} index={index} isDragDisabled={column.isNotRemovable}>
                                            {(provided) => (
                                                <>
                                                    <div
                                                        className={`py-1 px-2 flex gap-1 text-sm items-center ${column.isNotRemovable && 'text-slate-400 cursor-not-allowed'}`}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Bars3Icon height={12} width={12} className="text-slate-500" />
                                                        {column.name}
                                                        {
                                                            !column.isNotRemovable && <MinusCircleIcon onClick={() => remove(column.name)} height={16} width={16} className="ml-auto text-slate-300 hover:text-slate-400 cursor-pointer" />
                                                        }
                                                    </div>
                                                    {
                                                        index !== columns.length - 1 && <div className="h-[1px] bg-slate-200"></div>
                                                    }
                                                </>
                                            )}
                                        </Draggable>
                                    );
                                }
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </StrictModeDroppable>
                </DragDropContext>
            </div>
        </div>
    );
};

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true));
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) {
        return null;
    }
    return <Droppable {...props}>{children}</Droppable>;
};

type AddColumnProps = {
    columns: TableColumnProps[];
    updateColumns: (columns: TableColumnProps[]) => void;
    columnOptions: TableColumnProps[];
    tableName: string;
}

const AddColumn = ({ columns, updateColumns, columnOptions, tableName }: AddColumnProps) => {
    const items = columnOptions.filter(co => !columns.some(c => c.name === co.name)).map(column => ({
        name: column.name,
        onClick: () => {
            updateColumns([...columns, column]);
            trackEvent(events.dataModelColumnAdded, { column: column.name, tableName });
        }
    }));
    return (
        <div className="px-2 relative">
            <DropdownMenu items={items} className="bg-white overflow-auto max-h-80">
                <div className={`flex gap-1 mt-2 items-center w-fit text-tertiary text-sm ${items.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'}`} ><PlusCircleIcon width="14" height="14" /> Add column</div>
            </DropdownMenu>
        </div>
    );
};
