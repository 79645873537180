import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { ChangeType, IChange } from '../IChange';
import { getNodesPositions } from '../../../infrastructure/DAG/getNodesPositions';
import Xarrow, { Xwrapper } from 'react-xarrows';
import { useDraggable } from 'react-use-draggable-scroll';
import { getImplicationNodes as getNewColumnImplicationNodes } from './newColumnChange/getImplicationNodes';
import Button from '../../../components/button/Button';
import { ButtonTypes } from '../../../components/button/types';
import { useNavigate } from 'react-router-dom';
import { ModelChangesIcon } from '../../../assets/images/icons/DelphiIcons';
import { getImplicationNodes as getNewMeasureImplicationNodes } from './newMeasureChange/getImplicationNodes';
import { getNodeIdForDom } from '../../models/discover/getNodeIdForDom';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../infrastructure/state/slices/activeAccountSlice';
import { getNodeUrl } from '../../models/discover/getNodeUrl';

const xOffset = -70;
const yOffset = 0;

type ImplicationNode = {
  id: string;
  parents: string[];
  width: number;
  height: number;
  component: JSX.Element;
}

const ChangeImplications = ({ change }: { change: IChange; }) => {
  const accountId = useSelector(selectActiveAccountId);
  const [nodes, setNodes] = useState<ImplicationNode[]>([]);
  useEffect(() => {
    if (change && accountId) {
      getNodes({ change, accountId }).then(setNodes);
    }
  }, [change, accountId]);
  const navigate = useNavigate();
  const nodesPositions = useMemo(
    () =>
      getNodesPositions({
        nodes: nodes.map((n) => ({ id: n.id, parents: n.parents, width: n.width, height: n.height })),
        xOffset,
        yOffset
      }),
    [nodes]
  );
  const goToDataModel = () => {
    navigate(getNodeUrl(change.targetUtl));
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-text-primary font-medium">Data model</div>
        <Button
          type={ButtonTypes.secondary}
          text="Data model"
          icon={<ModelChangesIcon width="14" height="14" fill="#334155" />}
          onClick={goToDataModel}
          className="ml-auto"
        />
      </div>
      <div className="relative mt-2 h-[40vh] rounded-lg border border-slate-200 bg-gradient-to-b from-gray-50 to-white">
        <DAG nodes={nodes} nodesPositions={nodesPositions} />
      </div>
    </div>
  );
};

const DAG = ({ nodes, nodesPositions }: { nodes: ImplicationNode[]; nodesPositions: Map<string, { x: number; y: number }> }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { events } = useDraggable(ref as MutableRefObject<HTMLElement>);
  const nodeIds = nodes.map(n => n.id);
  return (
    <div {...events} ref={ref} className="border-1 absolute flex h-full w-full overflow-auto rounded-lg border-slate-200">
      <Xwrapper>
        {nodes.map((node) => (
          <div
            id={getNodeIdForDom(node.id, 'implication')}
            className="absolute"
            style={{ top: nodesPositions.get(node.id)!.y, left: nodesPositions.get(node.id)!.x }}
            key={node.id}>
            {node.component}
          </div>
        ))}
        {nodes.flatMap((n) =>
          n.parents.filter(p => nodeIds.includes(p)).map((p) => (
            <Xarrow
              key={[p, n.id].join('-')}
              start={getNodeIdForDom(p, 'implication')}
              end={getNodeIdForDom(n.id, 'implication')}
              zIndex={0}
              color={'#CBD5E1'}
              strokeWidth={1}
              showHead={false}
            />
          ))
        )}
      </Xwrapper>
    </div>
  );
};

const getNodes = async ({ change, accountId }: { change: IChange, accountId: number }): Promise<ImplicationNode[]> => {
  if (change.changeType === ChangeType.NewColumn) {
    return await getNewColumnImplicationNodes({ change, accountId });
  }
  else if (change.changeType === ChangeType.NewMeasure) {
    return await getNewMeasureImplicationNodes({ change, accountId });
  }
  else {
    return [];
  }
};

export default ChangeImplications;
