import Select, {Option} from "src/components/form/Select";


interface TimeSelectorProps {
  onChange: (day: string) => void;
  value?: string;
  className?: string;
}

const generateTimeOptions = () => {
  const times = [];

  for (let hour = 0; hour < 24; hour++) {
    // Format hour as 24-hour time (e.g., "10:00:00", "22:00:00")
    const hour24 = `${hour.toString().padStart(2, "0")}:00:00`;

    // Convert to 12-hour time with AM/PM
    const hour12 = ((hour % 12) || 12).toString().padStart(2, "0") + ":00";
    const period = hour < 12 ? "AM" : "PM";

    // Push an object with the 12-hour format as key and 24-hour format as value
    times.push({ label: `${hour12} ${period}`, value: hour24 });
  }

  return times;
};

export const TimeSelector = ({ value, onChange, className }: TimeSelectorProps) => {
  const times = generateTimeOptions();
  return (
    <div>
      <Select
        options={times.map(i => ({ label: i.label, value: i.value }))}
        value={value || `10:00`}
        onChange={(time) => onChange((time as Option).value.toString())}
        className={`border ${className}`}
      />
    </div>
  );
};
