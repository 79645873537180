import { useMemo, useState } from "react";
import Input from "../../components/form/Input";
import { ArrowLeftIcon, EnvelopeIcon, PencilIcon } from "@heroicons/react/24/outline";
import Button from "../../components/button/Button";
import { ButtonTypes } from "../../components/button/types";
import { useDescope } from "@descope/react-sdk";
import { events, trackEvent } from "../../infrastructure/analytics";

type OnError = (error: string) => void;

type MagicLinkLoginProps = {
    onClose: () => void;
    onError: OnError
}
export const MagicLinkLogin = ({ onClose, onError }: MagicLinkLoginProps) => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const descope = useDescope();
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const magicLinkToken = useMemo(() => (new URLSearchParams(window.location.search)).get('t'), []);

    const sendMagicLink = async () => {
        onError('');
        if (!email) {
            onError('Please enter your email.');
            return;
        }
        setIsLoading(true);
        try {
            // @ts-expect-error - customClaims is not part of the type definition
            const res = await descope.magicLink.signIn.email(email, window.location.href, { customClaims: { email } });
            if (res.ok) {
                setShowSuccessScreen(true);
            }
            else {
                onError(res.error?.errorMessage || 'Error occurred, please try again later.');
            }
        } catch (e) {
            console.error(e);
            onError('Error occurred, please try again later.');
        }
        setIsLoading(false);
    };

    if (magicLinkToken) {
        return <LinkVerification onError={onError} token={magicLinkToken} />;
    }

    if (showSuccessScreen) {
        return <SuccessScreen email={email} onEdit={() => setShowSuccessScreen(false)} onClose={onClose} />;
    }

    return (
        <>
            <div className="mx-auto w-fit mt-8 text-md mb-2">Welcome back</div>
            <div className="w-60 mx-auto mt-4">
                <Input onEnter={sendMagicLink} className="text-sm" placeholder="Enter your email" value={email} onInputChange={e => setEmail(e.target.value)} icon={<EnvelopeIcon width="14" height="14" className="text-slate-400" />} />
                <Button type={ButtonTypes.primary} isLoading={isLoading} text="Send me a magic link" className="w-full mt-4" onClick={sendMagicLink} />
                <div className="text-primary text-sm flex items-center gap-1 mx-auto w-fit mt-4 cursor-pointer" onClick={() => onClose()}>
                    <ArrowLeftIcon width="12" height="12" />
                    Back to login
                </div>
            </div>
        </>
    );
};

const SuccessScreen = ({ email, onEdit, onClose }: { email: string, onEdit: () => void, onClose: () => void }) => {
    return (
        <>
            <div className="mx-auto w-fit mt-8 text-md mb-2">Check your email</div>
            <div className="w-60 mx-auto mt-4 text-center text-text-primary text-sm">
                We have sent a magic link to
                <div className="text-primary flex gap-1 items-center w-fit mx-auto">{email}<EditIcon onClick={onEdit} /></div>
                Click the link to sign in.
            </div>
            <div className="text-primary text-sm flex items-center gap-1 mx-auto w-fit mt-8 cursor-pointer" onClick={onClose}>
                <ArrowLeftIcon width="12" height="12" />
                Back to login
            </div>
        </>
    );
};

const LinkVerification = ({ token, onError }: { token: string, onError: OnError }) => {
    const descope = useDescope();
    const [isLoading, setIsLoading] = useState(false);

    const verifyMagicLink = async () => {
        trackEvent(events.magicLinkLogin);
        setIsLoading(true);
        descope.magicLink.verify(token).then(res => {
            if (res.ok) {
                window.location.href = '/';
            }
            else {
                onError(res.error?.errorMessage || 'Error occurred, please try again later.');
                setIsLoading(false);
            }
        });
    };

    return (
        <>
            <div className="mx-auto w-fit mt-8 text-md mb-2">Let&apos;s get you in</div>
            <div className="mx-auto w-60 mt-2 text-sm mb-2 text-center">You are about to log in to Euno using a magic link.</div>
            <Button type={ButtonTypes.primary} isLoading={isLoading} text="Log in" className="w-60 mx-auto mt-8" onClick={verifyMagicLink} />
        </>
    );
};

const EditIcon = ({ onClick }: { onClick: () => void }) => {
    return (
        <div className="rounded-full w-fit p-1 bg-slate-100 cursor-pointer hover:bg-slate-200" onClick={onClick}>
            <PencilIcon width="8" height="8" className="text-slate-400" />
        </div>
    );
};