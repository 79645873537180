import { Materialization } from "../../../IChange";
import { MaterilizationIcon } from "../../../../../assets/images/icons/DelphiIcons";
import RadioButton from "../../../../../components/form/RadioButton";
import { NewAggregateTableChangeColumn, NewAggregateTableChangeColumnType } from "../types";

type NewAggregateTableMaterializationFormProps = {
    materialization: Materialization;
    setMaterialization: (materialization: Materialization) => void;
    labelClassName?: string;
    columns: NewAggregateTableChangeColumn[];
}

export const NewAggregateTableMaterializationForm = ({ columns, materialization, setMaterialization, labelClassName = 'w-36' }: NewAggregateTableMaterializationFormProps) => {
    const keys = columns.filter(c => c.type !== NewAggregateTableChangeColumnType.metric);
    return (
        <div className="flex gap-2 items-start">
            <div className={`text-secondary text-sm font-medium flex items-center gap-1 ${labelClassName}`}>
                <MaterilizationIcon width="12" height="12" />
                Model materialization
            </div>
            <div className="text-sm flex flex-col gap-1 -ml-1">
                <RadioButton value={materialization === 'view'} setValue={() => setMaterialization('view')} label={'View'} />
                <RadioButton value={materialization === 'table'} setValue={() => setMaterialization('table')} label={'Table'} />
                <div className="flex gap-1 items-start">
                    <RadioButton value={materialization === 'incremental'} setValue={() => setMaterialization('incremental')} />
                    <div>
                        Incremental
                        {
                            materialization === 'incremental' &&
                            <div className="text-xs text-tertiary">{keys.map(k => k.alias || k.name).join(', ')}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};