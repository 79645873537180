interface TextAreaProps {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  disabled?: boolean;
}

const Textarea = ({ className, placeholder, value, onChange, rows, disabled = false }: TextAreaProps) => {
  const baseClassName = 'form-control border-grey-300 w-full w-full rounded-md border px-3 py-2 text-secondary shadow outline-0 hover:border-lilac-400 focus:border-lilac-400 active:border-lilac-400';
  let finalClassName = baseClassName;
  if (className) {
    finalClassName = `${baseClassName} ${className}`;
  }
  if (disabled) {
    finalClassName = `${finalClassName} bg-slate-50 cursor-not-allowed`;
  }

  return (
    <textarea
      className={finalClassName}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rows={rows}
      disabled={disabled}
    />
  );
};

export default Textarea;
