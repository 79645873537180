import loginImg from '../../assets/images/login.png';
import { Logo } from "src/assets/images/icons/DelphiIcons";

export const UserNotFoundPage = () => {
    
    const goToLogin = () => {
        window.location.href = '/login';
    };

    return (
        <div className="flex h-screen">
            <div className="bg-white flex-1 h-full flex flex-col justify-center">
                <div className="w-64 mx-auto text-center">
                    <Logo className="mx-auto" height="20px"/>
                    <div className="text-text-primary font-semibold text-lg mt-12">Email not found</div>
                    <div className="text-text-primary mt-4 text-base">We couldn’t find an account with this email. Please check your entry and try again.</div>
                    <div className="text-primary cursor-pointer hover:text-lilac-900 mt-12" onClick={goToLogin}>Back to login</div>
                </div>
            </div>
            <div className="flex-1 h-full bg-gradient-to-r from-lilac-200 to-white flex flex-col justify-center">
                <img src={loginImg} alt="login" className='max-w-[35vw] m-auto' />
            </div>
        </div>
    );
};