import { dateFormats, utcToLocal } from 'src/infrastructure/dateUtilities';
import {
    IExpandedNode,
    INodeUsage,
    ISourceNodeUsage,
    ISuperficialNode,
    IUserNodeUsage,
    MetricDependency,
    NodeType,
    SubnodeType,
    Swimlane
} from '../../features/models/discover/INode';
import {
    BackendSuperficialResource,
    BackendSubnodeType,
    BackendExpandedNodeResponse,
    BackendNodeType,
    ExternalNodeUsage,
    UsageAppBreakdown,
    UsageUserBreakdown,
    MetricDependenciesResponse,
    BackendExpandedSubnode
} from './types';

export const transformBackendSuperficialResourceToLocalNode = (backendSuperficialResource: BackendSuperficialResource): ISuperficialNode | null => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(backendSuperficialResource.type);
    if (!nodeType) {
        console.error(`Unknown node type: ${backendSuperficialResource.type}`);
        return null;
    }
    const node: ISuperficialNode = {
        ...backendSuperficialResource,
        id: backendSuperficialResource.utl || backendSuperficialResource.uri || '',
        parents: backendSuperficialResource.parents || [],
        type: nodeType,
        generatedByDelphi: backendSuperficialResource.generated_by_delphi === true || `${backendSuperficialResource.generated_by_delphi}` === 'true',
        name: backendSuperficialResource.name,
        description: backendSuperficialResource.description,
        tags: backendSuperficialResource.tags,
        meta: backendSuperficialResource.meta || {},
        tableauWorkbook: backendSuperficialResource.tableau_workbook || '',
        tableauProject: backendSuperficialResource.tableau_project || '',
        dbtMaterializationStrategy: backendSuperficialResource.dbt_materialization_strategy,
        materialized: backendSuperficialResource.materialized,
        database: backendSuperficialResource.database,
        databaseSchema: backendSuperficialResource.schema || '',
        repo: backendSuperficialResource.git_repo_url,
        branch: backendSuperficialResource.git_repo_branch,
        package: backendSuperficialResource.package_name,
        identifier: backendSuperficialResource.unique_id,
        parentName: backendSuperficialResource.parent_name || backendSuperficialResource.parent_container_name,
        numberOfDimensions: backendSuperficialResource.number_of_dimensions,
        numberOfMeasures: backendSuperficialResource.number_of_measures,
        numberOfEntities: backendSuperficialResource.number_of_entities,
        numberOfMetrics: backendSuperficialResource.number_of_metrics,
        numberOfCustomFields: backendSuperficialResource.number_of_custom_fields,
        numberOfColumns: backendSuperficialResource.number_of_columns,
        hasProposals: backendSuperficialResource.has_shift_left_potential || false,
        isTrivialSql: backendSuperficialResource.is_trivial_sql,
        owner: backendSuperficialResource.owner,
        subnodes: (backendSuperficialResource.subnodes || []).map((subnode) => ({
            name: subnode.name,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.subnode_type) || SubnodeType.Column
        })),
        firstSeen: backendSuperficialResource.first_seen_at,
        dbtProject: backendSuperficialResource.dbt_project || null,
        lookerFolder: backendSuperficialResource.looker_folder || null,
        lookerModel: backendSuperficialResource.looker_model || null,
        sourceDirectory: backendSuperficialResource.source_directory || null,
        lookerHost: backendSuperficialResource.looker_host || null,
        totalViews7Days: backendSuperficialResource.last_7d_views,
        totalViews30Days: backendSuperficialResource.last_30d_views,
        totalQueries14Days: backendSuperficialResource.total_queries_14d,
        databaseTechnology: backendSuperficialResource.database_technology || null,
        totalQueries30Days: backendSuperficialResource.total_queries_30d,
        totalQueries60Days: backendSuperficialResource.total_queries_60d,
        distinctUsers14Days: backendSuperficialResource.distinct_users_14d,
        distinctUsers30Days: backendSuperficialResource.distinct_users_30d,
        distinctUsers60Days: backendSuperficialResource.distinct_users_60d,
        totalImpressions14Days: backendSuperficialResource.total_impressions_14d,
        totalImpressions30Days: backendSuperficialResource.total_impressions_30d,
        totalImpressions60Days: backendSuperficialResource.total_impressions_60d,
        distinctUserImpressions14Days: backendSuperficialResource.distinct_impressions_users_14d,
        distinctUserImpressions30Days: backendSuperficialResource.distinct_impressions_users_30d,
        distinctUserImpressions60Days: backendSuperficialResource.distinct_impressions_users_60d,
        tableauHasExtracts: backendSuperficialResource.tableau_has_extracts,
        nativeLastDataUpdate: backendSuperficialResource.native_last_data_update,
        isCalculated: backendSuperficialResource.is_calculated,
        refreshFrequency: backendSuperficialResource.tableau_extract_refresh_frequency,
        isIdentityTransformation: backendSuperficialResource.is_identity_transformation ?? null,
        lookmlViewPersistency: backendSuperficialResource.lookml_view_persistency || null,
        pdtBuildsLast30d: backendSuperficialResource.pdt_builds_last_30d ?? null,
        pdtTotalBuildTime30d: backendSuperficialResource.pdt_total_build_time_30d || null,
        derivedType: backendSuperficialResource.derived_type || null,
        sourcePath: backendSuperficialResource.source_path || null,
        hasRefinements: backendSuperficialResource.has_refinements ?? null,
    };
    return node;
};

export const mapBackendNodeTypeToLocalNodeType = new Map<BackendNodeType, NodeType>([
    [BackendNodeType.DBTModel, NodeType.DataModel],
    [BackendNodeType.DBTMetric, NodeType.Metric],
    [BackendNodeType.DataSource, NodeType.DataSource],
    [BackendNodeType.LookerView, NodeType.LookerView],
    [BackendNodeType.LookerDerivedView, NodeType.LookerDerivedView],
    [BackendNodeType.LookerExplore, NodeType.LookerExplore],
    [BackendNodeType.LookerLook, NodeType.LookerLook],
    [BackendNodeType.GenericDataTransformation, NodeType.GenericDataTransformation],
    [BackendNodeType.LookerDashboard, NodeType.LookerDashboard],
    [BackendNodeType.LookerTile, NodeType.LookerTile],
    [BackendNodeType.TableauWorkbook, NodeType.TableauWorkbook],
    [BackendNodeType.TableauView, NodeType.TableauView],
    [BackendNodeType.TableauCustomQuery, NodeType.TableauCustomQuery],
    [BackendNodeType.TableauPublishedDataSource, NodeType.TableauPublishedDataSource],
    [BackendNodeType.TableauEmbeddedDataSource, NodeType.TableauEmbeddedDataSource],
    [BackendNodeType.TableauDashboard, NodeType.TableauDashboard],
    [BackendNodeType.TableauStory, NodeType.TableauStory],
    [BackendNodeType.Table, NodeType.Table],
    [BackendNodeType.Column, NodeType.Column],
    [BackendNodeType.DBTColumn, NodeType.DbtColumn],
    [BackendNodeType.DBTDimension, NodeType.DbtDimension],
    [BackendNodeType.DBTMeasure, NodeType.DbtMeasure],
    [BackendNodeType.LookerDimension, NodeType.LookerDimension],
    [BackendNodeType.LookerMeasure, NodeType.LookerMeasure],
    [BackendNodeType.TableauDimension, NodeType.TableauDimension],
    [BackendNodeType.TableauMeasure, NodeType.TableauMeasure],
]);

export const mapBackendSubnodeTypeToLocalSubnodeType = new Map<BackendSubnodeType, SubnodeType>([
    [BackendSubnodeType.Dimension, SubnodeType.Dimension],
    [BackendSubnodeType.Measure, SubnodeType.Measure],
    [BackendSubnodeType.Entity, SubnodeType.Entity],
    [BackendSubnodeType.Metric, SubnodeType.Metric],
    [BackendSubnodeType.CustomField, SubnodeType.CustomField],
    [BackendSubnodeType.Column, SubnodeType.Column],
    [BackendSubnodeType.TableCalculation, SubnodeType.TableCalculation],
    [BackendSubnodeType.LookerConnectedView, SubnodeType.LookerConnectedView],
    [BackendSubnodeType.LookerLook, SubnodeType.LookerLook],
    [BackendSubnodeType.LookerTile, SubnodeType.LookerTile],
    [BackendSubnodeType.TableauConnectedView, SubnodeType.TableauConnectedView],
]);

export const mapNodeTypeToBackendNodeType = new Map<NodeType, BackendNodeType>([
    [NodeType.DataModel, BackendNodeType.DBTModel],
    [NodeType.DataSource, BackendNodeType.DataSource],
    [NodeType.LookerView, BackendNodeType.LookerView],
    [NodeType.LookerDerivedView, BackendNodeType.LookerDerivedView],
    [NodeType.LookerExplore, BackendNodeType.LookerExplore],
    [NodeType.LookerLook, BackendNodeType.LookerLook],
    [NodeType.GenericDataTransformation, BackendNodeType.GenericDataTransformation],
    [NodeType.LookerDashboard, BackendNodeType.LookerDashboard],
    [NodeType.LookerTile, BackendNodeType.LookerTile],
    [NodeType.TableauWorkbook, BackendNodeType.TableauWorkbook],
    [NodeType.TableauView, BackendNodeType.TableauView],
    [NodeType.TableauCustomQuery, BackendNodeType.TableauCustomQuery],
    [NodeType.TableauPublishedDataSource, BackendNodeType.TableauPublishedDataSource],
    [NodeType.TableauEmbeddedDataSource, BackendNodeType.TableauEmbeddedDataSource],
    [NodeType.TableauDashboard, BackendNodeType.TableauDashboard],
    [NodeType.TableauStory, BackendNodeType.TableauStory],
    [NodeType.Table, BackendNodeType.Table],
    [NodeType.Column, BackendNodeType.Column],
    [NodeType.DbtColumn, BackendNodeType.DBTColumn],
    [NodeType.DbtDimension, BackendNodeType.DBTDimension],
    [NodeType.DbtMeasure, BackendNodeType.DBTMeasure],
    [NodeType.LookerDimension, BackendNodeType.LookerDimension],
    [NodeType.LookerMeasure, BackendNodeType.LookerMeasure],
    [NodeType.TableauDimension, BackendNodeType.TableauDimension],
    [NodeType.TableauMeasure, BackendNodeType.TableauMeasure],
]);

export const mapLocalSubnodeTypeToBackendSubnodeType = new Map<SubnodeType, BackendSubnodeType>([
    [SubnodeType.Dimension, BackendSubnodeType.Dimension],
    [SubnodeType.Measure, BackendSubnodeType.Measure],
    [SubnodeType.Entity, BackendSubnodeType.Entity],
    [SubnodeType.Metric, BackendSubnodeType.Metric],
    [SubnodeType.CustomField, BackendSubnodeType.CustomField],
    [SubnodeType.Column, BackendSubnodeType.Column],
    [SubnodeType.TableCalculation, BackendSubnodeType.TableCalculation],
    [SubnodeType.LookerConnectedView, BackendSubnodeType.LookerConnectedView],
    [SubnodeType.LookerLook, BackendSubnodeType.LookerLook],
    [SubnodeType.LookerTile, BackendSubnodeType.LookerTile]
]);

export const getSwimlaneForNodeType = (nodeType: NodeType): Swimlane => {
    const mapNodeTypeToSwimlane = new Map<NodeType, Swimlane>([
        [NodeType.DataModel, Swimlane.transformations_and_metrics],
        [NodeType.Metric, Swimlane.transformations_and_metrics],
        [NodeType.DataSource, Swimlane.sources],
        [NodeType.LookerView, Swimlane.appModeling],
        [NodeType.LookerDerivedView, Swimlane.appModeling],
        [NodeType.LookerExplore, Swimlane.appModeling],
        [NodeType.LookerLook, Swimlane.application],
        [NodeType.LookerTile, Swimlane.application],
        [NodeType.LookerDashboard, Swimlane.application],
        [NodeType.GenericDataTransformation, Swimlane.transformations_and_metrics],
        [NodeType.TableauWorkbook, Swimlane.application],
        [NodeType.TableauView, Swimlane.application],
        [NodeType.TableauCustomQuery, Swimlane.appModeling],
        [NodeType.TableauPublishedDataSource, Swimlane.appModeling],
        [NodeType.TableauEmbeddedDataSource, Swimlane.appModeling],
        [NodeType.TableauDashboard, Swimlane.application],
        [NodeType.TableauStory, Swimlane.application],
        [NodeType.Table, Swimlane.transformations_and_metrics]
    ]);
    const swimlane = mapNodeTypeToSwimlane.get(nodeType);
    if (!swimlane) {
        throw new Error(`Unknown node type: ${nodeType}`);
    }
    return swimlane;
};

export const transformBackendExpandedNodeToLocalExpandedNode = (node: BackendExpandedNodeResponse, additionalProperties: string[]): IExpandedNode => {
    const nodeType = mapBackendNodeTypeToLocalNodeType.get(node.type);
    const rawCode = node.table_properties?.view_native_code?.code || node.raw_code || '';
    if (!nodeType) {
        throw new Error(`Unknown Backend node type: ${node.type}`);
    }
    return {
        customProperties: additionalProperties.reduce((acc, prop) => {
            acc[prop] = node[prop as keyof BackendExpandedNodeResponse];
            return acc;
        }, {} as Record<string, unknown>),
        subnodes: [...buildSubondes(node), ...(node.subnodes || [])].map((subnode) => ({
            name: subnode.name,
            description: subnode.description,
            tags: subnode.tags || [],
            meta: subnode.meta || {},
            parentName: subnode.parent_name || subnode.parent_container_name,
            type: mapBackendSubnodeTypeToLocalSubnodeType.get(subnode.subnode_type) || SubnodeType.Column,
            withCode: subnode.source_code || subnode.compiled_code ? true : false,
            subType: subnode.type,
            rawCode: subnode.source_code || '',
            compiledCode: subnode.compiled_code || '',
            semanticCode: '',
            url: null,
            id: subnode.uri || `${node.uri}.${subnode.subnode_type}.${subnode.name}`,
            usage: subnode.looker_queries ? transformUsageFromExternalToInternal(subnode.looker_queries) : null,
            last30DaysViews: subnode.last_30d_views || null,
            last7DaysViews: subnode.last_7d_views || null,
            alias: subnode.alias || null,
            typeSpecificInfo: buildSubnodeTypeSpecificInfo(subnode),
            hasProposals: subnode.has_shift_left_potential || false,
            isTrivialSql: subnode.is_trivial_sql,
            aggType: subnode.agg || null,
            inputMeasures: subnode.type_params?.input_measures?.map(im => im.name) || [],
            isCalculated: subnode.is_calculated || null,
            impressions: null,
        })),
        tableauProject: node.tableau_project || null,
        lastAccessedAt: node.last_accessed_at,
        favouriteCount: node.favourite_count,
        url: node.url,
        lastUpdatedAt: node.updated_at,
        lastUpdatedBy: node.native_updated_by?.email || node.updated_by || null,
        createdAt: node.created_at,
        createdBy: node.native_created_by?.email || node.created_by || null,
        id: node.utl || node.uri || '',
        name: node.name,
        type: nodeType,
        subType: nodeType === NodeType.Metric && node.metric_type ? node.metric_type : null,
        description: node.description,
        tags: node.tags || [],
        meta: node.meta || {},
        generatedByDelphi: node.generated_by_delphi === true || `${node.generated_by_delphi}` === 'true',
        materialization: node.materialized,
        tableauHasExtracts: node.tableau_has_extracts,
        nativeLastDataUpdate: node.native_last_data_update,
        last30DaysViews: node.last_30d_views || null,
        last7DaysViews: node.last_7d_views || null,
        package: node.package_name,
        database: node.database,
        databaseSchema: node.database_schema,
        identifier: '',
        parentName: node.parent_name || node.parent_container_name,
        repo: node.git_repo_url,
        branch: node.git_repo_branch,
        rawCode,
        compiledCode: node.compiled_code,
        semanticCode: node.semantic_code,
        withCode: node.compiled_code || rawCode || node.semantic_code ? true : false,
        sourceDirectory: node.source_directory || '',
        lookerFolder: node.looker_folder || '',
        lookerModel: node.looker_model || '',
        lookerProject: node.looker_project || '',
        hasProposals: node.has_shift_left_potential || false,
        isTrivialSql: node.is_trivial_sql,
        usage: transformUsageFromExternalToInternal(node.looker_queries || node.tableau_queries_summary || null),
        impressions: transformUsageFromExternalToInternal(node.tableau_impressions_summary || null),
        dbtProjectName: node.dbt_project,
        eunoProjectId: node.euno_project_id || null,
        typeSpecificInfo: buildNodeTypeSpecificInfo(node),
        containedNodes:
            node.contained_resources?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || NodeType.GenericDataTransformation
            })) || [],
        chainedNodes:
            node.container_chain?.map((resource) => ({
                name: resource.name,
                id: resource.uri,
                type: mapBackendNodeTypeToLocalNodeType.get(resource.normalized_type) || resource.normalized_type
            })) || [],
        databaseTechnology: node.database_technology || null,
        tableauViewInputFields: node.tableau_view_input_fields_legacy_uri || null,
        parentUri: node.parent_container || null,
        refreshFrequency: node.tableau_extract_refresh_frequency || null,
        isCalculated: node.is_calculated || null,
        dataType: node.normalized_data_type || null,
        derivedType: node.derived_type || null,
        dbtMaterializationStrategy: node.dbt_materialization_strategy || null,
        externalLinks: node.external_links || [],
        owner: node.owner || null,
        firstSeen: node.first_seen_at,
        lastSeen: node.last_seen_at,
        isProxyToPublishedDatasource: node.is_proxy_to_published_datasource || false,
        nativeId: node.native_id || null,
        dbtVersion: node.dbt_version || null,
        lookerConnectionName: node.looker_connection_name || null,
        sponsor: node.sponsor || null,
        pdtTotalBuildTime30d: node.pdt_total_build_time_30d || null,
        pdtBuildsLast30d: node.pdt_builds_last_30d || null,
        isIdentityTransformation: node.is_identity_transformation ?? null,
        inputFields: node.input_fields ? node.input_fields.map(f => f.uri!).filter(f => f !== null) : null,
        hasRefinements: node.has_refinements ?? null,
        lookerRefinementChain: node.looker_refinement_chain || []
    };
};

const buildSubondes = (node: BackendExpandedNodeResponse): BackendExpandedSubnode[] => {
    const emptySubnode = {
        compiled_code: null,
        looker_queries: null,
        last_30d_views: null,
        last_7d_views: null,
        filter: null,
        alias: null,
        has_shift_left_potential: false,
        is_trivial_sql: false,
    };
    const columns =
        node.table_schema?.columns?.map((column) => ({
            name: column.name,
            description: column.description,
            type: column.normalized_data_type,
            subnode_type: column.semantic_role,
            tags: column.native_tags,
            meta: column.native_meta,
            parent_name: node.name,
            parent_container_name: node.parent_container_name,
            source_code: column.native_code?.code || null,
            is_calculated: column.native_tags?.includes('CALCULATEDFIELD'),
            uri: column.uri || null,
            ...emptySubnode,
        })) || [];
    return [...columns];
};

const buildSubnodeTypeSpecificInfo = (subnode: BackendExpandedSubnode) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (subnode.alias) {
        typeSpecificInfo.alias = subnode.alias;
    }
    if (subnode.type) {
        typeSpecificInfo.type = subnode.type;
    }
    return typeSpecificInfo;
};

const buildNodeTypeSpecificInfo = (node: BackendExpandedNodeResponse) => {
    const typeSpecificInfo: { [key: string]: string } = {};
    if (node.native_data_type) {
        typeSpecificInfo['type'] = node.native_data_type;
    }
    if (node.normalized_data_type && node.normalized_data_type !== 'unknown') {
        typeSpecificInfo['type'] = node.normalized_data_type;
    }
    if (node.owner) {
        typeSpecificInfo['Owner'] = node.owner;
    }
    if (node.tableau_workbook && node.type !== BackendNodeType.TableauWorkbook) {
        typeSpecificInfo['Workbook'] = node.parent_name;
    }
    if (node.metric_type) {
        typeSpecificInfo['type'] = node.metric_type;
    }
    if (node.dimension_type) {
        typeSpecificInfo['type'] = node.dimension_type;
    }
    if (node.dbt_measure_type) {
        typeSpecificInfo['type'] = node.dbt_measure_type;
    }
    if (node.native_last_data_update) {
        typeSpecificInfo['Extract last updated'] = utcToLocal(node.native_last_data_update, dateFormats.dateHoursAndMinutes);
    }
    if (Array.isArray(node.container_chain) && node.container_chain.length > 0) {
        typeSpecificInfo['Browse path'] = node.container_chain.map(({ name }) => name).join('/');
    }
    if (typeof node.table_properties?.materialized === 'boolean') {
        typeSpecificInfo['Materialization type'] = node.table_properties.materialized ? 'table' : 'view';
    }
    if (node.tableau_extract_refresh_frequency) {
        typeSpecificInfo['Refresh frequency'] = node.tableau_extract_refresh_frequency;
    }
    return typeSpecificInfo;
};

export const transformUsageFromExternalToInternal = (usage: ExternalNodeUsage | null): INodeUsage | null => {
    if (!usage) {
        return null;
    }
    return {
        usage14Days: usage.total_queries_14d,
        usage30Days: usage.total_queries_30d,
        usage60Days: usage.total_queries_60d,
        sources14Days: map_sources(usage.breakdown_by_app || []),
        users14Days: map_users(usage.breakdown_by_user || []),
        sources30Days: map_sources(usage.breakdown_by_app_30d || []),
        users30Days: map_users(usage.breakdown_by_user_30d || []),
        sources60Days: map_sources(usage.breakdown_by_app_60d || []),
        users60Days: map_users(usage.breakdown_by_user_60d || [])
    };

    function map_sources(breakdown_by_app: UsageAppBreakdown[]): ISourceNodeUsage[] {
        return (breakdown_by_app || []).map((app) => ({
            number: app.cnt || 0,
            utl: app.app.utl,
            dashboardTitle: app.app.dashboard_element_id || '',
            type: app.app.type
        }));
    }

    function map_users(breakdown_by_user: UsageUserBreakdown[]): IUserNodeUsage[] {
        return (breakdown_by_user || []).map((user) => ({
            name: user.user.user_name,
            number: user.cnt,
            email: user.user.user_email
        }));
    }
};

export const transformMetricDependenciesResponseToLocal = (
    response: MetricDependenciesResponse
): MetricDependency[] => {
    const metricDependencies: MetricDependency[] = [];
    for (const entity of response) {
        const existingEntity = metricDependencies.find((e) => e.entityName === entity.entity_name);
        const dependencies = entity.dimensions.map((d) => ({ name: d.name, type: d.type, description: '' }));
        if (existingEntity) {
            if (entity.entity_path) {
                existingEntity.entityPaths.push(entity.entity_path);
            }
            existingEntity.dimensions.push(
                ...dependencies.filter((d) => !existingEntity.dimensions.find((e) => e.name === d.name))
            );
        } else {
            metricDependencies.push({
                entityName: entity.entity_name,
                entityPaths: entity.entity_path ? [entity.entity_path] : [],
                dimensions: dependencies,
                entityDescription: ''
            });
        }
    }
    return metricDependencies;
};
