import { PlayIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

type ListProps = {
    label: string;
    icon?: JSX.Element;
    items: ListItem[];
    maxHeight?: string;
    isCollapsible?: boolean;
    defaultCollapsed?: boolean;
    headerActions?: JSX.Element;
}

type ListItem = {
    title: string;
    subtitle?: string;
    value: string | number;
    onClick?: () => void;
    icon?: JSX.Element;
}

export const List = ({ label, icon, items, maxHeight = '200px', isCollapsible = false, defaultCollapsed = false, headerActions }: ListProps) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const toggleCollapse = () => {
        if (isCollapsible) {
            setIsCollapsed(!isCollapsed);
        }
    };
    return (
        <div className="rounded-lg border border-border">
            <div onClick={toggleCollapse} className={`flex gap-2 p-2 items-center rounded-t-lg bg-lilac-50 text-secondary ${isCollapsible && 'cursor-pointer'}`}>
                {isCollapsible && <PlayIcon width="14" height="14" className={`cursor-pointer text-tertiary ${isCollapsed ? '' : 'rotate-90'}`} />}
                {icon}
                {label}
                <div className="ml-auto">{headerActions}</div>
            </div>
            {
                !isCollapsed && (
                    <div className="overflow-y-auto" style={{maxHeight}}>
                        {
                            items.map((item, i) => (
                                <div
                                    onClick={item.onClick}
                                    key={i}
                                    className={`p-2 flex items-center gap-2 border-b border-border ${i === items.length - 1 && 'rounded-b-lg'} ${i % 2 === 0 ? 'bg-white' : 'bg-slate-50'} ${item.onClick && 'cursor-pointer hover:bg-slate-50'}`}
                                >
                                    {item.icon}
                                    <div className="text-text-primary">{item.title}</div>
                                    {item.subtitle && <div className="text-slate-500">{item.subtitle}</div>}
                                    <div className="text-slate-400 ml-auto text-sm">{item.value}</div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};
