import {useNavigate, useParams} from 'react-router-dom';
import {ChevronLeftIcon} from "@heroicons/react/24/solid";
import Button from "src/components/button/Button";
import {ButtonTypes} from "src/components/button/types";
import {PlayIcon, SpinnerIcon} from "src/assets/images/icons/DelphiIcons";
import {GenericIntegrationConfiguration, GenericIntegrationType, ScheduleType} from "src/services/integrations/types";
import {notify} from "src/components/Toaster";
import {extractErrorMessage} from "src/services/api";
import {useSelector} from "react-redux";
import {selectActiveAccountId} from "src/infrastructure/state/slices/activeAccountSlice";
import {useGetAccountsQuery} from "src/services/accounts/accounts";
import {
  useCreateGenericIntegrationMutation,
  useTestIntegrationConfigurationMutation
} from "src/services/integrations/integrations";
import {useState} from "react";
import {FIELDS_BY_TYPE} from "src/features/sources/connectedSources/ConnectedSourcesConsts";
import Checkbox from "src/components/form/Checkbox";
import {IntegrationInfoModal} from "src/features/sources/IntegrationInfoModal";


type TopBarType = {
  integrationName: string,
  schedule: ScheduleType | undefined,
  configuration?: GenericIntegrationConfiguration,
}

const TopBar = ({integrationName, schedule, configuration} : TopBarType) => {
  const navigate = useNavigate();
  const accountId = useSelector(selectActiveAccountId);
  const [testIntegrationConfigurationMutation, { isLoading }] = useTestIntegrationConfigurationMutation();
  const integrationType = useParams().type as GenericIntegrationType;
  const integrationId = useParams().id;
  const [secretToCopy, setSecretToCopy] = useState('');
  const [createIntegration] = useCreateGenericIntegrationMutation();
  const getAccountsQuery = useGetAccountsQuery();
  const accountName = getAccountsQuery.data?.find(account => account.id === accountId)?.name || '';

  const save = async () => {
    try {
      const integrationData = await createIntegration({
        accountId,
        integrationName,
        configuration: configuration as GenericIntegrationConfiguration,
        schedule,
        integrationType
      }).unwrap();
      if (integrationType === "dbt_core") {
        setSecretToCopy(integrationData.trigger_secret || '');
      } else {
        navigate('/sources');
      }
    } catch (e) {
      notify(`Error creating integration: ${extractErrorMessage(e).message}`, 'error');
    }
  };

  const test = async () => {
    try {
      FIELDS_BY_TYPE[integrationType].forEach((field) => {
        if (!field['optional'] && field['component'] !== Checkbox) {
          if (!configuration || !configuration[field['title'] as keyof GenericIntegrationConfiguration])
            throw new Error(`${field['label']} is a mandatory field`);
        }
      });
      if (configuration) {
        await testIntegrationConfigurationMutation({ configuration, integrationType }).unwrap();
        notify('Your configuration is correct', 'success');
      }
    } catch (e) {
      notify(`${extractErrorMessage(e).message}`, 'error');
    }
  };

  return (
    <div className="flex justify-between bg-white px-8 py-3 border-b">
      <div className="flex items-center text-xl">
        <ChevronLeftIcon
            className="mr-2 text-grey-background hover:text-slate-500 cursor-pointer"
            width="20"
            height="20"
            onClick={() => navigate('/sources')}
        />
        {accountName} / {integrationId ? integrationType : 'Connect a new source'}
      </div>
      {!integrationId && (
        <div className="flex justify-between text-base">
          <Button
              className="w-24 !text-base"
              type={ButtonTypes.secondary}
              text="Test"
              icon={isLoading ? <SpinnerIcon className='animate-spin'/> : <PlayIcon width="16" height="16"/>}
              onClick={test}
              isLoading={isLoading}
              isLoadingText = "Testing..."
          />
          <Button className="ml-4 w-24 !text-base" type={ButtonTypes.primary} text="Save" onClick={save} />
        </div>
      )}
      { integrationType === "dbt_core" && (
        <IntegrationInfoModal
          info={secretToCopy}
          modalTitle="Integration key"
          infoTitle="Integration key created successfully"
          infoSubTitle="You`ll need this key to setup your system"
          label="Key"
          withAlert
          onClose={() => {
            setSecretToCopy('');
            navigate('/sources');
          }}
        />
      )}
    </div>
  );
};

export default TopBar;
