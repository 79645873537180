import { combineReducers } from 'redux';
import api from '../../services/api';
import activeProjectSlice from './slices/activeProjectSlice';
import activeAccountSlice from './slices/activeAccountSlice';
import isMenuCollpasedSlice from './slices/isMenuCollpasedSlice';
import historyStackSlice from './slices/historyStackSlice';
import showSubResouresInDataModelTableSlice from 'src/infrastructure/state/slices/showSubResouresInDataModelTableSlice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  activeProject: activeProjectSlice.reducer,
  activeAccount: activeAccountSlice.reducer,
  isMenuCollapsed: isMenuCollpasedSlice.reducer,
  historyStackSlice: historyStackSlice.reducer,
  showSubResourcesInDataModelTable: showSubResouresInDataModelTableSlice.reducer,
});

export default rootReducer;
