import toast from 'react-hot-toast';
import { events, trackEvent } from 'src/infrastructure/analytics';

type notificationTypes = 'success' | 'error';

export const notify = (message: string, type: notificationTypes) => {
    if (type === 'error') {
        trackEvent(events.errorToasterShown, { message });
    }
    toast[type](message, {
        position: "bottom-left"
    });
};

export const customToast = toast;