import { useNavigate } from "react-router-dom";
import { ButtonTypes } from "src/components/button/types";
import Button from "src/components/button/Button";
import { SOURCE_ICONS } from "./Consts";


const Gallery = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='w-[50rem] mt-9 grid grid-cols-3 gap-4 m-auto'>
        {Object.keys(SOURCE_ICONS).map(name => {
          const SourceIcon = SOURCE_ICONS[name];
          return (
            <div key={name} className='h-40 bg-white border rounded-lg items-center flex flex-col'>
              <SourceIcon width="40" height="40" className="mt-4 mb-4 text-black" fill="#FF694A"/>
              <span className={`${name !== 'dbt_core' ? 'capitalize' : ''}`}>{name}</span>
              <Button
                className="mt-4"
                type={ButtonTypes.secondary}
                text="Connect"
                onClick={() => navigate(`/sources/connect/${name}`)}
              />
            </div>
          );
        })}
        <div className='w-[50rem] my-9 m-auto bg-paragraph-background py-4 px-6 rounded-lg col-span-3 flex'>
          {"Need an integration but don't see it here?"}
          <a className='ml-2 text-lilac-700' href="mailto:support@euno.ai">Request integration</a>
        </div>
      </div>
    </>
  );
};

export default Gallery;
