import api from './api';
import IStakeholder, { Roles } from '../features/stakeholders/IStakeholder';

interface RenameFolderRequest {
  id: number;
  name: string;
}

interface CreateFolderRequest {
  name: string;
  parent: number | null;
}

interface DeleteFolderRequest {
  id: number;
}

interface DeleteFolderRequest {
  id: number;
}

export interface FolderResponse {
  folders: {
    id: number;
    name: string;
    parent: number | null;
    folders?: FolderResponse[];
  }[];
}

interface CreateFolderResponse {
  id: number;
  name: string;
  parent: number;
}

interface GetStakeholdersRequest {
  id: number;
}

interface StakeholdersResponse {
  id: number;
  stakeholders: [
    {
      role: Roles;
      user: string;
    }
  ];
  inherited_stakeholders: [
    {
      role: Roles;
      user: string;
      inherited_from: {
        name: string;
        parent: number;
        id: number;
      };
    }
  ];
}

interface SetFolderStakeholdersRequest {
  id: number;
  role: Roles;
  user: string;
}

export const foldersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFolders: build.query<FolderResponse, void>({
      query: () => 'folders',
      providesTags: ['Folders'],
      keepUnusedDataFor: 0
    }),
    createFolder: build.mutation<CreateFolderResponse, CreateFolderRequest>({
      query(data) {
        return {
          url: `folders`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Folders']
    }),
    deleteFolder: build.mutation<void, DeleteFolderRequest>({
      query(data) {
        return {
          url: `folders/${data.id}`,
          method: 'DELETE',
          body: data
        };
      },
      invalidatesTags: ['Folders']
    }),
    renameFolder: build.mutation<void, RenameFolderRequest>({
      query(data) {
        return {
          url: `folders/${data.id}/rename`,
          method: 'POST',
          body: { name: data.name }
        };
      },
      invalidatesTags: ['Folders']
    }),
    getFolderStakeholders: build.query<IStakeholder[], GetStakeholdersRequest>({
      query: (data) => `folders/${data.id}/stakeholders`,
      providesTags: ['Stakeholders'],
      transformResponse: (response: StakeholdersResponse) => {
        return [...response.stakeholders, ...response.inherited_stakeholders];
      },
      keepUnusedDataFor: 0
    }),
    setFolderStakeholders: build.mutation<void, SetFolderStakeholdersRequest>({
      query(data) {
        return {
          url: `folders/${data.id}/stakeholders`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['Stakeholders']
    }),
    deleteFolderStakeholder: build.mutation<void, { entityId: number; user: string }>({
      query({ entityId, user }) {
        return {
          url: `folders/${entityId}/stakeholders/${user}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['Stakeholders']
    })
  })
});

export const {
  useGetFoldersQuery,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useRenameFolderMutation,
  useGetFolderStakeholdersQuery,
  useSetFolderStakeholdersMutation,
  useDeleteFolderStakeholderMutation
} = foldersApi;
