import showSubResouresInDataModelTableSlice from 'src/infrastructure/state/slices/showSubResouresInDataModelTableSlice';
import { set } from '../../localStorage/localStorage';
import localStorageKeys from '../../localStorage/localStorageKeys';
import activeAccountSlice from '../slices/activeAccountSlice';
import activeProjectSlice from '../slices/activeProjectSlice';
import { Middleware, Dispatch, AnyAction } from 'redux';

const supportedActions = [
    {
        action: activeProjectSlice.actions.setActiveProject,
        key: localStorageKeys.activeProjectId
    },
    {
        action: activeAccountSlice.actions.setActiveAccount,
        key: localStorageKeys.activeAccountId
    },
    {
        action: showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable,
        key: localStorageKeys.showSubResourcesInDataModelTable
    }
];

const localStorageMiddleware: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);
    const supportedAction = supportedActions.find(({ action: supportedAction }) => supportedAction.type === action.type);
    if (supportedAction) {
        set(supportedAction.key, action.payload);
    }
    return result;
};

export default localStorageMiddleware;